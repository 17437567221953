import React from 'react'
import styled from "styled-components";
import EmpExpImg from "../../../../../assets/LandingPageImages/EmpExp"


export default function Customer() {
  return (
    <CUS>

    
<div className='customer-experience'>
  <div className='customer-content'>
    <div className='customer-header'>
      <div className='customer-subheader'>
        <ul className='customer-text'>
            <li>      Optimize every touchpoint in your customer's journey. Be</li>
            <li > proactive & exceed expectations.</li>
        </ul>
    
      </div>
      <ul className='list'>
        <li>Employee Satisfaction with company</li>
        <li>Job satisfaction</li>
        <li>360-degree feedback</li>
        <li>Employee benefits and remuneration</li>
        <li>Employee Engagement</li>
        <li>Onboarding & New Hiring Surveys</li>
        <li>Interview Feedback Surveys</li>
      </ul>
    </div>
    <div className='customer-image'>
      < EmpExpImg/>
    </div>
  </div>
</div>


    </CUS>
  )
}
 
const CUS=styled.div`
@media screen and (max-width: 767px) {
  .customer-content{
    /* flex-wrap: ; */
  }
  .customer-text{
    font-size:x-small;
  }
  .list{
    font-size: x-small;
  }

}
.customer-experience {
  display: flex;
  flex-direction: column;
  padding-left:10%;
  padding-right: 15%;
  margin-top: 5%;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;

}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.customer-image{
  background-color:  #EC7A50;
  border-radius: 40px;


}

.customer-content {
  display: flex;
  
  justify-content: space-between;
  
  
  
}

.customer-header {
  
margin-left: 10%;
line-height: 1.5;
  
  


}
.customer-text {
  font-size: 22px;
  font-weight:bolder;
   /* Adjust the font size as desired */
}


.customer-subheader {
  margin-bottom: 20px;
  
}

.customer-header{
  
  margin-right: 20px;
  
}

.customer-image {

  
}
.list{
    font-size:17px;
}


`