import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import PreviewIcon from "../../../../assets/svg/preview";
// import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import { useParams } from "react-router-dom";

export default function Index(props) {
  
  const {surveyId, surveyInfo, editMode} =props
  const [currentUrl, setCurrentUrl] = useState('/agent/dashboard')
  const location = useLocation();
  const navigate = useNavigate()
  const menus = [
    {
      label: "Build",
      url: `/edit-survey/${surveyId}` ,
    },
    {
      label: "Share",
      url: `/share/${surveyId}`,
    },
    {
      label: "Reports",
      url: `/reports-and-responses/${surveyId}`,
    },
  ];
  return (
    <>
      <div className="title-menu-bar">
     
       
        <div className="survey-title"> 
        <div className="home-icon">
        <HomeIcon onClick={()=>navigate('/dashboard')} />
          </div> 
          {surveyInfo?.survey_title}</div>
        <div className="center-nav-menu">
          {menus?.map((menu, idx) => (
            <div
              key={idx}
              className={`header-menu ${menu?.url === location?.pathname ? "active" : ""
                }`}
              onClick={() => navigate(menu?.url)}
            >
              {menu?.label}
            </div>
          ))}
        </div>
        

         <div className="header-right-btns">
           
          <button onClick={()=> navigate(`/preview/${surveyId}`) }className="prev-btn" >
            <PreviewIcon />
            <div>Preview</div>
          
          
          </button>
          
        </div> 
      </div>
    </>
  );
}
