import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material//Paper";
import { Auth } from "aws-amplify";
import { useValidCode, useValidUsername } from "../../hooks/useAuthHooks";
import { Code, Username } from "../../Components/Auth/authComponents";
import { AuthContext } from "../../contexts/AuthContext";

// const useStyles = makeStyles({
//   root: {
//     height: "100vh",
//   },
//   hover: {
//     "&:hover": { cursor: "pointer" },
//   },
// });

const ConfirmSignUp: React.FunctionComponent<{}> = () => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const { error, setError }: any = useContext(AuthContext);
  const { username, setUsername, usernameIsValid } = useValidUsername("");
  const { code, setCode, codeIsValid } = useValidCode("");

  const isValid =
    !usernameIsValid ||
    username.length === 0 ||
    !codeIsValid ||
    code.length === 0;

  const sendClicked = async () => {
    try {
      await Auth.confirmSignUp(username, code);
      navigate("/log-in");
    } catch (err) {
      setError("Invalid Code");
    }
  };

  const passwordResetClicked = async () => {
    navigate("/reset-password");
  };

  return (
    <Grid
    style={{height: "100vh"}}
      // className={classes.root}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        xs={11}
        sm={6}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
      >
        <Paper style={{ width: "100%", padding: 32 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* Title */}
            <Box m={2}>
              <Typography variant="h3">Confirm Sign up</Typography>
            </Box>

            {/* Sign In Form */}
            <Box width="80%" m={1}>
              {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
              <Username
                usernameIsValid={usernameIsValid}
                setUsername={setUsername}
              />{" "}
            </Box>
            <Box width="80%" m={1}>
              <Code codeIsValid={codeIsValid} setCode={setCode} />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box onClick={passwordResetClicked} mt={2}>
                  <Typography variant="body2">
                    Resend Code
                  </Typography>
                  <Box mt={2}>
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" justifyContent="center">
                <Box m={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box m={1}>
                  <Button
                    disabled={isValid}
                    color="success"
                    variant="contained"
                    onClick={sendClicked}
                  >
                    Send
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ConfirmSignUp;
