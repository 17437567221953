import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Overview from "../../Report-Overview"
import Results from "../../ReportResult"
import Responses from "../../ReportResponse"

// import Box from '@mui/material/Box';
// import ShareIcon from '@mui/icons-material/Share';
import "./style.css";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
// import {CSVLink} from "react-csv";
import Divider from "@mui/material/Divider";
import Csv from "../Graph/components/ExportCsv";
import Pdfs from "../Graph/components/Exportpdf";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


type Anchor = "right";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
// const csvReport={
//   filename:'Report.csv',
//   data:
// }

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate()
  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 500 }}
      role="presentation"
      
    >

      <div className='side-pannel-header'>

        <div className='side-pannel-export'>Export</div>
        <div className='side-pannel-cancel'
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        >Cancel</div>

      </div>
      <Divider />
      <div className='side-pannel-subheader'>Download Responses</div>


      {/* <Button className='link-1'>
        <div className='side-pannel-csvdwld' >
       
          <Csv/>
        </div>
      </Button> */}
      {/* <Button className='link-1'>
        <div className='side-pannel-csvdwld' >
          <Pdf/>
        </div>
      </Button> */}
      <Button className='link-1'>
        <div className='side-pannel-csvdwld' >
          Download .XLSX
        </div>
      </Button>
    </Box>
  );



  return (
    <>
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="OVERVIEW" />
          <Tab label="REPORT" />
          <Tab label="RESPONSE" />
          <div className='right-side-pannel'>
    {(["right"] as const).map((anchor) => (
      <React.Fragment key={anchor}>
        {/* <div>
          <button>

  <Csv/>
          </button>
          
<button>
          <Pdfs/>

</button>
   
</div> */}
        {/* <Button style={{marginLeft:"800px"}} onClick={toggleDrawer(anchor, true)} >Share</Button> */}
          {/* <Button onClick={toggleDrawer(anchor, true)} >
            <div className='share-content'>share report</div>
          </Button> */}

          {/* <div className='testing'>

          <FileDownloadOutlinedIcon onClick={toggleDrawer(anchor, true)} />
         
        </div> */}
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    ))}
  </div>
        </Tabs>
        
      </Box>
      <TabPanel value={value} index={0}>
        <Overview/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Results/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Responses/>
      </TabPanel>
    </Box>
    

    </>
  );
}
