import React from "react";
import styled from "styled-components";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import Data from "../components/Data.json";
import { render } from "react-dom";



export default function App(props) {
  const{surveyReport ,q_id,chartColor}=props;
  // console.log("qqqqq",q_id)              *
  
  const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
  const colors = ['#45bd62', '#f24260', '#9260f7', '#1878f3', '#f7b928', '#29bba6'];
  const randomIndex = Math.floor(Math.random() * colors.length);

  const questionData = surveyReport.filter((data) => data.q_id === q_id)[0];
  // console.log("questionData",questionData)                *
  

  const chartData =questionData && questionData.answers && questionData.answers
  .filter((answer) => answer.q_answer !== "None")
  .map((answer) => ({
    name: answer.q_answer,
    responsepercentage: answer.percentage,
    responses: answer.answer_count,
  }));


  

  return (
    <>
    {
  questionData.q_type === "email" || questionData.q_type === "phone" || questionData.q_type==="TextInput"||questionData.q_type==="LongText"|| questionData.q_type==="upload" ? (
    null // If the question type is email or phonenumber, don't render the chart
  ) : (
      <BarR>
        
        <div className="graph">
        
              <BarChart
              width={500}
              height={300}
              data={chartData}
              // data={Data}
              margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              {/* <XAxis dataKey="option" /> */}
              <YAxis />
              <Tooltip />
              <Legend />
           
              {/* <Bar
                dataKey="responsepercentage"
                fill="var(--green)"
            
              /> */}
              {/* <LabelList
                dataKey="responsepercentage"
                position="insideRight"
                style={{ fill: "var(--blue)"}}
              /> */}
  
              <Bar dataKey="responses" stackId="a" fill= {chartColor}  />
            </BarChart>
         
       
        </div>
      </BarR>
       )
      }
    </>
  );
}

const BarR = styled.div`
  .graph {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    
  }
`;
