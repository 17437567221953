import React, { useState } from "react";
// import SurveyCard, { SurveyCardProps } from "../../Components/SurveyCard";
// import "./style.css";

// icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LinkIcon from "@mui/icons-material/Link";
import ShareIcon from "@mui/icons-material/Share";
import SmsIcon from '@mui/icons-material/Sms';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SocialShare from "../../SocialMedia"
import QrCode from "../../QrCode"
import { useNavigate } from "react-router-dom";


export default function Index({url}) {
  const navigate= useNavigate();
  const [socialShareModalShow, setSocialShareModalShow] = useState(false)
  const [qreModalShow, setQreModalShow] = useState(false)
  const cards = [
    {
      title: "Send Mail",
      href: `mailto:?subject=Beginner's%20Guide%20to%20Jest%20Testing%20in%20React&body=${url}`,
      icon: <MailOutlineIcon/>,
      // onClick: () => console.log("Mail"),
    },
    // {
    //   title: "Create a URL",
    //   icon: <LinkIcon/>,
    //   onClick: () => console.log("LinkIcon"),
    // },
    {
      title: "Share",
      icon: <ShareIcon/>,
      onClick: () => {
        console.log('open');
        
        setSocialShareModalShow(true)},
    },
    {
      title: "Send SMS",
      href:
        `sms:?body=${url}`,
      icon: <SmsIcon/>,
      onClick: () => console.log("SmsIcon"),
    },
    {
      title: "QR code",
      icon: <QrCodeIcon/>,
      onClick: () => {
        console.log('open');
        
        setQreModalShow(true)},
  
    },
  
  
  ];
  
  return (
    <>

      <div className="mailcard">
        {cards?.map((card, index) => (
          <div className="share-card" onClick={card?.onClick}  key={index}>
            <a href= {card?.href}>
            <figure>{card.icon}<figcaption></figcaption>{card.title}</figure>
            </a>
          
          </div>
        ))}
      </div>
      <SocialShare show={socialShareModalShow} onClose={()=>setSocialShareModalShow(false)} url={url}/>
      {/* <QrCode/> */}
      <QrCode show={qreModalShow} onClose={()=>setQreModalShow(false)} url={url}/>
    </>

  );
}
