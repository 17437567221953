import React, { useEffect, useState } from "react";
import { get, update } from "../../../Utils/Function/Method";
import { useParams } from 'react-router-dom';
export interface QuestionType {
  // id: any,
  id?:any;
  LongText:string;
  text: string;
  type: string;
  required: boolean;
  choices: string[];
  skipLogic: Array<{ choice: string; skipTo: string }>;
  min: any;
  max: any;
  extension:any,
  file_name:any,
  path:any,
  content_type:any
 
}

type AllFunctionParamsType={
    surveyInfo: any;
    editMode: any;
    setUpdatingQuestionId?: React.Dispatch<React.SetStateAction<number>>
    setIsEditingQuestion?: React.Dispatch<React.SetStateAction<boolean>>
}
export const AllFunction = ({surveyInfo, editMode,setUpdatingQuestionId,setIsEditingQuestion}:AllFunctionParamsType)=>{

const [questions, setQuestions] = useState([]);
const [inputChoice, setInputChoice] = useState("");
const [questionIndex, setQuestionIndex] = useState(-1);

const [currentQuestion, setCurrentQuestion] = useState<QuestionType>({
  // id: 1,
  LongText:"",
  text: "",
  type: "multipleChoice",
  choices: [],
  skipLogic: [{ choice: "", skipTo: "" }],
  required: false,
  min: "",
  max: "",
  extension:"",
  file_name:"",
  path:"",
  content_type:""



});

const handleBackendData=async(data)=>{
  // console.log("Question SEt in the Backedn daata",JSON.parse(data?.question_set))
  const parsedData = JSON.parse(data?.question_set)
  // setQuestions([...parsedData])
  
}

const handleAddField = (property, value) => {
  setCurrentQuestion((prevState) => ({
    ...prevState,
    [property]: value,
  }));
};
const handleChoiceChange = (index, event) => {
  const newChoices = [...currentQuestion.choices];
  newChoices[index] = event.target.value;
  setCurrentQuestion({ ...currentQuestion, choices: newChoices });
};

const handleQuestionChange = (event) => {
  console.log("Event target>>>",event.target,event.target.name)
  // console.log(currentQuestion.choices,currentQuestion.type,"Current question is ",currentQuestion,"Name is ",event.target.name,"Target is ",event.target.value)  *  
  if(event.target.name==="min"){
    setCurrentQuestion({
      ...currentQuestion,
      [event.target.name]: event.target.value,
      choices:currentQuestion.max===""?[""]:Array.from({length:Number(currentQuestion.max)-Number(event.target.value)+1},(v,k)=>((k+Number(event.target.value)).toString()))
    });
  }else if(event.target.name==="max"){
    setCurrentQuestion({
      ...currentQuestion,
      [event.target.name]: event.target.value,
      choices:currentQuestion.min===""?[""]:Array.from({length:Number(event.target.value)-Number(currentQuestion.min)+1},(v,k)=>((k+Number(currentQuestion.min)).toString()))
    });
  }else if(event.target.value==="boolean"){
    setCurrentQuestion({
      ...currentQuestion,
      [event.target.name]: event.target.value,
      choices:["Yes","No"],
      min:"",
      max:""
    });
  }else if(event.target.name==="text"){
    setCurrentQuestion({
      ...currentQuestion,
      [event.target.name]: event.target.value,
      // choices:[""],
      // min:"",
      // max:""
    });
  }else{
    setCurrentQuestion({
      ...currentQuestion,
      [event.target.name]: event.target.value,
      choices:[""],
      min:"",
      max:"",
      skipLogic:[]
    });
  }
  
};
const addChoice = () => {
  setCurrentQuestion({
    ...currentQuestion,
    choices: [...currentQuestion.choices, ""],
  });
};

const removeChoice = (index) => {
  const newChoices = [...currentQuestion.choices];
  newChoices.splice(index, 1);
  setCurrentQuestion({ ...currentQuestion, choices: newChoices });
};

const handleSkipLogicChange = (event, index) => {
  const newSkipLogic = [...currentQuestion.skipLogic];
  newSkipLogic[index] = event.target.value;
  setCurrentQuestion({ ...currentQuestion, skipLogic: newSkipLogic });
};

const addQuestion = (e) => {
  console.log(e)
  console.log("DAtaaaaaaaaaaaaaa",questions,currentQuestion)
  e.preventDefault();
  if (editMode && questionIndex !== -1) {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = currentQuestion;
    setQuestions(updatedQuestions);
  } else {
    // If not in edit mode, add a new question to the list
    setQuestions([...questions, currentQuestion]);
  }

  // setQuestions([...questions, currentQuestion]);
  setCurrentQuestion({
    // id: `${questionNumber+1}`,
    LongText:"",
    text: "",
    type: "multipleChoice",
    choices: [],
    skipLogic: [{ choice: "", skipTo: "" }],
    required: false,
    min: "",
    max: "",
    extension:"",
    file_name:"",
    path:"",
    content_type:""
 
  });
  // console.log(questions)
  // setQuestionNumber(questionNumber+1);
};

const handleEditQuestion = (index) => {
  const editedQuestion = questions[index];
  if(setUpdatingQuestionId){
    setUpdatingQuestionId(editedQuestion.id)
  }
  setCurrentQuestion(editedQuestion);
  setQuestionIndex(index);
};


const EditQuestionButton = ({ index }) => (
  <button onClick={() => handleEditQuestion(index)}>Edit Question</button>
);

const handleDeleteQuestion =async (index) => {
  
  let questionID:any
  const surveyId= parseInt(surveyInfo?.survey_id);
  const dataSet=questions.filter((data, i) => {
    if(index===i){
      questionID=data.id
    }
    return index!==i
  })

  // alert(questionID)    *
  const action='delete'
  const response=await update(`/question_set?q_id=${questionID}`,{ survey_id: surveyId, question_set:JSON.stringify(dataSet)})
  if(response[0]===200){
    // alert("Question updated successfully")    *
  setQuestions(dataSet);
  }else{
    alert("Can't update question") 
  }
  
};
const handleSkipChoiceChange = (index) => (event) => {
  const newSkipLogic = [...currentQuestion.skipLogic];
  newSkipLogic[index].choice = event.target.value;
  setCurrentQuestion({ ...currentQuestion, skipLogic: newSkipLogic });
};

const handleSkipToChange = (index) => (event) => {
  const newSkipLogic = [...currentQuestion.skipLogic];
  newSkipLogic[index].skipTo = event.target.value;
  setCurrentQuestion({ ...currentQuestion, skipLogic: newSkipLogic });
};

const addSkipLogic = (e) => {
  e.preventDefault();
  console.log("Current question skip logic",currentQuestion,currentQuestion.skipLogic)
  setCurrentQuestion({
    ...currentQuestion,
    skipLogic: currentQuestion.skipLogic?[...currentQuestion.skipLogic, { choice: "", skipTo: "" }]:[{ choice: "", skipTo: "" }],
  });
  console.log(currentQuestion)
};
const handleDeleteSkipLogic = (index) => {
  const newSkipLogic = [...currentQuestion.skipLogic];
  newSkipLogic.splice(index, 1);
  setCurrentQuestion({
    ...currentQuestion,
    skipLogic: newSkipLogic,
  });
};

const SaveQuestionButton = () => (
  <button className="quest-builder-btns"
    onClick={() => {
      const newQuestions = [...questions];
      newQuestions[questionIndex] = currentQuestion;
      setQuestions(newQuestions);
      setCurrentQuestion({
        // id: `${questionNumber}`,
        LongText:"",
        text: "",
        type: "multipleChoice",
        choices: [],
        skipLogic: [{ choice: "", skipTo: "" }],
        required: false,
        min: "",
        max: "",
        extension:"",
        file_name:"",
        path:"",
        content_type:""
      
      });
      console.log("Current question data while clicking updateee dd",currentQuestion?.id)
      if(setIsEditingQuestion){
        setIsEditingQuestion(true)
      }
      setQuestionIndex(-1);
    }}
  >
    Updateee
  </button>
);

const removeEmptyObj = (obj) => { 
  Object.keys(obj).forEach(key => {
    if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
  }
  
const addId = (arr) => {
  if(arr)
  for (let i = 0; i < arr.length; i++) {
    arr[i].id = i + 1;
    delete arr[i].index;
  }
  }
  
  

return {
  handleChoiceChange,
  handleQuestionChange,
  addChoice,
  removeChoice,
  addQuestion,
  handleEditQuestion,
  EditQuestionButton,
  handleDeleteQuestion,
  handleSkipChoiceChange,
  handleSkipToChange,
  addSkipLogic,
  handleDeleteSkipLogic,
  SaveQuestionButton,
  questions,
  currentQuestion,
  questionIndex,
  setQuestions,
  setCurrentQuestion,
  setQuestionIndex,
  removeEmptyObj,  
  addId,
  inputChoice,
  setInputChoice,
  handleAddField,
  handleBackendData,
  cleanedQuestionsArray,
  
  
};
}

export function cleanedQuestionsArray(questions){ questions.map(question => {
  let cleanedQuestion = { ...question };
  cleanedQuestion.skipLogic = cleanedQuestion.skipLogic.filter(
    skipLogic => skipLogic.choice !== "" && skipLogic.skipTo !== ""
  );
  return Object.entries(cleanedQuestion)
    .filter(([key, value]) => {
      if (typeof value === "string") {
        return value !== "";
      } else if (Array.isArray(value)) {
        return value.length !== 0;
      } else {
        return true;
      }
    })
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
});
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}