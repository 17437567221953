
import React, { useState } from "react";
import Area from "./components/AreaChart";
import Bar from "./components/BarChart";
import Line from "./components/LineChart";
import Pie from "./components/PieChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import LineChartIcon from "@mui/icons-material/ShowChart";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import "./style.css";
import ColorPicker from "../Graph/components/ColorPicker/ColorPicker";

export default function App(props) {
  const{surveyReport ,q_id}=props;

  // console.log("REPORTTTTTTTTTT",surveyReport)
  const [graph, setGraph] = useState("");
  const [chartColor, setChartColor] = useState("#8884d8");
  const handleColorChange = (color) => {
    setChartColor(color.hex);
  };

  
  const graphType = () => {
    switch (graph) {
      case "BarChart":
        return <Bar
         surveyReport={surveyReport} q_id={q_id} chartColor={chartColor}  />;
      case "LineChart":
        return <Line surveyReport={surveyReport} q_id={q_id} chartColor={chartColor}/>;
      case "AreaChart":
        return <Area surveyReport={surveyReport} q_id={q_id} chartColor={chartColor} />;
      case "PieChart":
        return <Pie  surveyReport={surveyReport} q_id={q_id} chartColor={chartColor}/>;
      default:
        return <Bar surveyReport={surveyReport} q_id={q_id} chartColor={chartColor} />;
    }
  };
  const questionData = surveyReport.filter((data) => data.q_id === q_id)[0];
  return (
    <>
      
       {
  questionData.q_type === "email" || questionData.q_type === "phone" || questionData.q_type==="TextInput"|| questionData.q_type === "upload"  ? (
    null // If the question type is email or phonenumber, don't render the chart
  ) :(
    
      <div className="graphbutton">
      
        <button
          className="Bar glass"
          onClick={() => {
            setGraph("BarChart");
          }}
        >
          <BarChartIcon />
        </button>
        <button
          className="Bar glass"
          onClick={() => {
            setGraph("LineChart");
          }}
        >
          <LineChartIcon />
        </button>
        <button
          className="Bar glass"
          onClick={() => {
            setGraph("AreaChart");
          }}
        >
          <AlignHorizontalLeftIcon />
        </button>
        <button
          className="Bar glass"
          onClick={() => {
            setGraph("PieChart");
          }}
        >
          <PieChartIcon />
        </button>
        <button className="Bar glass">

        <ColorPicker chartColor={chartColor} handleColorChange={handleColorChange}  />
        </button>
      </div>
      )
    }
      <div className="graph-desktop">{graphType()}</div>
      <div className="graph-media">
      <details><summary>Visualize in Graph</summary>{graphType()}</details>
      </div>
      
      
    </>
    
  );
}
