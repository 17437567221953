import React, { useState, useContext, useEffect } from "react";

// import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material//Paper";

import { useValidPassword, useValidUsername } from "../../hooks/useAuthHooks";
import { Password, Username } from "../../Components/Auth/authComponents";

import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import RivaziaLogo from "../../assets/rivaziaAnim";

// const useStyles = makeStyles({
//   root: {
//     height: "100vh",
//   },
//   hover: {
//     "&:hover": { cursor: "pointer" },
//   },
//   text: {
//     textAlign: "center",
//   },
// });

const Index = () => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const { username, setUsername, usernameIsValid } = useValidUsername("");
  const [error, setError] = useState("");
  const [resetSent, setResetSent] = useState(false);

  const isValid = !usernameIsValid || username.length === 0;

  const { setUserName }: any = useContext(AuthContext);

  const sendCodeClicked = async () => {
    try {
      await Auth.forgotPassword(username);
      setUserName(username);
      navigate("/forgot-password");
    } catch (err) {
      setError("Unknown user");
    }
  };

  return (
    <Grid
      // className={classes.root}
      style={{height: "100vh"}}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        xs={11}
        sm={6}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
      >
        <Paper style={{ width: "100%", padding: 32 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <RivaziaLogo width="200" height="100"/>
            <Box m={2}>
              <Typography variant="h4">Reset your password</Typography>
            </Box>

            <Box width="80%" m={1}>
              <Username
                usernameIsValid={usernameIsValid}
                setUsername={setUsername}
              />
            </Box>
            <Box mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>

            <Box mt={2}>
              <Grid container direction="row" justifyContent="center">
                <Box m={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate("/log-in")}
                  >
                    Back to Sign In
                  </Button>
                </Box>
                <Box m={1}>
                  <Button
                    disabled={isValid}
                    color="success"
                    variant="contained"
                    onClick={sendCodeClicked}
                  >
                    Send Code
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Index;
