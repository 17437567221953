import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {LinkedInIcon, FacebookIcon, TwitterIcon, WhatsApp} from "../../../assets/SocialMedia";
import { useState } from "react";
import Link from '@mui/material/Link';



const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  // border: '2px solid',
  borderRadius:'5px',
  boxShadow: '0px 0px 1px 2px #d1d0f7',
  // boxShadow: 24,
  p: 4,
};


interface Props {
  show:boolean;
  onClose: ()=>void;
  url:any;
  // index: number,
}

export default function BasicModal({show, onClose, url}:Props) {
  const socials = [
    {
      outlet: "LinkedIn",
      href:
        `https://www.linkedin.com/shareArticle?mini=true&url=${url} created and shared via Rivazia.&source=`,
      label: "Share on LinkedIn",
      icon: <LinkedInIcon />
    },
    {
      outlet: "Facebook",
      href:
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      label: "Share on Facebook",
      icon: <FacebookIcon />
    },
    {
      outlet: "Twitter",
      href:
        `https://twitter.com/intent/tweet?url=${url} created and shared via Rivazia.`,
      label: "Share on Twitter",
      icon: <TwitterIcon />
    },
    {
      outlet: "Whatsapp",
      href:
        `https://api.whatsapp.com/send?text=${url}`,
      label: "Share via Whatsapp",
      icon: <WhatsApp/>
    },
  ];
  const handleClose = () => onClose();
  const variants = ['facebook', 'linkedin', 'whatsapp', 'twitter']
  return (
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className='social-share-icons'>
        {socials.map((data)=> {
          return(
            // <Link href={data.href} target="_blank" sx={{background: '{data.background}'}}>
            //   {data.icon}
            
            // </Link>
          <a href={data.href} target="_blank" className= "socail-media-icons" >

            {data.icon}
            </a>
          );
        })}
        
        </div>  
        </Box>
      </Modal>
  );
}