import React, { useEffect, useRef } from 'react';
import styled from "styled-components";
import CusExpImg from "../../../../../assets/LandingPageImages/CustomerExp"


export default function Customer() {
  const customrRef = useRef();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold based on your needs
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-in'); // Add a class to trigger the animation
          observer.unobserve(entry.target); // Unobserve once the animation is triggered
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(customrRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <CUS>

    
<div className='customer-experience' ref={customrRef} >
  <div className='customer-content'>
    <div className='customer-header'>
      <div className='customer-subheader'>
        <ul className='customer-text'>
            <li>      Optimize every touchpoint in your customer's journey. Be</li>
            <li > proactive & exceed expectations.</li>
        </ul>
    
      </div>
      <ul className='list'>
        <li>CSAT (Customer Satisfaction Surveys)</li>
        <li>Online Purchase Feedback Surveys</li>
        <li>Store Purchase Feedback Surveys</li>
        <li>New Product Idea</li>
        <li>Event Feedback</li>
        <li>NPS (Net Promoter Score)</li>
        <li>CES (Customer Effort Score)</li>
      </ul>
    </div>
    <div className='customer-image'>
      <CusExpImg/>
    </div>
  </div>
</div>


    </CUS>
  )
}
 
const CUS=styled.div`
.customer-experience {
  display: flex;
  flex-direction: column;
  padding-left:10%;
  padding-right: 15%;
  margin-top: 5%;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.customer-image{
  background-color: #eec14e ;
  border-radius: 40px;


}

.customer-content {
  display: flex;
  
  justify-content: space-between;
  
  
  
}

.customer-header {
  
margin-left: 10%;
line-height: 1.5;
  
  


}
.customer-text {
  font-size: 22px;
  font-weight:bolder;
   /* Adjust the font size as desired */
}


.customer-subheader {
  margin-bottom: 20px;
  
}

.customer-header{
  
  margin-right: 20px;
  
}

.customer-image {

  
}
.list{
    font-size:17px;
}

@media screen and (max-width: 767px) {
  .customer-content{
    /* flex-wrap: ; */
  }
  .customer-text{
    font-size:x-small;
  }
  .list{
    font-size: x-small;
  }

}

`