import React, { useEffect, useState, useContext, useRef } from "react";
import Surveys from "../survey.json";
import OptionMenu from "../../../assets/option";
import DropMenu from "../../../Components/DropMenu";
import { deletes, get } from "../../../Utils/Function/Method/index";
import { apiEndPoint } from "../../../Utils/constants";
import "./style.css";
import { AuthContext } from "../../../contexts/AuthContext";
import { getAllSurvey } from "../../../api/Dashboard/getAllSurvey";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Box, Icon, Pagination } from "@mui/material";
import SurveyImage from "../../../assets/LandingPageImages/Hand"
const Index = () => {
  const [recentSurvey, setRecentSurvey] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [surveyId, setSurveyId] = useState(null);
  const[refreshDashboard,setRefreshDashboard]=useState(false);
  const [page, setPage] = useState(1);
  const {surveyNum}=useContext(AuthContext)
  // console.log("Survey number",surveyNum)


  const { userAttr } = useContext(AuthContext);

  async function fetchData() {
    try {
      const response = await get(`/rv_dashboard/users/${userAttr?.sub}`);
      // console.log("response", response[1][0]);
      setSurveys(response[1][0]);
    } catch (error) {
      console.log("fetch error ", error);
    }
  }

  const handleDelete = async (surveyId) => {
    await deletes(`/dashboard/survey/${surveyId}`)
      .then(([status, res]) => {
        if (status == 200) {
          setRefreshDashboard(!refreshDashboard)
          // alert("delete succesfully");
          fetchData();
        } else {
          alert("something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  // console.log("Works hello", recentSurvey);
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);

  function handleFocus() {
    setIsExpanded(true);
    inputRef.current.focus();
  }

  function handleBlur() {
    setIsExpanded(false);
  }
// *******************Search bar***************************
  const [searchTerm, setSearchTerm] = useState("");

function handleSearch(event) {
  setSearchTerm(event.target.value);
}

const filteredSurveys = surveys?.filter((survey) => {
  return survey.survey_title.toLowerCase().includes(searchTerm.toLowerCase());
});

// *********************Pagination*************************************

const surveysPerPage = 10; // number of surveys to show per page
const startIndex = (page - 1) * surveysPerPage;
const endIndex = startIndex + surveysPerPage;
const displayedSurveys = filteredSurveys?.slice(startIndex, endIndex);


  return (
    <>
<div className="Survey-heading">
    <div className="heading">All Surveys</div>

<div className={`search-bar ${isExpanded ? "expanded" : ""}`}>
      <input
        type="text"
        placeholder="Search Surveys"
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        onChange={handleSearch}
      />
      <button>
        <i className="fa fa-search"></i>
      </button>
    </div>
    </div>
      <div className="survey-all-list">
      {displayedSurveys?.length === 0 ? (
    <div className="no-search-results">No results found.</div>
  ) : (
    <>
    
        {(displayedSurveys &&
          displayedSurveys.map((data, i) => {
     
              if (data.survey_status === "Paused") {
              var fontColor = "red";
            } else {
              var fontColor = "green";
            }
            return (
              <div className="survey-card" key={i}>
                 <div className="scratch-card">

<ArticleOutlinedIcon style={{ color: 'white' ,fontSize: '40px'}} />

</div>
                <div className="survey-card-col1">
       
        
                  <div className="survey-title">{data?.survey_title}
                  </div>

                  <ul className="ul-head">
                    <li className="created-list">
                      Created At :  &nbsp;
                      <span >
                      {data?.created_date
    ? new Date(data?.created_date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : ''}
                      </span>
                    </li>
                    <li>
                      
                      Last Altered :  &nbsp;
                      <span>
                        {data.modified_date
                          ? new Date(data?.modified_date).toLocaleDateString('en-US',
                          {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          }
                          )
                          : ""}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="survey-card-col2">
                  <div className="survey-status" style={{ color: fontColor }}>
                    
                    {data.survey_status}
                  </div>
                  <div className="questions-count" >
                    {data.question_count} <br />
                    <span>Questions</span>
                  </div>
                  <div className="responses">
                     {data.response_count}<br />
                     <span>
                     <div className="animated-bar-chart-icon">
                    <BarChartOutlinedIcon  color="primary"/>
                    </div>
                    Responses</span>
                  </div>
                  <div className="card-drop-menu">
                    <DropMenu
                      handleDelete={handleDelete}
                      id={surveyId}
                      survey={data?.survey_id}
                      setSurveyId={setSurveyId}
                      statusType={data?.survey_status}
                    />
                    
                  </div>
                
                </div>
               
              </div>
              
              
          
            
            );
            
          })) 
          
          
          
          
          || (
            <div className="Dashboard-empty-header-content">
              <div className="Dashboard-empty-header"> Embark on your journey to create a survey.
                </div>
                <SurveyImage/>
            
            </div>
            // <img src='SurveyImage'/>
          // <div className="loader">
          //   Loading<span className="dot1">.</span>
          //   <span className="dot2">.</span>
          //   <span className="dot3">.</span>
          // </div>
        )}
        <div className="pagination-home">




<Pagination
              count={Math.ceil(filteredSurveys?.length / surveysPerPage)}
              page={page}
              onChange={(event, value) => setPage(value)}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
              />
              </div>
              </>
  )}
      </div>
    </>
  );
};

export default Index;
