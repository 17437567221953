import React, { useState ,useEffect} from 'react';
// import Navbar from "../BuiildBar";
import { Card, CardContent, Typography, TextField, Button } from '@mui/material';

import Contactusimg from "../../../../assets/LandingPageImages/Contactus"
import styled from "styled-components";
import TopNavBar from "../../Component/TopNavBar"
import {post} from "../../../../Utils/Function/Method"
import { zodResolver } from "@hookform/resolvers/zod";
import { string } from 'yup';
import {z} from 'zod'
import { useForm ,Controller , SubmitHandler} from 'react-hook-form';
import { error } from 'console';


const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const schema=z.object({
    user_name:z.string().trim().min(2,{message:"User name must contains atleast 2 characters"}).max(15),
    phone_number:z.string().trim().regex(/^[6-9]\d{9}$/,{message:"Enter a valid mobile number"}),
    email_id:z.string().trim().email({message:"Enter a valid email id"}),
    message:z.string().trim().min(5,{message:"Message must contains atleast 5 characters"}).max(100,{message:"Maximum 100 characters only"})
  })

  type ContactFormType=z.infer<typeof schema>

  const {handleSubmit,control,formState:{errors}}=useForm<ContactFormType>({
    resolver:zodResolver(schema)
  })

  const contactformsubmission:SubmitHandler<ContactFormType> = async (data) => {
    setSubmitted(true);
    try {
      console.log(data)
      const [sts, res] = await post('/contact_form',data); // Assuming post function returns a Promise
      console.log("Contact Form Response", res, sts);
      if (sts === 200) {
        // alert("INSERTED");
      } else {
        alert("Something went wrong. Status code: " + sts);
      }
    } catch (err) {
      console.error("Error:", err);
      alert("An error occurred");
    }
  };

  useEffect(() => {
    // Add a class to trigger the fade-in animation when the component mounts
    const timeout = setTimeout(() => {
      setSubmitted(false);
      document.getElementById('contact-form').classList.add('show');
    }, 100); // Adjust the timeout based on your needs

    return () => clearTimeout(timeout);
  }, []);
  
  return (
    <CONTACT>
  <div className="navbar-container">
  <TopNavBar/>
    </div>
    <div className='contact-container'>
    <div className='contact-image'>

   

    <Contactusimg/>
    </div>
    <div className='contact-card-form'>

    <Card className="contact-card" style={{ width: '350px' , boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.3)'}}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Get in Touch 📞
        </Typography>
        {submitted ? (
            <p>Message Submitted! Thank you for reaching out.</p>
          ) : (
        <form onSubmit={handleSubmit(contactformsubmission)} id="contact-form" className="fade-in">
          <Controller 
          control={control}
          name='user_name' render={({field})=>(<TextField
            id="username"
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            {...field}
          />)} />
          {errors.user_name && <span style={{ color: "red" }}>{errors.user_name.message}</span>}
          <Controller 
          control={control}
          name='phone_number' render={({field})=>(
            <TextField
              id="phone"
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              {...field}
            />)} />
          {errors.phone_number && <span style={{ color: "red" }}>{errors.phone_number.message}</span>}
          <Controller 
          control={control}
          name='email_id' render={({field})=>(
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              {...field}
            />)} />
          {errors.email_id && <span style={{ color: "red" }}>{errors.email_id.message}</span>}
          <Controller 
          control={control}
          name='message' render={({field})=>(<TextField
            id="message"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            {...field}
          />)} />
          {errors.message && <span style={{ color: "red" }}>{errors.message.message}</span>}
          <Button type="submit" variant="contained" color="primary" fullWidth      
          >
            Submit
          </Button>
        </form>
          )}
      </CardContent>
    </Card>
    </div>
   </div>
   <div className='contact-footer'>
    <div className='contact-footer-left'> 

    <p>
      Toll Free-6385861832
    </p>
    <p>
      Email-info@rivazia.com
    </p>
    </div>
    <div className='contact-footer-right'> 
    <p className='connect'>Connect with us</p>

    <div className="social-icons">
  <a href=" https://www.facebook.com/profile.php?id=100087495820024" className="icon-link">
    <li className="fab fa-facebook-f"></li>
  </a>
  <a href="twitter.com/@RivaziaInfo" className="icon-link">
    <li className="fab fa-twitter"></li>
  </a>
  <a href="https://www.instagram.com/the_rivazia/" className="icon-link">
    <li className="fab fa-instagram"></li>
  </a>
  <a href="https://www.linkedin.com/company/rivazia/" className="icon-link">
    <li className="fab fa-linkedin-in"></li>
  </a>
 
</div>
</div>

   </div>
    </CONTACT>
  );
};

export default ContactForm;




const CONTACT=styled.div`
  

  .contact-container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 8%;
  }
  .navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999; /* Set a high z-index value to ensure it appears above other content */
}
.contact-footer{
  padding: 2.30rem;
  display: flex;
  justify-content: space-between;
}
.social-icons {
  display: flex; /* Display icons in a row */
}

.social-icons .icon-link {
  margin-right: 20px; /* Add margin to the right of each icon */
}
.connect{
  color: gray;
}

@media screen and (max-width: 767px){
.contact-container{
  flex-wrap: wrap;
  padding:5.5rem;
  /* margin-top: 20px; */
  padding-top:50%;
}
.contact-image{
  /* padding:1.5rem; */


}
.contact-card-form{
  /* padding: 5.5rem; */
  /* margin-left: 20%; */
  margin-right: 150%;
  width:2%;
  height: 20%;
}


}

/* ------------------Animation for card------------------------------- */

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}



/* ----------------------Animation for image----------------- */



/* ------------------------Media Query---------------- */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .contact-container{
  flex-wrap: wrap;
 
}
.contact-card-form{
 
  /* width:10%; */

}
 

}


`