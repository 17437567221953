import React, { useState, useContext, useEffect } from "react";

// import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material//Paper";

import { useValidPassword, useValidUsername } from "../../hooks/useAuthHooks";
import { Password, Username } from "../../Components/Auth/authComponents";

import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
// import RivaziaLogo from "../../assets/Rivazia";
// import RivaziaLogo from "../../assets/rivaziaBlack";
import RivaziaLogo from "../../assets/rivaziaAnim";
import { z } from "zod";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// const useStyles = makeStyles({
//   root: {
//     height: "100vh",
//   },
//   hover: {
//     "&:hover": { cursor: "pointer" },
//   },
// });

const SignIn: React.FunctionComponent<{}> = () => {
  const schema = z.object({
    username: z.string().email(),
    password: z.string(),
  });

  type LoginSubmitType = z.infer<typeof schema>;

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginSubmitType>({ resolver: zodResolver(schema) });
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { signIn }: any = useContext(AuthContext);
  const loginSubmit: SubmitHandler<LoginSubmitType> = async(
    data: LoginSubmitType
  ) => {
    const response =await signIn(data.username, data.password);
    // console.log("RESPONSE>>>>>>>>>",response);                *
    if(response){
      // console.log("RESPONSE IS TRIGGERED")
      console.log("Error response ?",response,response.message)
      setError(response.message)
    }
  };
  const { isAuthenticated }: any = useContext(AuthContext);
  console.log(error)

  const passwordResetClicked = async () => {
    navigate("/reset-password");
  };
  useEffect(() => {
    if (isAuthenticated) {
      // setUserName(username);
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  return (
    <Grid
      // className={classes.root}
      style={{ height: "100vh" }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        xs={11}
        sm={6}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
      >
        <Paper style={{ width: "100%", padding: 32 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* Title */}
            <RivaziaLogo width="200" height="100" />
            <Box m={2}>
              <Typography variant="h4">Log in</Typography>
            </Box>

            {/* Sign In Form */}
            <Box width="80%" m={1}>
              {/* <Username
                usernameIsValid={usernameIsValid}
                setUsername={setUsername}
              />{" "} */}
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={"Email Address"}
                    {...field}
                  />
                )}
              />
              {errors.username && <span style={{ color: "red" }}>{errors.username.message}</span>}
            </Box>

            <Box width="80%" m={1}>
              {/* <Password
                label="Password"
                passwordIsValid={passwordIsValid}
                setPassword={setPassword}
              /> */}
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    label={"Password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
              {errors.password && <span style={{ color: "red" }}>{errors.password.message}</span>}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box onClick={passwordResetClicked} mt={2}>
                  <Typography variant="body2" className="pointer">
                    Forgot Password?
                  </Typography>
                </Box>
              </Grid>
            </Box>

            {/* Error */}
            <Box mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" justifyContent="center">
                <Box m={1}>
                  <Button color="primary" variant="contained">
                    Cancel
                  </Button>
                </Box>
                <Box m={1}>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={handleSubmit(loginSubmit)}
                    // onClick={() => {
                    //   signIn(username, password);
                    //   // handleSubmit();
                    // }}
                  >
                    Log In 🔓
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Box mt={2}>
              <Box>
                <Typography
                  variant="body1"
                  className="pointer"
                  onClick={() => navigate("/sign-up")}
                >
                  Register a new account
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SignIn;
