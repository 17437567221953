
import React, { useEffect, useState } from 'react'
import RivaziaLogo from "../../../../assets/rivaziaAnim";
import { Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import HomeImage from "../../../../assets/LandingPageImages/NewRivazia";



export default function Index() {
  const navigate = useNavigate();
 const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <TOPNAV>
    
    
    <div  className={`Home-TopContainer ${scrolled ? 'scrolled' : ''}`}>
    <div className='Home-LogoAni'>
            <RivaziaLogo width="150" height="80" />
                </div>
                <div>
                <ul className='ul'
                
              >
                <li style={{ margin: "0 1em", fontSize: "1.2em" }}>
                  <a href="/">Home</a>
                </li>
                <li style={{ margin: "0 1em", fontSize: "1.2em" }}>
                  <a href="/About">About</a>
                </li>

                <li style={{ margin: "0 1em", fontSize: "1.2em" }}>
                  <a href="/Contact">Contact</a>
                </li>
                {/* <li style={{ margin: "0 1em", fontSize: "1.2em" }}>
                  <a href="/Plans">Plans</a>
                </li> */}
                <li style={{ margin: "0 1em", fontSize: "1.2em" }}>
                  <a href="/PrivacyPolicy">Privacy&Policy</a>
                </li>
                {/* <li style={{ margin: "0 1em", fontSize: "1.2em" }}>
                  <a href="/TermsandConditions">Terms & Condition</a>
                </li> */}
              </ul>

                </div>
                <div className="nav-button">
          <Button
                 onClick={() => navigate("/log-in")}
                 variant="outlined"
                 style={{ marginRight: "10px" }}
               >
                 Login 🔓
               </Button>
               <Button onClick={() => navigate("/sign-up")} variant="contained">
                 Sign-up 🔑
               </Button>
             </div>
                </div>
               


    </TOPNAV>
  )
}

const TOPNAV= styled.div`
  
.ul{
  display:flex;
 list-style: none;
   margin: 0;
  padding: 0

}
.Home-TopContainer{
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* position: fixed; */
  /* top: 0;
  left: 0;
  width: 100%; */
  background-color: transparent; /* Set the initial background color */
  /* transition: background-color 0.3s ease; */

}
/* .Home-TopContainer.scrolled {
  background-color:var(--green); /* Change the background color when scrolled */
/* } */ */
.Home-Top-Content{
  display: flex;
  justify-content: space-around;
  margin-top: 8%;
  margin-bottom: 12%;
  /* padding:2.5rem; */
}
.Green-text{
  color:var(--green);


}
@media screen and (max-width: 767px) {
  .Home-TopContainer{
    flex-wrap: wrap;
    padding:2.5rem;

  }
  .Home-Top-Content{
    flex-wrap: wrap;
    padding:2.5rem;
  }
  .ul{
font-size:xx-small;
margin-bottom: 5%;

}
.Top-NavImg{
 
 padding-right: 5.5rem;
 
   /* padding:3.5rem; */
}

}
@media screen and (min-width: 768px) and (max-width: 1023px) {
.Home-Top-Content{
  padding:1.5rem;
  margin-right: 20%;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

 .ul{
  font-size:small;
 }

}
}

`
