import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import Data from "../ReportResponse/data.json";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { AuthContext } from "../../../contexts/AuthContext";
import ErrorIcon from "../../../assets/Nofile.png";
import { get } from "../../../Utils/Function/Method";
import { useParams } from "react-router-dom";
import { Icon, Pagination } from "@mui/material";


function Index() {
  const { surveyId } = useParams();
  const surveyInt = parseInt(surveyId);
  const [expandedQId, setExpandedQId] = useState(null);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>();
    const itemsPerPage = 10; 
  // console.log("IDDDDDDd",surveyInt)
  const [surveyResponse, setSurveyResponse] = useState([]);
  async function fetchData() {
    setIsLoading(true)
    try {
      const response = await get(`/response_info/${surveyInt}`);
      setSurveyResponse(response[1][0]);
    
    } catch (error) {
      console.log("fetch error ", error);
    }
    finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  console.log("Survey response",surveyResponse)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  
  const currentItems = surveyResponse.map((data)=>{
    const answers=data.answers
    return {
      ...data,answers:answers.slice((page-1)*10,((page-1)*10)+10)
    }
  })
 
  console.log("Current items >>>>",currentItems)
  return (
    <>
      {isLoading ? (
      <div>Loading...</div>
    ) : (
      <div className="report-response-table">
        {surveyResponse?.length > 0 ? (
          <>
          <table>
          <thead>
          <tr>
          {surveyResponse
           ?.sort((a, b) => a.q_id - b.q_id) 
          ?.map((question, index) => (
            <th key={question.q_id} className="ellipsis-cell">
             <span
                className={expandedQId === question.q_id ? "full-text" : ""}
                onClick={() => setExpandedQId(expandedQId === question.q_id ? null : question.q_id)}
              > 
               {question.q_title.length>20 ?expandedQId === question.q_id? question.q_title : question.q_title.slice(0, 20) + '...' : question.q_title}
              </span>
            </th>
          ))}
        </tr>
          
          </thead>
          <tbody>
         
             {currentItems && currentItems.length > 0 && currentItems[0].answers.map((data, index) => (
          <tr key={index}>
            {currentItems.map((newData) => (
                <td key={index}>
                {newData.answers[index] ?newData.answers[index].q_answer?newData.answers[index].q_answer:"null" : "null"}
                </td>
              )
            )}
          </tr>
        ))}
          </tbody>
        </table>
          
          
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Pagination
          
            count={Math.ceil(surveyResponse && surveyResponse[0] ? surveyResponse[0].answers.length / itemsPerPage : 0)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Box>
        </>
        ) : (
          <div className="report-no-data">
            <div className="report-no-data-image">
              <img src={ErrorIcon} alt="filenotfound" />
            </div>
            <div className="report-no-data-header">
              <p> You haven't created any questions.</p>
              <p>Go Back to start buillding your survey or you dont receive any responses.</p>
            </div>
            <div className="report-no-data-button">
              {/* 
              <button  onClick={()=>navigate('/edit-survey')} >Create Survey</button> */}
            </div>
          </div>
        )}
      </div>

    )}
     
    </>
  );
}
export default Index;
