import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { get, uploadFiles } from "../../Utils/Function/Method";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/rivaziaAnim";
import ImageIcon from "../../../src/assets/svg/chhh.png";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { post } from "../../Utils/Function/Method";

import "./style.css";
// import { Navigate, useNavigate } from "react-router-dom";

function QuestionForm() {
  const [questionIndex,setQuestionIndex]=useState(1)
  const { survey_id } = useParams();
  const navigate = useNavigate();
  const surveyId = parseInt(survey_id);
  const [surveyInfo, setSurveyInfo] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [disable, setDisable] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [selectedOption, setSelectedOption] = useState<string|boolean>(false);
  const [selectedCheckBoxIndex,setSelectedCheckBoxIndex]=useState<Array<number>>()
  const [selectedBoolean, setSelectedBoolean] = useState();
  const [selectedNps, setSelectedNps] = useState<any>();
  const [completed, setCompleted] = useState(false);
  const [shouldFocus, setShouldFocus] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [completionRate, setCompletionRate] = useState(0);
  const [previousQuestion, setPreviousQuestion] = useState(0);
  const [shouldShowSubmitButton, setShouldShowSubmitButton] = useState(false);
  const [rating, setRating] = useState(0);
  const [fileUpload, setFileUpload] = useState<any>("");

  const [responseSet, SetResponseSet] = useState("");
  const surveyrespose = async () => {
    const response = JSON.stringify(formData);
    // console.log(response)
    const temp = { survey_id: surveyId, response_set: response };

    try {
      const [sts, res] = await post(`/answer_info/response_set`, temp);
      // console.log("RESPONSEE", res, sts);
      if (sts === 200) {
        // alert("INSERTED");                   *
      } else {
        alert("Went Wrong");                  
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await get(`/survey_info/${surveyId}`);
        setSurveyInfo(response[1][0]);
        const parsedQuestions = JSON.parse(response[1][0].question_set);
        setQuestions(parsedQuestions);
        setCurrentQuestion(0);
        setAnswers({});
      } catch (error) {
        console.log("FetchError", error);
      }
    }
    fetchData();
  }, [surveyId]);
  // console.log(surveyInfo);

  const question = questions[currentQuestion];
  useEffect(() => {
    document.addEventListener("keypress",handleInputKeyPress)
  
    return () => {
      document.removeEventListener("keypress",handleInputKeyPress)
    }
  }, [])

  const [formData, setFormData] = useState([]);

  function handleSubmit(event) {
    event.preventDefault();
    localStorage.setItem(`completionStatus_${surveyId}`, JSON.stringify(false));    
    // #local status into false change this into true

    setCompleted(true);
  }
  useEffect(() => {
    const completionStatus = JSON.parse(
      localStorage.getItem(`completionStatus_${surveyId}`)
    );
    if (completionStatus) {
      setCompleted(true);
    }
  }, [surveyId]);

  // let completionMessage = '';

  // if (completed) {
  //   completionMessage = `You have already responded to Survey ${surveyId}.`;
  // } else {
  //   completionMessage = `Please complete Survey ${surveyId}.`;
  // }

  const handleInputKeyPress = (event:KeyboardEvent) => {
    console.log("Key pressed",event)
  };
  
  const verifyEmail = (mail) => {
    if (mail.indexOf("@") == -1 || mail.indexOf(".") == -1) {
      setDisable(true);
      return setEmailError("* Enter valid email id");
    } else {
      setDisable(false);
    }
  };

  function generateRandomString() {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const generateUniqueKey = () => {
    const date = new Date();
    const randomLetter = generateRandomString();
    const components = [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ];
    const uniquekey = randomLetter + components.join("");
    return uniquekey;
  };

  async function handleInput(event) {
    let { value } = event.target;

    if (question?.type === "multipleChoice") {
      let arr=typeof selectedOption==="boolean"?[]:selectedOption.split(",")
      if(arr.includes(value)){
        arr=arr.filter((val)=>{
          //val!==value || val!==""
          if(val!==""){
            return val!==value
          }
          return false
        })
        setSelectedOption(`${arr.join(",")}`)
      // console.log("Array inside removing multiple choice",arr)           *
      }else{
        arr=arr.filter(prevValue=>prevValue!=='')
        arr.push(value)
        setSelectedOption(`${arr.join(",")}`)
      // console.log("Array inside adding multiple choice",arr)      *
      }
      setFormData((prevState) => ({
        ...prevState,
        [question?.id]: {
          id: question?.id,
          title: question?.text,
          answer: `${arr.join(",")}`,
          type: question?.type,
        },
      }));
    } else if (question?.type === "boolean") {
      setSelectedBoolean(value);

      setFormData((prevState) => ({
        ...prevState,
        [question?.id]: {
          id: question?.id,
          title: question?.text,
          answer: value,
          type: question?.type,
        },
      }));
    } else if (question?.type === "Rating") {
      setRating(value);
      setFormData((prevState) => ({
        ...prevState,
        [question?.id]: {
          id: question?.id,
          title: question?.text,
          answer: value,
          type: question?.type,
        },
      }));
    } else if (question?.type === "nps") {
      setSelectedNps(value);

      setFormData((prevState) => ({
        ...prevState,
        [question?.id]: {
          id: question?.id,
          title: question?.text,
          answer: value,
          type: question?.type,
        },
      }));
    } else if (question?.type === "upload") {
      setFileUpload(value);
      const file = event.target.files[0];
      const fileKey = generateUniqueKey();
      if (file) {
        value = `https://sam-rivazia-test-bucket.s3.ap-south-1.amazonaws.com/${surveyId}/${fileKey}.${file.name
          .split(".")
          .pop()}`;
        const fileDetails = {
          content_type: file.type,
          path: `${survey_id}/`,
          file_name: fileKey,
          extension: file.name.split(".").pop(),
        };
        try {
          await uploadFiles(file, fileDetails);
          setFormData((prevState) => ({
            ...prevState,
            [question?.id]: {
              id: question?.id,
              title: question?.text,
              answer: `https://sam-rivazia-test-bucket.s3.ap-south-1.amazonaws.com/${surveyId}/${fileKey}.${file.name
                .split(".")
                .pop()}`,
              type: question?.type,
              fileData: file, // add the file data to the answer object
            },
          }));
        } catch (err) {
          console.log("Upload Error" + err);
        }
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [question?.id]: {
          id: question?.id,
          title: question?.text,
          answer: value,
          type: question?.type,
        },
      }));
    }

    if (question.skipLogic) {
      for(const l of question.skipLogic){
        console.log("Inside for each ",l.choice,l.skipTo,value)
        if(l.choice==="isAnswered" && l.skipTo==="completed"  && value && value!==""){
          console.log("Is answered is triggering")
          // setSelectedOption(false)
            setShouldShowSubmitButton(true)
          }else if(l.choice==="isNotAnswered" && l.skipTo==="completed" && (value===undefined || value==="")){
          console.log("Is not answered is triggering")
          // setSelectedOption(false)
            setShouldShowSubmitButton(true)
          }else if (l.choice === value && l.skipTo === "completed") {
          console.log("else if is triggering")
          // setSelectedOption(false)
          // currentQuestion === questions.length-1
          setShouldShowSubmitButton(true);
          return;
          // setCurrentQuestion(questions.length - 1);
        } else{
          // setSelectedOption(false)
          console.log("last one is triggering")
          setShouldShowSubmitButton(false);
        }
        // else if (l.choice === value && l.skipTo !== "completed") {
        //   console.log("last one is triggering")
        //   setShouldShowSubmitButton(false);
        // }
      };
    }
    const isEmailValid = /^\S+@\S+\.\S+$/.test(value);
    // email validation regex
    // setFormData({ ...formData, [name]: value });
    // setFormData((prevState) => ({
    //   ...prevState,
    //   [question.id]: {
    //     id: question.id,
    //     title: question.text,
    //     answer: value,
    //     type: question.type,
    //   },
    // }));
    if (question?.type === "email") {
      if (!isEmailValid) {
        setEmailError("* Enter a valid email id");
      } else {
        setEmailError("");
      }
    }
    setAnsweredQuestions(Object.keys(formData)?.length + 1);
    setCompletionRate(
      Math.min(
        Math.round(
          ((Object.keys(formData)?.length + 1) / questions?.length) * 100
        ),
        100
      )
    );
  }

  //   if (question.type === "email") {
  //     setDisable(
  //       !isEmailValid || (questions[currentQuestion].required && !value)
  //     );
  //     if (!isEmailValid) {
  //       setEmailError("* Enter a valid email id");
  //     } else {
  //       setEmailError("");
  //     }
  //   }
  // }

  function handlePhoneInput(value) {
    // setFormData({ ...formData, [question.id]: value });
    setFormData((prevState) => ({
      ...prevState,
      [question?.id]: {
        id: question?.id,
        title: question?.text,
        answer: value,
        type: question?.type,
      },
    }));
  }
  function handleCheckbox(event,index) {
    const { name, value, checked } = event.target;
    let updatedArray = formData[name] ? formData[name]?.answer.split(",") : [];
    if (checked) {
      updatedArray.push(value);
    } else {
      updatedArray = updatedArray.filter((item) => item !== value);
    }

    const updatedAnswer = updatedArray.join(",");
    setFormData((prevState) => ({
      ...prevState,
      [name]: {
        id: name,
        title: question?.text,
        answer: updatedAnswer,
        // answerString: updatedAnswer,
        type: question?.type,
      },
    }));
    if(selectedCheckBoxIndex){
      if(selectedCheckBoxIndex.includes(index)){
        let arr=[...selectedCheckBoxIndex]
        arr=arr.filter((val)=>val!==index)
        setSelectedCheckBoxIndex(arr)
      }else{
        let arr=[...selectedCheckBoxIndex]
        arr.push(index)
        setSelectedCheckBoxIndex(arr)
      }
    }else{
      setSelectedCheckBoxIndex([index])
    }
  }

  

  function handleNext() {
    
    const currentAnswer = formData[question?.id]?.answer;
    console.log(formData);
    if (!currentAnswer) {
      setFormData((prevState) => ({
        ...prevState,
        [question?.id]: {
          id: question?.id,
          title: question?.text,
          answer: null,
          type: question?.type,
        },
      }));
    }

    setAnsweredQuestions(answeredQuestions + 1);

    setCompletionRate(
      Math.min(
        Math.round(
          ((Object.keys(formData).length + 1) / questions.length) * 100
        ),
        100
      )
    );

    setShouldFocus(true);
    // const currentAnswer = formData[question.id]?.answer;
    let skipTo = currentQuestion + 1;
    console.log("Console before skip logic condition",question,question.skipLogic,"Form data",formData,currentAnswer)
    if (question.skipLogic) {
      question.skipLogic.forEach((l) => {
        console.log("Checking why is not answered not working","l.choice===isAnswered",l.choice==="isAnswered","formData.length>0 ",formData.length>0 ,"formData!==undefined",formData!==undefined,"currentAnswer",currentAnswer," currentAnswer!==", currentAnswer!=="")
        // if(l.choice==="isAnswered"  && formData.length>0 && formData!==undefined && currentAnswer && currentAnswer!==""){
        if(l.choice==="isAnswered" && formData!==undefined && currentAnswer && currentAnswer!==""){
          console.log("IS answered is triggered")
          if(l.skipTo==="completed"){
            setCompleted(true)
          }else{
          skipTo=Number(l.skipTo)-1
          }
          console.log("Skip to is ",skipTo)
        }else if(l.choice==="isNotAnswered" && (formData.length===0 || currentAnswer===undefined || currentAnswer==="")){
          console.log("IS not answered is triggered")
          if(l.skipTo==="completed"){
            setCompleted(true)
          }else{
          skipTo=l.skipTo-1
          }
        }else if (l.choice === currentAnswer) {
          skipTo = l.skipTo - 1;
        }
      });
    }
    setPreviousQuestion(currentQuestion);
    setCurrentQuestion(skipTo);
    setSelectedOption(false)
    setSelectedCheckBoxIndex(undefined)
    setAnsweredQuestions(answeredQuestions + 1);
    setQuestionIndex((prev)=>prev+1)
  }
  useEffect(()=>{
    question && question.skipLogic && question.skipLogic.forEach((l) => {
      if(l.choice==="isNotAnswered" && l.skipTo==="completed"){
          setShouldShowSubmitButton(true)
        console.log("Second if")
        }
    });
  },[question])

  function handlePrevious() {
    setShouldFocus(true);
    setCurrentQuestion(previousQuestion);
    setSelectedOption(false)
    // setCurrentQuestion(currentQuestion - 1);
    const currentQuestionData = formData[questions[currentQuestion].id];
    const isEmailValid =
      currentQuestionData?.type === "email" &&
      /^\S+@\S+\.\S+$/.test(currentQuestionData?.answer);
    const isRequiredAndEmpty =
      questions[currentQuestion].required && !currentQuestionData.answer;
    setDisable(!isEmailValid || isRequiredAndEmpty);
    setQuestionIndex((prev)=>{
      if(prev<=1){
        return prev
      }else{
        return prev-1
      }
    })
  }

  // function handlePrevious() {
  //   setShouldFocus(true);
  //   setCurrentQuestion(currentQuestion - 1);
  // }

  if (questions?.length === 0) {
    return <div>Loading...</div>;
  }

  let inputField;
  if (question?.type === "TextInput") {
    inputField = (
      <input
        type="text"
        name={question?.id?.toString()}
        value={formData[question?.id]?.answer || ""}
        onChange={handleInput}
        className="input-field"
        placeholder="please enter your response"
        autoFocus={shouldFocus}
        style={{ marginTop: "10px", fontSize: "18px", fontWeight: "semibold" }}
        // onKeyPress={handleInputKeyPress}
      />
    );
  } 
  
  if (question?.type === "LongText") {
    inputField = (
      <textarea
        name={question?.id?.toString()}
        value={formData[question?.id]?.answer || ""}
        onChange={handleInput}
        className="input-field"
        placeholder="please enter your response"
        autoFocus={shouldFocus}
        style={{ marginTop: "10px", fontSize: "18px", fontWeight: "semibold" }}
        // onKeyPress={handleInputKeyPress}
      />
    );

  } 
  
  else if (question?.type === "phone") {
    inputField = (
      <PhoneInput
        name={question?.id?.toString()}
        value={formData[question?.id]?.answer || ""}
        // onChange={(event) => handlePhoneInput(event)}
        onChange={handlePhoneInput}
        className="input-field"
        placeholder="please enter an phone number"
        autoFocus={shouldFocus}
        style={{ marginTop: "10px", fontSize: "18px", fontWeight: "semibold" }}
        // onKeyPress={handleInputKeyPress}
      />
    );
  } else if (question?.type === "email") {
    inputField = (
      <>
        {/* <p style={{ marginTop: '10px' }}></p> */}
        <input
          type="email"
          // onKeyPress={handleInputKeyPress}
          name={question?.id?.toString()}
          value={formData[question?.id]?.answer || ""}
          onChange={handleInput}
          className="input-field"
          placeholder="please enter an email"
          autoFocus={shouldFocus}
          style={{
            marginTop: "10px",
            fontSize: "18px",
            fontWeight: "semibold",
          }}
        />
        <p className="required-error">{emailError}</p>
      </>
    );
  } else if (question?.type === "multipleChoice") {
    inputField = (
      <>
        {question?.choices && (
          <>
            {question?.choices && (
              <div className="choices-container">
                {question?.choices?.map((choice: any, idx: any) => (
                  <button
                    // onKeyPress={handleInputKeyPress}
                    type="button"
                    className={`selectable-button ${
                      typeof selectedOption!=="boolean" && selectedOption.split(",").includes(choice)? "selected" : ""
                    }`}
                    key={idx}
                    name={question?.id?.toString()}
                    value={choice}
                    onClick={handleInput}
                    style={{
                      fontSize: "16px",
                      fontWeight: "semibold",
                      padding: "15px 20px",
                    }}
                  >
                    {choice}
                  </button>
                ))}
              </div>
            )}
          </>
        )}
      </>
    );
  } 
  else if (question?.type === "Radio Button") {
    inputField = (
      <>
        {question?.choices && (
          <>
            {question?.choices && (
              <div className="choices-container">
                {question &&
                  question?.choices &&
                  Array.isArray(question?.choices) &&
                  question?.choices?.map((choice: any, idx: any) => (
                    <>
                    <input
                      // onKeyPress={handleInputKeyPress}
                      type="radio"
                      className={`selectable-button ${
                        selectedOption === choice ? "selected" : ""
                      }`}
                      key={idx}
                      name={question?.id?.toString()}
                      value={`${choice} `}
                      onClick={handleInput}
                      id={choice}
                      style={{
                        fontSize: "16px",
                        fontWeight: "semibold",
                        padding: "15px 20px",
                      }}
                    />
                    <label htmlFor={choice}>{choice}</label>
                    </>
                  ))}
              </div>
            )}
          </>
        )}
      </>
    );
  }
  else if (question?.type === "boolean") {
    inputField = (
      <>
        <button
          type="button"
          className={`selectable-button ${
            selectedBoolean === "Yes" ? "selected" : ""
          }`}
          name={question?.id?.toString()}
          value="Yes"
          onClick={handleInput}
        >
          <ThumbUpOffAltIcon />
          Yes
        </button>
        <button
          type="button"
          className={`selectable-button ${
            selectedBoolean === "No" ? "selected" : ""
          }`}
          name={question?.id?.toString()}
          value="No"
          onClick={handleInput}
        >
          <ThumbDownOffAltIcon />
          No
        </button>
      </>
    );
  } else if (question?.type === "dropdown") {
    inputField = (
      <>
        {question?.choices && (
          <>
            <select
              name={question?.id?.toString()}
              onChange={handleInput}
              // onKeyPress={handleInputKeyPress}
              style={{ fontSize: "18px", fontWeight: "semibold" }}
              //  style={{ fontSize: '16px' }}
            >
              Select Option
              {question?.choices?.map((choice: any, idx: any) => (
                <option key={idx} value={choice}>
                  {choice}
                </option>
              ))}
            </select>
          </>
        )}
      </>
    );
  } else if (question?.type === "Rating") {
    inputField = (
      <div className="star-rating">
        {[...Array(5)]?.map((_, i) => {
          const ratingValue = i + 1;
          return (
            <label key={i}>
              <input
                type="radio"
                name={question?.id?.toString()}
                value={ratingValue}
                onClick={handleInput}
                // onKeyPress={handleInputKeyPress}
                // onClick={() => handleClick(ratingValue)}
              />
              <span className={ratingValue <= rating ? "active" : null}>★</span>
            </label>
          );
        })}
      </div>
    );
  } else if (question?.type === "checkbox") {
    inputField = (
      <>
        {question?.choices && (
          <>
            {question?.choices?.map((choice: any, idx: any) => (
              <label key={idx} style={{ fontSize: "20px", padding: "3px" }}>
                <input
                  type="checkbox"
                  name={question?.id?.toString()}
                  value={choice}
                  checked={selectedCheckBoxIndex?selectedCheckBoxIndex.includes(idx):false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=>handleCheckbox(event,idx)}
                  // onKeyPress={handleInputKeyPress}
                />
                {choice}
              </label>
            ))}
          </>
        )}
      </>
    );
  } else if (question?.type === "nps") {
    const buttons = [];
    for (let i = question?.min || 0; i <= (question?.max || 10); i++) {
      buttons.push(
        <button
          type="button"
          value={i}
          className={selectedNps == i ? "active" : null}
          onClick={handleInput}
        >
          {i}
        </button>
      );
    }
    inputField = <div className="nps">{buttons}</div>;
  } else if (question?.type === "upload") {
    inputField = (
      <>
        <div>
          <input
            type="file"
            name={question?.id?.toString()}
            onChange={handleInput}
          />

          <div></div>
        </div>
      </>
    );
  }
  // const navigate = useNavigate();
  return (
    <>
      {surveyInfo ? (
        <>
          <div className="prev-main-container">
            {/* <div className="bg-image"> 

          <Whitebgimage/>
            </div> */}
            <div className="survey-main-form">
              {questions?.length > 0 ? (
                <>
                  {currentQuestion < questions?.length && !completed ? (
                    <>
                      <form onSubmit={handleSubmit}>
                        {question.text && (
                          <h2 className="ques-title">
                            {questionIndex}. {question?.text}
                          </h2>
                        )}
                        <div className="prev-opt-container">
                          {inputField}
                          {question?.required && (
                            <p className="required-error">*Answer Required</p>
                          )}
                        </div>
                        <div className="prev-nav-btns">
                          {currentQuestion > 0 && (
                            <button type="button" onClick={handlePrevious}>
                              Previous
                            </button>
                          )}

                          {currentQuestion < questions?.length - 1 &&
                            !shouldShowSubmitButton && (
                              <button
                                disabled={
                                  (question?.required &&
                                    !formData[question?.id]?.answer) ||
                                  (question.type === "email" &&
                                    !/^\S+@\S+\.\S+$/.test(
                                      formData[question?.id]?.answer
                                    ))
                                }
                                // disabled={
                                //   disable ||
                                //   (question.required && !formData[question.id])

                                // }
                                type="button"
                                onClick={handleNext}
                              >
                                Next
                              </button>
                            )}
                          {(currentQuestion === questions?.length - 1 ||
                            shouldShowSubmitButton) && (
                            <button
                              onClick={() => {
                                {
                                  surveyrespose();
                                }
                              }}
                              disabled={
                                (question.required &&
                                  !formData[question?.id]?.answer) ||
                                (question.type === "email" &&
                                  !/^\S+@\S+\.\S+$/.test(
                                    formData[question?.id]?.answer
                                  ))
                              }
                              type="submit"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                  ) : (
                    <div className="survey-container">
                      <div className="survey-info">
                        <p>Thanks for taking the survey!</p>
                        {/* <h6>See how easy it is to collect feedback with our rivazia</h6> */}
                        <button>
                          <a href="https://rivazia.com/">
                            create your own survey
                          </a>
                        </button>
                        {/* <p>Your answers are:</p>
                        <ul>
                          {Object.entries(formData).map(([key, value]) => {
                            const { title, answer, id } = value;
                            return (
                              <div key={key}>
                                <p>
                                  {id}. {title}: {answer}
                                </p>
                              </div>
                            );
                          })}
                        </ul> */}
                        {/* <button onClick={restartSurvey}>Restart Survey</button> */}
                      </div>
                      <div className="survey-image">
                        <img src={ImageIcon} alt="Logo" />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                "No Questions Added"
              )}
            </div>
            <div className="footer-heading">
              {/* <div className="completion-rate">
                {Array.from({ length: questions.length }, (_, i) => (
                  <div
                    key={i}
                    className={`circle ${
                      i < answeredQuestions ? "filled" : ""
                    }`}
                  ></div>
                ))}
                <span className="percentage">
                  {Math.round(completionRate)}% complete
                </span>

               </div> */}
              <div className="prev-footer">
                <p>Created By</p>

                <Logo />
              </div>
            </div>
          </div>
        </>
      ) : (
        "Survey is not live/Active."
      )}
    </>
  );
}

export default QuestionForm;
