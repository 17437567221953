import React from 'react'
import "./style.css";
import PricingList from "./Component/PricingList"
// import Lapimage from "../../../../assets/"
import Laponeimage from "../../../../assets/LandingPageImages/lapone";
import Laptopimage from "../../../../assets/LandingPageImages/lapthree"
import Lapbottomimage from "../../../../assets/LandingPageImages/laptwo"

import TopNavBar from "../../Component/TopNavBar"
export default function Index() {
  return (
    <>



    <div className="navbar-container">

  <TopNavBar/>
      </div>
     
    <div className="container-plans">

   
   <div className='about-topimage'>
  
      <Laptopimage/>
      </div>  
<div className='cont'>
<div className='about-lapbottomimg'>
<Lapbottomimage/>
</div>

                 
<div className='price-content'>

<h1>Choose Your Plan!</h1>
<p className='text-p' >Our Pricing Plan, where simplicity meets value! Explore our</p>
<p className='text-p'>throughtfully designed pricing structures tailored to meet your</p>
<p className='text-p'>
  unique needs. Whether you're an individual, startup, or enterprise,
</p>
<p className='text-p'>we have the perfect plan for you.</p>
</div>
<div className='lapright-image'>

<Laponeimage/>
</div>
</div>

<div>
{/* <Lapbottomimage/> */}
</div>
</div>
<PricingList/>
      
   
    
    </>

  )
}
