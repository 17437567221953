
import React from "react";
import styled from "styled-components";
import Data from "../components/Data.json";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

// const data = {Data}

export default function App(props) {
  const{surveyReport ,q_id,chartColor}=props;
  const colors = ['#f24260', '#9260f7', '#45bd62', '#1878f3', '#f7b928', '#29bba6'];
  const randomIndex = Math.floor(Math.random() * colors.length);

  const questionData = surveyReport.filter((data) => data.q_id === q_id)[0];

  const chartData = questionData.answers
  .filter((answer) => answer.q_answer !== "None")
  .map((answer) => ({
    name: answer.q_answer,
    responsepercentage: answer.percentage,
    responses: answer.answer_count,
  }));

  return (
  
    <>
     {
  questionData.q_type === "email" || questionData.q_type === "phone" || questionData.q_type==="TextInput" ||questionData.q_type==="LongText"|| questionData.q_type==="upload"? (
    null // If the question type is email or phonenumber, don't render the chart
  ) : (
    
    <AreaR>
    <div className="graph">
    <AreaChart
      width={500}
      height={300}
      // data={Data}
      // data={data.Data}
      data={chartData}
      margin={{
        top: 10,
        right: 10,
        left: 0,
        bottom: 0
      }}
    >
      
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" padding={{left: 20}}  />
      <YAxis />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="responses"
        stackId="1"
        stroke="var(--blue)"
        fill={chartColor}
        
      />
      <Area
        type="monotone"
        dataKey="responsepercentage"
        stackId="1"
        stroke="#82ca9d"
        fill="var(--green)"
      />
    </AreaChart>
    </div>
    </AreaR>
    )
  }
     
    </>
  );
}

const AreaR =styled.div`
   .graph{
    display: flex;
    justify-content: center;
    margin: 10px auto;
   }

`