import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import Graph from "../components/Graph";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ProfileTick from "../../../assets/svg/profileTick";
import Startted from "../../../../src/assets/svg/startted";
import Avgtime from "../../../assets/svg/AvgTime";
import Completed from "../../../assets/svg/Completed";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList
  
} from "recharts";
import NoresponseImage from "../../../../src/assets/Noresponsereport.png";
import Data from "../Report-Overview/Overviewdata.json"
import { render } from "react-dom";
import {AuthContext} from "../../../contexts/AuthContext";
import { get } from "../../../Utils/Function/Method";
import { useParams } from 'react-router-dom';
const data = {Data}
const [{title}] =Data

export default function Index(props) {

  const { surveyInfo} =props
  // console.log("title",surveyInfo)
  const { surveyId } = useParams();
  // const {surveyNum}=useContext(AuthContext)
  const surveyInt = parseInt(surveyId);
  const[reportOverview,setreportOverview]=useState(null);
  // console.log("fdsf",reportOverview)
  const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
  const colors = ['#45bd62', '#f24260', '#9260f7', '#1878f3', '#f7b928', '#29bba6'];
  const randomIndex = Math.floor(Math.random() * colors.length);
  
  async function fetchData(){
    try{
      const response=await get(`/report_res_count/${surveyInt}`);
        // console.log("0", response[1][0]);
        setreportOverview(response[1][0]);


        
    } catch (error) {
      console.log("fetch error ", error);
    }
    }
    useEffect(() => {
      fetchData();
    }, []);

     
    // if (!reportOverview) {
    //   return <div>Loading...</div>;
    // }
    const chartData = reportOverview?.map((item:any) => ({
      name: item.created_date || "",
      no_of_responses: item.respondent_count ||0,
    }));

    // if (!chartData || chartData.length === 0) {
    //   return <div>No responses to display.</div>;
    // }
// console.log("Overview",chartData);
    // 
  return (
  <>
        <div className="container">
      <div className="card">
        <div className="chart-container">
          <div className="top-div">Responses</div>
          {reportOverview ? (
            <>
              
              {chartData && chartData?.length > 0 ? (
                <div className="">

              
                <BarChart
                  width={600}
                  barSize={40}
                  height={300}
                  data={chartData}
                  margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
                  className="Barchart"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="no_of_responses" fill={colors[randomIndex]} />
                </BarChart>
                </div>
              ) : (
                <div className="text-responses"> 
                  <img src={NoresponseImage} alt="Logo" />
                  <h2>You have no responses to display.</h2>
                </div>
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>

        
      </>
      
      
    
    
  );
}
