
import React from "react";
import Data from "../components/Data.json";
import styled from "styled-components";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";


const data={Data}
export default function App(props) {
  const{surveyReport ,q_id}=props;
  const questionData = surveyReport.filter((data) => data.q_id === q_id)[0];

  const chartData = questionData.answers
  .filter((answer) => answer.q_answer !== "None")
  .map((answer) => ({
    name: answer.q_answer,
    responsepercentage: answer.percentage,
    responses: answer.answer_count,
  }));

  return (
    <>
     {
  questionData.q_type === "email" || questionData.q_type === "phone" || questionData.q_type==="TextInput" || questionData.q_type==="LongText"||questionData.q_type==="upload"? (
    null // If the question type is email or phonenumber, don't render the chart
  ) : (
    <LineR>
      <div className="graph">
    <LineChart
      width={500}
      height={300}
      data={chartData}
      // data={data.Data}
      margin={{
        top: 10,
        right: 10,
        left: 0,
        bottom: 0
      }}
      >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name"  padding={{left: 20}}/>
      {/* <XAxis dataKey="option" /> */}
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="responses" stroke="var(--blue)" />
    </LineChart>
    </div>
    </LineR>
    )
  }
</>
  );
}
const LineR =styled.div`
   .graph{
     display: flex;
     justify-content: center;
     margin: 10px auto;
     
    }
    
`
