

import { Button, TextField ,Card, Box} from "@mui/material";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import styled from "styled-components";
import React, { useState } from "react";

function CopyURL({url}) {

  const [copySuccess, setCopySuccess] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopySuccess('Copied!');
    setTimeout(() => {
      setCopySuccess('');
    }, 1000);
  };

  return (
    <>
      <ShareCard>
        <div className='copy-link-header'> Share this link and start getting responses to your survey 
        </div>
    <div className="copy-Input-Container">

   
        <div className='copy-link-input'>
          <TextField
            type="text"
            className="copy-text-box"
            value={url}
            // style={{ width: '400px' }}
            // onChange={(e) => setUrl(e.target.value)}
          />
          </div>
          <div className="Copy-Button-box">

          <Button className="Copy-Button" variant="contained" onClick={handleCopy}>
            Copy
            <FileCopyIcon />
          </Button>
          </div>
          </div>
       
      </ShareCard>
      {copySuccess && <CopySuccessMessage>
        {copySuccess}
        <CheckCircleOutlineRoundedIcon/>
        
        </CopySuccessMessage>}
    </>
  );
}

export default CopyURL;

const ShareCard = styled.div`
  .copy-link-header {
    flex: auto;
    font-weight:bold;
    margin: 20px;
    font-size: large;
    justify-content: center;
    align-items: center;
    text-align: center;
    
  }
  .copy-Input-Container{
    display: flex;
    align-items:center ;
    /* justify-content: space-around; */
  }
  .Copy-Button-box{
    /* margin-left: 30px; */
    /* background-color:red; */
  }

  .copy-link-input {
    width:120%;
    display: flex;
    align-items: center;
    margin: 20px;
    & > * {
      margin-right: 10px;
    }
  }

  .copy-text-box {
  width: 120%;
    text-align: center;
    justify-content: center;
    margin-left:10%;
  }
  @media only screen and (max-width: 320px) {
.copy-link-header{
display: flex;
flex-wrap:wrap;
}
.copy-link-input{
  display: flex;
flex-wrap:wrap;
}
 .copy-Input-Container{
  display: flex;
flex-wrap:wrap;
}
.Copy-Button-box{
  margin-left: 40%;
}


}
`;

const CopySuccessMessage = styled.div`
  position: fixed;
  padding: 2px;
  bottom: 0;
  border:1px solid #ccc;
  border-top:10px solid var(--blue);
  /* border-top-color:black; */
  right: 0;
  width: 20%;
  height: 50px;
  display: flex;
  gap:10px;
  justify-content: center;
  align-items: center;
  background-color:white;
  /* background-color:var(--blue); */
  color: var(--blue);
  font-weight: bold;
  border-radius:5px;


`;