import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Nav from "../../Components/NavBar";
import { Outlet } from "react-router-dom";

const Index = () => {
  const { isAuthenticated, signOut }: any = useContext(AuthContext);

  return (
    <div>
      <div>
        {/* <Nav /> */}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Index;
