import { Button, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Theme } from "@mui/material/styles";
// import { Add } from "@material-ui/icons";
import "./style.css";
import { useNavigate } from "react-router-dom";
import OptionMenu from "../../../assets/option";
import SurveyCard from "../SurveyCard";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CreateBtn from "../../CreateSurvey/components/CreateSurveyModal";
import { post } from "../../../Utils/Function/Method";
import { getAllSurvey } from "../../../api/Dashboard/getAllSurvey";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 768px)": {
    width: "85%",
    p: 2,
  },
};
const Index = () => {
  const navigate = useNavigate();
  const { userName }: any = useContext(AuthContext);
  const { userAttr } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  // console.log("-----", userAttr?.sub);
  // console.log(userAttr)

  // console.log("User Name",userName)
  const initialValue = {
    survey_title: "",
    survey_desc: "",
    user_key: userAttr?.sub,
  }; 
  // console.log(userAttr, "hello this is user atttrubute");  *
  const [surveyModal, setSurveyModal] = useState(initialValue);
  // console.log(surveyModal);
  const onValueChange = (key) => (value) => {
    setSurveyModal({ ...surveyModal, [key]: value });
  };

  const [surveyId, setSurveyId] = useState();

  // useEffect(() => {
  //   if (surveyId) {
  //     navigate(`/create-survey/${surveyId}`);
  //   }
  // }, [surveyId]);

  const surveyTitle = async () => {
    const temp = { ...surveyModal };

    try {
      const [status, data] = await post(
        `/rv_surveymodal/${userAttr?.sub}`,
        temp
      );
      // console.log(data,"ewretgret"); // log the value of data
      // setSurveyId(data[0]);
      navigate(`/create-survey/${data[0]}`);
      if (status !== 200) {
        alert("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("post", surveyModal);

  // console.log("surveyPage")
  // --------------------Modal----------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const [title, setTitle] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");

  // const handleSurveyTitleChange = (event) => {
  //   setTitle(event.target.value);
  // };

  const handleSurveyDescriptionChange = (event) => {
    setSurveyDescription(event.target.value);
  };

  // const handleTitleChange = (event) => {
  //   setTitle(event.target.value);
  // };

  const isTitleValid = surveyModal?.survey_title.trim().length > 0;

  // const handleCreateSurvey = () => {
  //   // logic for creating a new survey
  // };
  const handleCancel = () => {
    handleClose();
    // setTitle("");
    setSurveyDescription("");
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="welcome-back">
            <Typography
              variant="h5"
              align="center"
              p={2}
              className="animated-typography"
            >
              Welcome {userAttr?.given_name + " " + userAttr?.family_name}
            </Typography>
          </div>

          <div className="create-btn">
            <Button
              onClick={handleOpen}
              // onClick={() => {
              //   navigate("/create-survey");
              // }}

              variant="contained"
              sx={{
                backgroundColor: "var(--green)",
                color: "black",
                float: "right",
                marginTop: "6px",
                "&:hover": {
                  backgroundColor: "var(--blue)",
                  color: "white",
                },
              }}
            >
              Create New
            </Button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-surveyModal?.survey_title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-surveyModal?.survey_title"
                  variant="h6"
                  component="h2"
                  color={"gray"}
                >
                  CREATE A NEW SURVEY
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <TextField
                    label="Survey Title"
                    variant="outlined"
                    fullWidth
                    value={surveyModal?.survey_title}
                    onChange={(_) =>
                      onValueChange("survey_title")(_?.target.value)
                    }
                  />
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <TextField
                    label="Survey Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    value={surveyModal?.survey_desc}
                    onChange={(_) =>
                      onValueChange("survey_desc")(_?.target.value)
                    }
                  />
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    sx={{ mt: 4 }}
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    className="modal-button"
                    sx={{ mt: 4 }}
                    onClick={() => {
                      {
                        surveyTitle();
                      }
                    }}
                    // onClick={() => {
                    //   const title = surveyTitle(); // Retrieve survey title from input or state
                    //   const numericValue = title ? 1 : 0; // Convert title to numeric value
                    //   onValueChange("survey_title")(numericValue); // Update state or input field with numeric value
                    //   navigate('/create-survey'); // Navigate to create survey page
                    // }}

                    disabled={!isTitleValid}
                  >
                    Create Survey 📝
                  </Button>
                </Box>
              </Box>
            </Modal>
          </div>

          {/* <div className="survey_title">Recent Surveys</div> */}
          <div>
            <SurveyCard  />
          </div>
        </>
      )}
    </>
  );
};
export default Index;
