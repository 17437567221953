import Newimg from "../svg/Rivaziasvg.svg"
const Newbuild = () => {
    return (
   
      <div style={{marginTop:"30%"}}>

        <img src={Newimg} 
  />
      </div>


    )
  }
  export default Newbuild;