import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import OpacityTwoToneIcon from "@mui/icons-material/OpacityTwoTone";
import styled from "styled-components";

export default function ColorPicker(props) {
  const { chartColor, handleColorChange, colors } = props;
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef(null);

  const handleColorPickerOpen = () => {
    setShowColorPicker(true);
  };

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
  };
  useEffect(() => {
    // add event listener to close color picker when clicked outside of it
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorPickerRef]);

  return (
    <ColorChoose>

    
    <div className="Report-ColorChooser">

    
      <div ref={colorPickerRef}>
      <PaletteIcon onClick={handleColorPickerOpen} 
      style={{ backgroundImage: 'linear-gradient(to bottom right, #9C27B0, #00BCD4)',
      
      }}>
    
      </PaletteIcon>
      {showColorPicker ? (
        <SketchPicker
          color={chartColor}
          onChangeComplete={handleColorChange}
          presetColors={colors}
        />
      ) : null}
      </div>
      </div>
    
    </ColorChoose>
  );
}
const ColorChoose =styled.div`
    
    .Report-ColorChooser{
    margin-right:30%,


    }
`