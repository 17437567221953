import React, { useEffect, useRef } from 'react'
import Styled from "styled-components"
import TemplateIcon from "../../../../assets/LandingPageImages/Template"
import ReportIcon from "../../../../assets/LandingPageImages/Report"
import RobustIcon from "../../../../assets/LandingPageImages/Robust"
import PannelIcon from "../../../../assets/LandingPageImages/Pannel"
export default function Index() {

  // ---------------Animation for useeffect Card Content----------------------

 
  return (
    <CARD>

   
   <div className="card-container">
  <div className="card1">
    <div className="card-content ">
      <div className="card-header">
      <TemplateIcon/>
     
        <h2 className="c">Professional Templates</h2>
      </div>
      <ul className='card1-description'>
     <li> Create A Survey With Helop Of Easy To Use Online  </li>
     <li> Survey Tools.Simply Choose From A Wide Range Of  </li>
     <li> Survey Templates For Market Research, Customer </li>
     <li>Satisfaction Surveys, And More</li>

  </ul>
      
    </div>
  </div>

  <div className="card2">
    <div className="card-content">
      <div className="card-header">
        <RobustIcon/>
        
        <h2 className="card-title">Robert Logic</h2>
      </div>
      <ul className="card2-description">
        <li>Take Advantage of Our Extensive Range Of Online Survey</li>
<li>Logic Including Survey Branching And Skip Logic,Text </li>
<li>Piping Survey Looping, And Randomization, Without</li>
<li>Having To Spend Hours Learning How To Use It.</li>

      </ul>
    </div>
  </div>

  <div className="card3">
    <div className="card-content">
      <div className="card-header">
        <ReportIcon/>
        
        <h2 className="card-title">Shareable Reports</h2>
      </div>
      <ul className="card-description">
        <li>Reports Are Intended To Provide You With A Quick And</li>
        <li>Easy Visual Representation Of Your Survey Results. You</li>
        <li>Can Share Your ReportnIn A Variety Of Ways, Including</li>
        <li>Exporting It As A File And Sending It As An Email</li>
      </ul>
    </div>
  </div>

  <div className="card4">
    <div className="card-content">
      <div className="card-header">
      <PannelIcon/>
        
        <h2 className="card-title">Panel Management</h2>
      </div>
      <ul className="card-description"
      >
        <li>
        We Will Help You Through Recurting Panel Members, Distributing
          </li>
          <li>

           Surveys, And Rewarding Panel MEmbers Which Are All  Part Of Managing 
          </li>
          <li>
          A Panel You Can Create Your Own Panel.
          </li>
           </ul>
          
          

    </div>
  </div>
</div>

 </CARD>

  )
}

const CARD=Styled.div`
  .card-container {
    margin-top:5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items:center;
  gap:5%;
  font-weight:Bold;
}

.card {
  width: 35%;
  height:190px;
  background-color: #FFD1D1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  line-height: 1.5; 
  /* font-style:italic; */
}

.card3 {
  width: 35%;
  height:190px;
  background-color:#DBD8FC;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  line-height: 1.5; 
  /* font-style:italic; */
}

.card4{
  width: 35%;
  height:190px;
  background-color: #FFEEC3;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  line-height: 1.5; 
  /* font-style:italic; */
}
.card1{
  width: 35%;
  /* height:200px; */
  background-color:  #E1FCD8;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  line-height: 1.5; 
height:190px;
}
/* .card1:hover {
  background-color: var(--blue);
  color: #fff;
  cursor: pointer;
} */

.card2{
  width: 35%;
  /* height:200px; */
  background-color: #FFD1D1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  line-height: 1.5; 
  height:180px;
}

/* .card2:hover {
  background-color: var(--blue);
  color: #fff;
  cursor: pointer;
}
.card3:hover {
  background-color: var(--blue);
  color: #fff;
  cursor: pointer;
} */


.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.card-title {
  font-size: 18px;
  margin: 0;
}

.card-description {
  font-size: 14px;
  text-align: center;
}
/* .card:hover .card-description li {
  color: #fff;
}

.card:hover .card-description li {
  color: #fff;
} */
.card1-description {
  font-size: 14px;
  text-align: center;
}
/* .card1:hover .card1-description li {
  color: #fff;
}

.card1:hover .card1-description li {
  color: #fff;
} */

.card2-description {
  font-size: 14px;
  text-align: center;
}
/* .card2:hover .card2-description li {
  color: #fff;
}

.card2:hover .card2-description li {
  color: #fff;
} */

@media screen and (max-width: 767px) {
  .card-container{
    flex-wrap: wrap;
    padding:1rem;

  }
  .card1-description{
    font-size:8px;

  }
  .c{
    font-size:x-small;
  }
  .card-title{
    font-size:x-small;
  }
  .card2-description{
    font-size:8px;
  }
  .card-description{
    font-size:8px;
  }


}
@media screen and (min-width: 768px) and (max-width: 1024px) {



.c{
  font-size:large;
}
.card1-description{
    font-size:10px;

  }


  .card2-description{
    font-size:10px;
  }
  .card-description{
    font-size:10px;
  }

}



`