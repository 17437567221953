
export const Empty = () => {
  return (
    <>
    <svg width="140" height="250" viewBox="0 0 237 365" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M230.995 22H6.00453C2.68831 22 0 24.6427 0 27.9027V359.097C0 362.357 2.68831 365 6.00453 365H230.995C234.312 365 237 362.357 237 359.097V27.9027C237 24.6427 234.312 22 230.995 22Z" fill="#DBE8EC"/>
<path d="M160.203 275.359V325H31.226C29.84 325 28.5107 324.46 27.5307 323.498C26.5506 322.536 26 321.232 26 319.872V42.1282C26 40.7681 26.5506 39.4637 27.5307 38.502C28.5107 37.5403 29.84 37 31.226 37H205.774C207.16 37 208.489 37.5403 209.469 38.502C210.449 39.4637 211 40.7681 211 42.1282V275.359H160.203Z" fill="#BBBEC2"/>
<path d="M160 325V275H211L160 325Z" fill="#DBE8EC"/>
<path d="M118.5 153H91.9227C91.4097 153 90.9057 152.867 90.4614 152.615C90.017 152.363 89.6481 152 89.3916 151.563C89.135 151.127 89 150.631 89 150.127C89 149.622 89.135 149.127 89.3916 148.69L102.68 126.063L115.969 103.437C116.225 103 116.594 102.637 117.039 102.385C117.483 102.133 117.987 102 118.5 102C119.013 102 119.517 102.133 119.961 102.385C120.406 102.637 120.775 103 121.031 103.437L134.32 126.063L147.608 148.69C147.865 149.127 148 149.622 148 150.127C148 150.631 147.865 151.127 147.608 151.563C147.352 152 146.983 152.363 146.539 152.615C146.094 152.867 145.59 153 145.077 153H118.5Z" fill="#DBE8EC"/>
<path d="M120 112H117V136H120V112Z" fill="#409CB5"/>
<path d="M121.917 145.172C122.107 143.287 120.705 141.608 118.786 141.422C116.866 141.235 115.156 142.612 114.966 144.496C114.776 146.381 116.178 148.06 118.097 148.247C120.017 148.433 121.727 147.057 121.917 145.172Z" fill="#409CB5"/>
<path d="M54 176H183" stroke="#797D83" stroke-width="3" stroke-miterlimit="10"/>
<path d="M54 199H183" stroke="#797D83" stroke-width="3" stroke-miterlimit="10"/>
<path d="M54 223H183" stroke="#797D83" stroke-width="3" stroke-miterlimit="10"/>
<path d="M54 246H183" stroke="#797D83" stroke-width="3" stroke-miterlimit="10"/>
<path d="M54 79H183" stroke="#797D83" stroke-width="3" stroke-miterlimit="10"/>
<path d="M177.032 0H59.9682C56.6721 0 54 2.61718 54 5.84562V41.1544C54 44.3828 56.6721 47 59.9682 47H177.032C180.328 47 183 44.3828 183 41.1544V5.84562C183 2.61718 180.328 0 177.032 0Z" fill="#DBE8EC"/>
</svg>

    </>
  )
}


export const Delete = () => {
    return (
      <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761205 6.17317C0.00432903 8.00043 -0.193704 10.0111 0.192148 11.9509C0.578 13.8907 1.53041 15.6725 2.92893 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM14.71 13.29L13.3 14.7L10 11.41L6.71 14.71L5.29 13.29L8.59 10L5.29 6.71L6.71 5.29L10 8.59L13.29 5.3L14.7 6.71L11.41 10L14.71 13.29Z" fill="#939393"/>
</svg>

  
      </>
    )
  }
  
  export const Edit = () => {
      return (
        <>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.82373 12.959L0.960939 19.1709C0.802739 19.6992 0.947269 20.2725 1.3374 20.6621C1.62256 20.9473 2.00586 21.1016 2.39795 21.1016C2.54199 21.1016 2.68701 21.081 2.82861 21.0381L9.04101 19.1758C9.2788 19.1045 9.4956 18.9756 9.67089 18.7998L21.0605 7.41016C21.3418 7.12891 21.5 6.74707 21.5 6.34961C21.5 5.95215 21.3418 5.57031 21.0605 5.28906L16.7104 0.939455C16.1245 0.353515 15.1753 0.353515 14.5894 0.939455L3.2002 12.3291C3.02441 12.5049 2.89551 12.7207 2.82373 12.959ZM5.58887 14.1826L15.6499 4.12109L17.8784 6.34961L7.81738 16.4111L4.63476 17.3652L5.58887 14.1826Z" fill="#939393"/>
  </svg>
  
        </>
      )
    }

export const createSurvey = () => {
    return (
      <>
      </>
    )
  }