import React, { useEffect, useRef, useState } from "react";
import "./style.css";

import Data from "../Graph/components/Data.json";


export default function Tables(props) {
  const tableRef = useRef(null);
  const [minHeight, setMinHeight] = useState("38em");

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      const numRows = table.getElementsByTagName("tr").length;
      const newMinHeight = `${numRows * 50 + 300}px`; // adjust 50 and 300 according to your needs
      setMinHeight(newMinHeight);
    }
  }, [tableRef]);
  const [showAllResponses, setShowAllResponses] = useState(false);

  const { surveyReport, q_id } = props;
  // console.log("---------------------",surveyReport)             *
  const questionData = surveyReport.filter((data) => data.q_id === q_id)[0];
  const totalResponses = questionData.answers.reduce(
    (total, answer) => total + answer.answer_count,
    0
  );

  // console.log("**************",questionData.q_answer[0][1])
  return (
    <>
      {questionData.q_type === "email" ||
      questionData.q_type === "phone" ||
      questionData.q_type === "TextInput" ||
      questionData.q_type === "LongText" ||
      questionData.q_type === "upload" ? (
        <div className="result-table" style={{ minHeight }}>
          <table style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th>Respondent</th>
                <th>Response</th>
              </tr>
            </thead>

            <tbody ref={tableRef}>
  { questionData.answers.slice(0, showAllResponses ? undefined : 5).map((answer, idx)=>
    answer && answer.q_answer && answer.q_answer !== "None" ? (
      <tr key={idx}>
        <td style={{  padding: "8px" ,backgroundColor:"#f2f2f2"}}>Anonymous</td>
        <td style={{ backgroundColor:"#f2f2f2"}}>
          <div>
            <a href={answer.q_answer} target="_blank">
              {answer.q_answer?.split('/').pop()}
            </a>
          </div>
        </td>
      </tr>
    ) : null
  )}
</tbody>




          </table>
        </div>
      ) : (
        <div className="result-table">
          <table>
            <thead>
              <tr>
                <th>Answer choices</th>
                <th>Responses</th>
                <th>Response Percentage</th>
              </tr>
            </thead>

            <tbody>
              {questionData.answers.slice(0, showAllResponses ? undefined : 4).map((answer, idx) =>
                answer.q_answer !== "None" ? (
                  <tr key={idx} style={{ backgroundColor:"#f2f2f2"}}>
                    <td>{answer.q_answer}</td>
                    <td>{answer.answer_count}</td>
                    <td>
                      {((answer.answer_count / totalResponses) * 100).toFixed(2)}%
                    </td>
                    {/* <td>{answer.percentage}%</td> */}
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      )}
      {!showAllResponses && questionData.answers.length > 5 && (
  <button onClick={() => setShowAllResponses(true)}>View all responses</button>
)}
    </>
  );
}
