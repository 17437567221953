export const FacebookIcon = () => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_349_35)">
          <path
            d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
            fill="#3B5998"
          />
          <path
            d="M31.2849 25.9787H26.824V42.3216H20.0653V25.9787H16.8508V20.2352H20.0653V16.5185C20.0653 13.8606 21.3278 9.69873 26.8842 9.69873L31.8906 9.71968V15.2947H28.2581C27.6623 15.2947 26.8244 15.5924 26.8244 16.8603V20.2405H31.8754L31.2849 25.9787V25.9787Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_349_35">
            <rect width="50" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const LinkedInIcon = () => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_349_44)">
          <path
            d="M24.9999 0C11.1928 0 0 11.1928 0 24.9999C0 38.8071 11.1928 49.9999 24.9999 49.9999C38.8071 49.9999 50 38.8071 50 24.9999C49.9999 11.1928 38.8069 0 24.9999 0ZM18.3299 37.0863H12.8409V19.3529H18.3299V37.0863ZM15.5593 17.0306C13.7666 17.0306 12.3134 15.5655 12.3134 13.7585C12.3134 11.9513 13.7668 10.4863 15.5593 10.4863C17.3517 10.4863 18.805 11.9513 18.805 13.7585C18.8051 15.5656 17.3518 17.0306 15.5593 17.0306V17.0306ZM38.8999 37.0863H33.4375V27.7777C33.4375 25.2247 32.4678 23.7995 30.449 23.7995C28.2519 23.7995 27.1041 25.2838 27.1041 27.7777V37.0863H21.8395V19.3529H27.1041V21.7413C27.1041 21.7413 28.6877 18.812 32.4479 18.812C36.2082 18.812 38.9 21.108 38.9 25.8578L38.8999 37.0863V37.0863Z"
            fill="url(#paint0_linear_349_44)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_349_44"
            x1="7.32231"
            y1="7.32231"
            x2="42.6776"
            y2="42.6776"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2489BE" />
            <stop offset="1" stop-color="#0575B3" />
          </linearGradient>
          <clipPath id="clip0_349_44">
            <rect width="50" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const TwitterIcon = () => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_349_32)">
          <path
            d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
            fill="#2DAAE1"
          />
          <path
            d="M41.0297 15.0553C39.8505 15.5786 38.584 15.9323 37.2523 16.0913C38.6114 15.2775 39.6525 13.9878 40.1441 12.453C38.8531 13.2197 37.4402 13.7596 35.9668 14.049C34.7677 12.7709 33.0579 11.9729 31.1662 11.9729C27.5352 11.9729 24.5905 14.9164 24.5905 18.5496C24.5905 19.0643 24.6485 19.5665 24.7599 20.0487C19.294 19.774 14.448 17.1558 11.2044 13.1773C10.638 14.149 10.3138 15.2787 10.3138 16.484C10.3138 18.7654 11.475 20.7783 13.2395 21.9575C12.195 21.9251 11.1734 21.6431 10.2601 21.1352V21.2183C10.2601 24.4051 12.5267 27.0623 15.5356 27.6666C14.9829 27.8181 14.4028 27.8972 13.8027 27.8972C13.3784 27.8972 12.9668 27.857 12.5647 27.7814C13.4016 30.3934 15.8305 32.2946 18.7076 32.3484C16.4567 34.1128 13.6205 35.1646 10.5402 35.1646C10.0096 35.1646 9.48523 35.1329 8.97046 35.0709C11.8814 36.9374 15.3377 38.0271 19.0519 38.0271C31.1483 38.0271 37.7639 28.0057 37.7639 19.315C37.7639 19.0297 37.7576 18.7465 37.7439 18.4643C39.0328 17.532 40.1455 16.3777 41.0297 15.0553V15.0553Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_349_32">
            <rect width="50" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const WhatsApp = () => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_349_38)">
          <path
            d="M25.0063 0H24.9938C11.2094 0 3.28029e-05 11.2125 3.28029e-05 25C-0.00852688 30.2657 1.65831 35.3975 4.75941 39.6531L1.64378 48.9406L11.2532 45.8688C15.3293 48.573 20.1146 50.0105 25.0063 50C38.7907 50 50 38.7844 50 25C50 11.2156 38.7907 0 25.0063 0V0Z"
            fill="#4CAF50"
          />
          <path
            d="M39.5531 35.3031C38.95 37.0062 36.5562 38.4187 34.6468 38.8312C33.3406 39.1093 31.6343 39.3312 25.8906 36.95C18.5437 33.9062 13.8125 26.4406 13.4437 25.9562C13.0906 25.4718 10.475 22.0031 10.475 18.4156C10.475 14.8281 12.2969 13.0812 13.0312 12.3312C13.6344 11.7156 14.6312 11.4343 15.5875 11.4343C15.8969 11.4343 16.175 11.45 16.425 11.4625C17.1594 11.4937 17.5281 11.5375 18.0125 12.6968C18.6156 14.15 20.0843 17.7375 20.2593 18.1062C20.4375 18.475 20.6156 18.975 20.3656 19.4593C20.1312 19.9593 19.925 20.1812 19.5562 20.6062C19.1875 21.0312 18.8375 21.3562 18.4687 21.8125C18.1312 22.2093 17.75 22.6343 18.175 23.3687C18.6 24.0875 20.0687 26.4843 22.2312 28.4093C25.0219 30.8937 27.2843 31.6875 28.0937 32.025C28.6968 32.275 29.4156 32.2156 29.8562 31.7468C30.4156 31.1437 31.1062 30.1437 31.8093 29.1593C32.3093 28.4531 32.9406 28.3656 33.6031 28.6156C34.2781 28.85 37.85 30.6156 38.5844 30.9812C39.3187 31.35 39.8031 31.525 39.9812 31.8343C40.1562 32.1437 40.1562 33.5968 39.5531 35.3031V35.3031Z"
            fill="#FAFAFA"
          />
        </g>
        <defs>
          <clipPath id="clip0_349_38">
            <rect width="50" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
