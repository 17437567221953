import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import CreatedQuestion from "./Components/CreatedQuestion";
import QuestionBuilderForm from "./Components/QuestBuilderForm";
import { AllFunction } from "./Functions";
// import { cleanedQuestionsArray } from "./Functions";
import QuestionType from "./Components/QuestTypeDropdown";
import { Empty, Delete, Edit } from "../../assets/svg/createSurvey";
// import Preview from "../Preview";
import Preview from "./Components/PreviewModal";
import { Button, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { get, post, update } from "../../Utils/Function/Method/index";
import { AuthContext } from "../../contexts/AuthContext";
import DeleteBtn from "./Components/DeleteBtn";


const SurveyPage = ({ surveyInfo, editMode }) => {
  // console.log(surveyInfo, "surrrrrrrrrrrr")
  // console.log(typeof(JSON.parse(surveyInfo.question_set)), "type of question_set")
  // console.log(surveyInfo?.question_set, "rrrrrrrrrrrrrrr")
  const [updatingQuestionId,setUpdatingQuestionId]=useState<number>()
  const [isEditingQuestion,setIsEditingQuestion]=useState(false)
  const {
    handleChoiceChange,
    handleQuestionChange,
    addChoice,
    removeChoice,
    addQuestion,
    handleEditQuestion,
    EditQuestionButton,
    handleDeleteQuestion,
    handleSkipChoiceChange,
    handleSkipToChange,
    addSkipLogic,
    handleDeleteSkipLogic,
    SaveQuestionButton,
    questions,
    currentQuestion,
    questionIndex,
    setQuestions,
    setCurrentQuestion,
    setQuestionIndex,
    removeEmptyObj,
    addId,
    handleAddField,
  } = AllFunction({ surveyInfo, editMode ,setUpdatingQuestionId,setIsEditingQuestion});

  const [initialLoadingComplete, setInitialLoadingComplete] = useState(false);
  // const obj = JSON.stringify(questions);
  const cleanedQuestionsArray =questions && questions.length>0 ? questions.map((question) => {
    console.log("Cleaned questions array console here",questions)
    let cleanedQuestion = { ...question };
   if(cleanedQuestion && cleanedQuestion.skipLogic && Array.isArray(cleanedQuestion.skipLogic) ){
     cleanedQuestion.skipLogic = cleanedQuestion.skipLogic.filter(
       (skipLogic) => skipLogic.choice !== "" && skipLogic.skipTo !== ""
       );
      }
      console.log("Cleaned question console",Object.entries(cleanedQuestion)
      .filter(([key, value]) => {
        console.log(key,value)
        if (typeof value === "string") {
          console.log("Returning string")
       return value !== "";
     } else if (Array.isArray(value)) {
      console.log("Returning Array")
       return value.length !== 0;
     } else {
      console.log("Returning else")
       return true;
     }
    })
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}))
    return Object.entries(cleanedQuestion)
    .filter(([key, value]) => {
      if (typeof value === "string") {
        return value !== "";
      } else if (Array.isArray(value)) {
        return value.length !== 0;
      } else {
        return true;
      }
    })
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
  }) : []
  console.log("Cleaned questions array",cleanedQuestionsArray)
  const surveyId = parseInt(surveyInfo?.survey_id);
  const questionSet = JSON.parse(surveyInfo?.question_set);
  
  const updateSurvey = async () => {
    // const cleanedQuestions = cleanedQuestionsArray(questions);
    console.log("New Array value before map function",cleanedQuestionsArray)
      let newArray=JSON.parse(JSON.stringify(cleanedQuestionsArray));
      newArray=newArray?.map((singleArrayValue,index)=>{
      console.log("SingleArrayValue",singleArrayValue)
      let newValue={...singleArrayValue}
      console.log("Id value",newValue["id"])
      if(!newValue["id"])newValue["id"]=index
      console.log(newValue["id"]?"Has Id":"Don't have Id",newValue["id"])
      return newValue
    })
    console.log("New Array value before patch function",newArray)
    let temp = JSON.stringify(newArray);
    const dataSet = { survey_id: surveyId, question_set: temp };
    console.log("Temp value from the updated survey",questions,temp)
    // console.log(dataSet, "dataSet")

    await update(isEditingQuestion ? `/question_set?update_q_id=${updatingQuestionId}`:`/question_set`, dataSet)
      .then(([sts, res]) => {
        if (sts == 200) {
          // alert("Survey updated");
          setIsEditingQuestion(false)
          
        } else {
          // alert(" Survey wents wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const updateSurveyDebounced = debounce(updateSurvey, 500); // Debounce updateSurvey with a delay of 500ms

  useEffect(() => {
    if (!initialLoadingComplete) {
      setInitialLoadingComplete(true);
      // Skip calling updateSurvey() during initial loading
      return;
    }

    // Call updateSurveyDebounced after the completion of addQuestion
    updateSurvey();

    // console.log(questions);
  }, [questions]);
  // const [questions, setQuestions] = useState([]);

  const { surveyNum } = useContext(AuthContext);
  const [surveysInfo, setSurveysInfo] = useState(null);
  const surveyInt = parseInt(surveyNum);

  async function getData() {
    try {
      // console.log("Survey number in intereger",surveyInt)
      const response = await get(`/survey_info/${surveyInt}`);

      // console.log("Response surveyInfo", response[1][0]);

      // setQuestions(response[1][0]);
      // console.log(
      //   "Response surveyInfo parsed one",
      //   JSON?.parse(response[1][0]?.question_set)
      // );
      const questionSet=response[1][0]?.question_set
      setQuestions(JSON?.parse(questionSet));
      // console.log("sds",setQuestions);
      // console.log(questions);
    } catch (error) {
      console.log("FetchError", error);
    }
  }

  // console.log("ggggg", surveysInfo)

  useEffect(() => {
    if (editMode) {
      getData();
    }
  }, [surveyNum, editMode]);
  const currentDraggingRef=useRef<string>()
  const currentDragNode=useRef<any>()
  const onDragEvent=(event:React.DragEvent,question:any,index:number)=>{
    console.log("Drag event is happening",question,index)
    currentDraggingRef.current=question
    console.log(currentDraggingRef.current)
    currentDragNode.current=event.target
    currentDragNode.current.addEventListener("dragend",onDragEnd)
  }
  const onDragEnter=(event:React.DragEvent,question:any)=>{
    if(currentDraggingRef.current){
      const newList=[...questions]
    const enteredCard=question
    const draggingIndex=newList.indexOf(currentDraggingRef.current)
    const hoveringIndex=newList.indexOf(enteredCard)
    console.log("Draggging index",draggingIndex,"Hovering index",hoveringIndex)
    newList[draggingIndex]=enteredCard
    newList[hoveringIndex]=currentDraggingRef.current
    setQuestions(newList)
    }
  }
  const onDragEnd=()=>{
    // setDraggable(false)
    currentDragNode.current.removeEventListener("dragend",onDragEnd)
    currentDragNode.current=undefined;
    currentDraggingRef.current=undefined
    // alert("Please make sure you alter skip and logic also if needed")
  }

  return (
    <>
      <div className="survey-main-container">
        {/* <div className="ques-access">
          Access Questions
          {questions && questions.length > 0 ? (
            questions?.map((question, index) => (
              <>
                <a draggable onDragStart={(event: React.DragEvent<HTMLAnchorElement>)=>onDragEvent(event,question,index)} onDragOver={(event: React.DragEvent<HTMLAnchorElement>)=>{
        event.preventDefault()
      }} onDragEnter={(event: React.DragEvent<HTMLAnchorElement>)=>onDragEnter(event,question)} href={`#question${index + 1}`} className="quest-access-btn">
                  <span className="quest-title-prev">
                    {index + 1}. {question.text}
                  </span>
                  <span className="del-ed-btn">
                    <button
                      title="Edit"
                      onClick={() => handleEditQuestion(index)}
                    >
                      <Edit />
                    </button>
                    <button
                      title="Delete"
                      onClick={() => handleDeleteQuestion(index)}
                    >
                      <Delete />
                    </button>
                    {/* <DeleteBtn onDelete= {handleDeleteQuestion(index)}/> */}
                  {/* </span>
                </a>
              </>
            ))
          ) : (
            <div className="empty-container">
              <p>No Questions Created</p>
            </div>
          )}
          {}
        // </div> */} 
        <div className="survey-left-container">
          {/* <h4>Add Question Here</h4> */}
          <form className="quest-builder-form">
            <div className="row-container align-right">
              {/* <label htmlFor="required">Required: </label>
              
               */}
              <label className="switch">
                <input
                  type="checkbox"
                  id="required"
                  name="required"
                  checked={currentQuestion.required}
                  onChange={(event) =>
                    setCurrentQuestion({
                      ...currentQuestion,
                      required: event.target.checked,
                    })
                  }
                />
                <span className="slider"></span>
              </label>
            </div>
            <div className="field-container">
              <label htmlFor="text">Question Title:</label>
              <textarea
                className="ipstyle"
                name="text"
                value={currentQuestion.text}
                onChange={handleQuestionChange}
                placeholder="Type your Question"
              ></textarea>
            </div>
            <div className="field-container">
              <label htmlFor="type">Question Type:</label>
              <QuestionType
                currentQuestion={currentQuestion}
                questions={questions}
                handleQuestionChange={handleQuestionChange}
                handleChoiceChange={handleChoiceChange}
                removeChoice={removeChoice}
                addChoice={addChoice}
                handleSkipChoiceChange={handleSkipChoiceChange}
                handleSkipToChange={handleSkipToChange}
                handleDeleteSkipLogic={handleDeleteSkipLogic}
                addSkipLogic={addSkipLogic}
              />
            </div>
          </form>
          <div className="sticky-bot-container">
            {questionIndex !== -1 && <SaveQuestionButton />}
            {questionIndex == -1 && (
              <button
                className="quest-builder-btns"
                type="button"
                // onClick={async (e) => {
                //   // insertworkspace(e);
                // }}
                onClick={(e) => {
                  setIsEditingQuestion(false)
                  addQuestion(e);
                  // updateSurvey()
                }}
              >
                Add Question
              </button>
            )}
          </div>
        </div>

        <div className="survey-right-container">
          {/* <h2>Questions:</h2>
           */}
          <>
            {questions && questions.length > 0 ? (
              questions?.map((question: any, index: any) => (
                <CreatedQuestion
                  key={index}
                  question={question}
                  index={index}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleEditQuestion={handleEditQuestion}
                  questionIndex={questionIndex}
                />
              ))
            ) : (
              <div className="empty-container">
                <Empty />
                <p>No Questions Created</p>
              </div>
            )}
            {}
          </>
          {JSON?.stringify(addId(questions))}
        </div>
        {/* <div className="prev-modal">
        <Preview 
    questions={(cleanedQuestionsArray)}
    />
    </div> */}
      </div>
    </>
  );
};
export default SurveyPage;
