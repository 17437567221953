import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";

interface ContextProps {
  isAuthenticated?: string;
  setIsAuthenticated?: any;
  setSurveyNum?: any;
  surveyNum?: any;
  signOut?: (e: any) => void;
  email?: string;
  userAttr?: any;
  idToken?: any;
  setIdToken?: any;
  accessToken?: any;
  setAccessToken?: (e: any) => void;
  refreshToken?: any;
  setRefreshToken?: any;
  userName: string;
  setUserName?: (e: string) => void;
  signIn?: (username: string, password: string) => Promise<void|any>;
}

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

interface childrenProps {
  children: any;
}

export const AuthContext = createContext({} as ContextProps);

const AuthContextProvider = ({ children }: childrenProps) => {
  const [userAttr, setUserAttr] = useState<any>("");
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [isAuthenticated, setIsAuthenticated] = useState<any>(false);
  const [idToken, setIdToken] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<any>(null);
  const [refreshToken, setRefreshToken] = useState<any>(null);
  const [surveyNum, setSurveyNum] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // console.log("fetchUser"); *
        const session = await Auth.currentSession(); //returns tokens
        setIdToken(session?.getIdToken());
        setAccessToken(session?.getRefreshToken());
        setRefreshToken(session?.getAccessToken());
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        // console.log("Authenticated user", authenticatedUser); *
        setUserAttr(authenticatedUser?.attributes);
        // console.log("User Attributes", userAttr);                  *
        setUserName(authenticatedUser?.attributes["email"]);
        console.log("User", userName);
        setIsAuthenticated(true);
        // console.log("TESTTT", authenticatedUser?.attributes["email"]);
        setAuthStatus(AuthStatus.SignedIn);
        // console.log("Auth status : ", authStatus);
        // console.log("Authenticated User from fetch user : ", authenticatedUser);
        // console.log("Authenticated User : ", userAttr);
        // setIsAuthenticated(true);
        return authenticatedUser;
      } catch (error) {
        console.log("Fetch User:", error);
      }
    };
    fetchUser();
  }, [setAuthStatus, authStatus]);

  // if (authStatus === AuthStatus.Loading) {
  //   return null;
  // }

  // const signUp = async (e: any) => {
  //   e.preventDefault();
  //   await Auth.signUp({
  //     username: email,
  //     password: password,
  //     attributes: {
  //       "custom:firstname": userFirstName,
  //       "custom:lastname": userLastName,
  //     },
  //   })
  //     .then((result): any => {
  //       setWaitingForCode(true);
  //       setIsAuthenticated(false);
  //       setPassword("");
  //     })
  //     .catch((error) => {
  //       setError(error);
  //       console.log("error signing up:", error);
  //     });
  // };

  const signIn = async (username: string, password: string) => {
    console.log("sign in");

    try {
      const result = await Auth.signIn({
        username,
        password,
      });
      console.log("RESULT OF SIGN IN", result);
      console.log(result);
      setUser(result);
      setUserAttr(result?.attributes);
      setUserName(result?.attributes["email"]);
      setIsAuthenticated(true);
      console.log("is authenticated sign in > ", isAuthenticated);
    } catch (error) {
      // setError(err);
      // if(err.code === 'UserNotConfirmedException'){}
      console.log("is authenticated sign in error > ", isAuthenticated);
      setIsAuthenticated(false);
      return error;
    }
  };

  const signOut = () => {
    Auth.signOut({ global: true });
    setIsAuthenticated(false);
    console.log("sign out");
    console.log("is authenticated sign out > ", isAuthenticated);
  };

  // const forgotPassword = async (e: any) => {
  //   e.preventDefault();
  //   await Auth.forgotPassword(email)
  //     .then((data) => console.log(data))
  //     .catch((err) => console.log(err));
  // };

  // const forgotPasswordSubmit = async (e: any) => {
  //   e.preventDefault();
  //   await Auth.forgotPasswordSubmit(email, code, newPassword)
  //     .then((data) => console.log(data))
  //     .catch((err) => console.log(err));
  // };

  // const signUp = (e: any) => {
  //   e.preventDefault();
  //   Auth.signUp({ username: email, password, attributes: { email } })
  //     .then((data) => {
  //       console.log(data);
  //       setWaitingForCode(true);
  //       setPassword("");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const confirmSignUp = (e: any) => {
  //   e.preventDefault();
  //   Auth.confirmSignUp(email, code)
  //     .then((data) => {
  //       console.log(data);
  //       setWaitingForCode(false);
  //       setCode(code);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const resendCode = () => {
  //   Auth.resendSignUp(email)
  //     .then(() => {
  //       console.log("code resent successfully");
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userAttr,
        signOut,
        userName,
        setUserName,
        idToken,
        setIdToken,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        surveyNum,
        setSurveyNum,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
