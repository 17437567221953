import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Dashboard/Home";
import Layout from "../pages/Layout";
import Login from "../pages/Login";
// import Build from "../pages/Build";
import Share from "../pages/Share";
import Report from "../pages/Report/components/ResultTabs";
// import Eyeicon from "../pages/Eyeicon";
import Publish from "../pages/Publish";
import PlansandPricing from "../pages/Plans and Pricing";
import ProtectedRouter from "./ProtectedRouter";

// import ProtectedRouter from "./protectedRouter";
import Dashboard from "../pages/Dashboard";
import SignUp from "../pages/SignUp";
import ConfirmSignUp from "../pages/SignUp/confirmSignUp";
import ForgotPassword from "../pages/ForgotPassword/forgotPassword";
import ResetPassword from "../pages/ForgotPassword";
import ErrorPage from "../pages/ErrorPage";
// import MySurveys from "../pages/MySurveys";
import Reports from "../pages/Report/components/ResultTabs";
import SocicalShare from "../pages/Share/SocialMedia";

import CreateSurvey from "../pages/CreateSurvey";
import ShareSocial from "../pages/Share/SocialMedia";
// import result from '../pages/Report/components/ResultsSubHeader';
import ReportResult from "../pages/Report/ReportResult";
import ReportResponse from "../pages/Report/ReportResponse";
import Preview from "../pages/Preview"
import Dummy from "../pages/dummy";
import Test from "../pages/Test";
import LiveSurvey from "../pages/LiveSurvey";
import LandingPage from "../pages/LandingPage";
import About from "../pages/LandingPage/Component/AboutUs";
import Contact from "../pages/LandingPage/Component/ContactUS";
import TermsandConditions from "../pages/LandingPage/Component/TermsandConditions";
import PrivacyPolicy from "../pages/LandingPage/Component/PrivacyPolicy"
import Plans from "../pages/LandingPage/Component/PlansandPricing"


const Index = () => {
  
  return (
    <BrowserRouter>
      <Routes>
      <Route index element={<LandingPage />} />
     {/* <Route path="LandingPage" element={<LandingPage />} /> */}
     <Route path="about" element={<About />} />
     <Route path="contact" element={<Contact />} />
     <Route path="TermsandConditions" element={< TermsandConditions/>} />
     <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
     <Route path="Plans" element={<Plans />} />
        <Route path="/log-in" element={<Login />} />
        {/* <Route path ="/131" element={<LiveSurveyDemo/>}/> */}
        <Route path="/:survey_id" element={<LiveSurvey/>} />
        <Route path="/Preview/:surveyId" element={<Preview />}/>
        <Route path="/" element={<ProtectedRouter />}>
          <Route path="/" element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route index element={<Home />} />
            </Route>
            {/* <Route path="home" element={<Home />} /> */}
          

            {/* <Route path ="/:survey_id" element={<LiveSurvey/>}/> */}
            {/* <Route path="/my-surveys" element={<MySurveys />} /> */}
            <Route path="/create-survey/:surveyId" element={<CreateSurvey />} />
            <Route
              path="/edit-survey/:surveyId"
              element={<CreateSurvey editMode={true} />}
            />
            <Route path="/share/:surveyId" element={<Share />} />
           
            <Route path="/PlansandPricing" element={<PlansandPricing />} />
           
            <Route path="/reports-and-responses/:surveyId" element={<Reports />} />
          
          </Route>{" "}
        </Route>

        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/confirm-signup" element={<ConfirmSignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route path="/build" element={<Build/>} /> */}{" "}
        {/* <Route path="/share" element={<Share/>} /> */}
      
        {/* <Route path="/report" element={<Report />} /> */}
        {/* <Route path="/eyes" element={<Eyeicon />} /> */}
        <Route path="/publish" element={<Publish />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="dummy" element={<Dummy />} />{" "}
        <Route path="Test" element={<Test />} />
      </Routes>{" "}
    </BrowserRouter>
  );
};

export default Index;
