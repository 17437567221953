import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../CreateSurvey/components/Header";
import "./style.css";
import Tabs from "../components/ResultTabs";
import Graph from "../../Report/components/Graph";
import Table from "../components/GraphTable";
import Data from "../data.json";
import Logo from "../../../assets/rivazia.png";
import AccessModal from "../components/QuAcssModal"
import {get} from "../../../Utils/Function/Method/index"
import ErrorIcon from "../../../assets/Nofile.png"
import {AuthContext} from "../../../contexts/AuthContext";
import { useParams } from 'react-router-dom';
// import html2pdf from 'html2pdf.js';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Csv from "../components/Graph/components/ExportCsv"
import { Box, Icon, Pagination, CircularProgress } from '@mui/material';

export default function Index() {
  const navigate = useNavigate();
  const { surveyId } = useParams();
  // const {surveyNum}=useContext(AuthContext)
  const [surveyReport, setSurveyReport] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const surveyInt = parseInt(surveyId);
  const pdfRef = useRef();
  // console.log("dfg",surveyReport)
  async function fetchData(){
      setIsLoading(true);
    try{
      const response=await get(`/report_info/${surveyInt}`);
        // console.log("response>>>>", response[1]);                
      setSurveyReport(response[1]);
    } catch (error) {
      console.log("fetch error ", error);
    } finally {
      setIsLoading(false);
    }
    }
    useEffect(() => {
      fetchData();
      return ()=>setSurveyReport(undefined)
    }, []);

    
    const handleDownloadPDF = async () => {
      const element = pdfRef.current;
      const options = {
        scrollY: -window.scrollY,
      };
  
      try {
        // Wait for the content to be fully rendered
        await new Promise((resolve) => setTimeout(resolve, 500));
  
        // Capture the screenshot of the content
        const canvas = await html2canvas(element, options);
        const imgData = canvas.toDataURL("image/png");
        const desiredHeight = 600; // Adjust this value as needed
        const heightScalingFactor = canvas.height;
        const widthScalingFactor = 4.8; // Adjust this value to increase or decrease the width
// const heightScalingFactor = 2.8; // Adjust this value to increase or decrease the height

        // Create PDF and add the captured image
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imageWidth = canvas.width* heightScalingFactor;
        const imageHeight = canvas.height * heightScalingFactor;
        const ratio=Math.min(pdfWidth/imageWidth,pdfHeight/imageHeight)
        const imgX=(pdfWidth -imageWidth* ratio)/2;
        const imgY=0;
        pdf.addImage(imgData, "PNG",imgX,imgY, imageWidth*ratio, imageHeight* ratio);
  
        
        pdf.save("survey_report.pdf");
      } catch (error) {
        console.log("Error generating PDF: ", error);
      }
    };
    
  return (
    <>
      {isLoading ? (
      <div>Loading...</div>
    ) : (

    
   
      // <>
      <div ref={pdfRef}>
        <div className="download-options">
              <div className='csvdwld-link' >
       <button>

       <Csv/>
       </button>
     </div>
     <div className="pdf-download-link">
              {surveyReport ? (
                <button onClick={handleDownloadPDF}>Download PDF</button>
              ) : (
                <button>No data available</button>
              )}
            </div>
         {/* <div className="pdf-download-link">
                <button onClick={handleDownloadPDF }>Download PDF</button>
              </div> */}
          </div>


      <div className="rslt-main-contianer">
    
        <div className="rslt-right-container">
        <AccessModal data={surveyReport}/>
         
         {surveyReport?.length>0 && Array.isArray(surveyReport)?(
            surveyReport?.map((data,idx)=>(
              <div className="result-quest-container" id={`res-ques${idx}`}>
                <div className="result-header glass">
                  <div className="rslt-hdr-row">
                    <span>Question {data?.q_id} </span>
                    <span>| {data?.q_type}</span>
                  </div>
                  <div className="rslt-hdr-row q-title">{data?.q_title}</div>
                
                </div>
                <div className="rslt-row-spliter">
                  <Graph surveyReport={surveyReport}  q_id={data.q_id} />
                </div>
                <div className="rslt-row-spliter">
                  {/* <details> */}
                    {/* <summary>View in Table</summary> */}
                    <Table surveyReport={surveyReport}  q_id={data.q_id} />
                  {/* </details> */}
                </div>
                <div className="page-footer">
                  <div className="Report-page">
                  <div className='page-content'>Page</div>
                  <div className="pagination">
                    {idx + 1 + "/" + surveyReport.length}
                  </div>
                  </div>
                
                 
                </div>
              </div>
            ))
          ):(
            <div className="report-no-data">
              <div className="report-no-data-image">

              <img src={ErrorIcon} alt="filenotfound"/>
              </div>
              <div className="report-no-data-header">

              <p> You haven't created any questions.</p>
              <p>Go Back to start buillding your survey.</p>
              </div>
              <div className="report-no-data-button">
              </div>            
              </div>
          )
          }

        </div>
      </div>
      </div>
      )}
     
  </>
    
  );
}
