// import { apiEndPoint } from "../../Constants"; 
import { apiEndPoint } from "../../constants";
 

export const post = async (path: any, data: any) => { 

 const res = await fetch(`${apiEndPoint}${path}`, { 

  method: "POST", 

  mode: "cors", 

 

  body: JSON.stringify(data), 

 }) 

  .then(async (res) => { 

   // console.log("res insdsada", res); 

   const json = await res.json(); 

   return [res?.status, json]; 

  }) 

  .then((res) => { 

   return res; 

  }) 

  .catch((error) => { 

   console.log(`error in post api ${path} >`, error); 

   return [550, error]; 

  }); 

 return res; 

}; 

 

export const get = async (apiUri: any) => { 

 const res = await fetch(`${apiEndPoint}${apiUri}`, { 

  method: "GET", 

  mode: "cors", 

 }) 

  .then(async (res) => { 

   // console.log(t) 

   const json = await res.json(); 

   return [res?.status, json]; 

  }) 

  .then((res) => { 

   return res; 

  }) 

  .catch((error) => console.log(`error in api ${apiUri} >`, error)); 

 return res; 

}; 

 

export const update = async (path: any, data: any) => { 

 const res = await fetch(`${apiEndPoint}${path}`, { 

  method: "PATCH", 

  mode: "cors", 

 

  body: JSON.stringify(data), 

 }) 

  .then(async (res) => { 

   // console.log("res insdsada", res); 

   const json = await res.json(); 

   return [res?.status, json]; 

  }) 

  .then((res) => { 

   return res; 

  }) 

  .catch((error) => { 

   console.log(`error in patch api ${path} >`, error); 

   return [550, error]; 

  }); 

 return res; 

}; 

 

// export const delete = async (apiUri, token) => { 

export const deletes = async (apiUri: any) => { 

 const res = await fetch(`${apiEndPoint}${apiUri}`, { 

  method: "DELETE", 

  mode: "cors", 

 }) 

  .then(async (res) => { 

   const json = await res.json(); 

   return [res?.status, json]; 

  }) 

  .then((res) => { 

   return res; 

  }) 

  .catch((error) => { 

   console.log(`error in delete ${apiUri} >`, error); 

   return [500, error]; 

  }); 

 return res; 

}; 

 

export const uploadFiles = async (file, userData) => { 

 const contentType = userData?.["content_type"]; 

 await fetch(`${apiEndPoint}/uploads`, { 

  method: "POST", 

  mode: "cors", 

  body: JSON.stringify({ 

   ...userData, 

  }), 

 }) 

  .then((res) => res.json()) 

  .then((response) => { 

   const bucketUrl = response.uploadURL; 

   // console.log("upload response > ", response); 

   const headers = new Headers(); 

   fetch(bucketUrl, { 

    method: "PUT", 

    body: file, 

    headers: { 

     ...headers, 

     "Content-Type": contentType, 

    }, 

   }) 

    .then((res) => { 

     if (res.status === 200) { 

      // console.log("form submitted successfully"); 

     } else { 

      alert("Failed"); 

      console.log("error while uploading file"); 

     } 

     console.log(`success ${res.status} - ${res.statusText}`); 

    }) 

    .catch((err) => { 

     console.log(err); 

     console.log(`error on bucket url ${err.status} - ${err.statusText}`); 

    }); 

  }) 

  .catch((err) => console.log("error while fetching api endpoint", err)); 

}; 

 

// export const uploadImage = async (newFileName, image) => { 

//  const getFileExtension = (fileName) => { 

//     const nameList = fileName.split("."); 

//     return nameList[nameList?.length - 1]; 

//   }; 

//   const fileName = `${newFileName}.${getFileExtension(image?.name)}`; 

//   const contentType = image?.type; 

//   await fetch(`${apiEndPoint}/imageupload`, { 

//     method: "POST", 

//     mode: "cors", 

//     headers: new Headers(), 

//     body: JSON.stringify({ 

//       fileName: fileName, 

//       contentType: contentType, 

//     }), 

//   }) 

//     .then((res) => res.json()) 

//     .then((response) => { 

//       const bucketUrl = response.url; 

//       const headers = new Headers(); 

//       fetch(bucketUrl, { 

//         method: "PUT", 

//         body: image, 

//         headers: { 

//           ...headers, 

//           "Content-Type": contentType, 

//         }, 

//       }) 

//         .then((res) => { 

//           if (res.status === 200) { 

//             console.log( 

//               `image upload success ${res.status} - ${res.statusText}` 

//             ); 

//           } else { 

//             alert("Failed"); 

//             console.log( 

//               `error while uploading file ${res.status} - ${res.statusText}` 

//             ); 

//           } 

//         }) 

//         .catch((err) => { 

//           console.log(err); 

//           console.log(`error on bucket url ${err.status} - ${err.statusText}`); 

//         }); 

//     }) 

//     .catch((err) => console.log(err)); 

// }; 

 

 