import React,{useState,useContext, useEffect} from "react";
import { QuestionType, scrollToTop } from "../../Functions";
import {AuthContext} from "../../../../contexts/AuthContext";
import { get } from "../../../../Utils/Function/Method";
import { useParams } from 'react-router-dom';
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { Close } from '@mui/icons-material';
interface QuestionProps {
  question: QuestionType;
  index: number;
  handleDeleteQuestion: (index: number) => void;
  handleEditQuestion: (index: number) => void;
  questionIndex: any;
}



const Question: React.FC<QuestionProps> = ({
  question,
  index,
  handleDeleteQuestion,
  handleEditQuestion,
  questionIndex,
}) => {
  const { surveyId } = useParams();
  const surveyInt = parseInt(surveyId);
  // const {surveyNum }=useContext(AuthContext)
  // const[surveysInfo,setSurveysInfo]=useState(null)
  // // const surveyInt = parseInt(surveyNum);
  // useEffect(() => {
  //   async function getData() {
  //     try {
  //       const response = await get(`/survey_info/${surveyInt}`);
  //       // console.log("Response surveyInfo", response[1][0]);
  //       setSurveysInfo(response[1][0]);
  //     } catch (error) {
  //       console.log("FetchError", error);
  //     }
  //   }

  //   if (surveyId && surveysInfo === null) {
  //     getData();
  //   }
  // }, [surveyId, surveysInfo]);
  // console.log("ttttt", surveysInfo);

  const MAX_LENGTH = 20; 
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    handleDeleteQuestion(index)
    // onDelete(index);
    setOpen(false); // Close the modal after deletion
  };
  
  return (

    <>
    {/* #################Edited Question################################# */}
        
     {question ?  <div key={index} id={`question${index + 1}`} className="quest-container">
      {/* <div>{index + 1}</div> */}
      <div className="quest-title overflow">
        {index + 1}. &nbsp;
        {/* {surveysInfo?.survey_id} */}
        {/* {question.text} */}
        {question && question.text && question.text.length > 25 ? `${question.text.substring(0, 25)}...` : question.text}
      </div>
      <div className="row-text">
      <span> {question.type}</span>
      </div>
      {/* <div className="row-text">
        Skip: <span>{question.skipQuestion.toString()}</span>
      </div> */}
      <div className="row-text">
        Required: <span>{question.required.toString()}</span>
      </div>

      {question.type === "multipleChoice" && (
        <ul>
          {question && question.choices && Array.isArray(question.choices) && question.choices.length>0 && question.choices.map((choice, choiceIndex) => (
            // <li key={choiceIndex}>{choice}</li>
            <div>
              <input name="choice" type="radio" disabled />
              <label htmlFor="choice">{choice}</label>
            </div>
          ))}
        </ul>
      )}
      {question.type === "TextInput" && (
        <div>
             <input type="text" disabled maxLength={MAX_LENGTH} />
        </div>
      )}

      {question.type === "LongText" && (
        <div>
          <textarea  disabled />
        </div>
      )}

      {question.type === "Rating" && (
        <div>
          <input type="number" min="1" max="10" disabled />
        </div>
      )}

      {question.type === "boolean" && (
        <div>
          <input name="YesNo" type="radio" value="Yes" disabled />
          <label htmlFor="YesNo">Yes</label>
          <input name="YesNo" type="radio" value="No" disabled />
          <label htmlFor="YesNo">No</label>
        </div>
      )}

      {question.type === "nps" && (
        <div>
          {/* <input type="number" min="0" max="10" disabled /> */}
          {Array.from({ length: Number(question.max)-Number(question.min)+1 },(v:number,k:number)=>k+Number(question.min)).map((number) => (
            <button key={number}>{number}</button>
          ))}
        </div>
      )}

      {question.type === "email" && (
        <div>
          <input placeholder="Enter Email" type="email" disabled />
        </div>
      )}

      {question.type === "phone" && (
        <div>
          <input placeholder="Enter Phone" type="tel" disabled />
        </div>
      )}

      {question.type === "upload" && (
        <div>
          <input type="file" disabled />
        </div>
      )}

      {question.type === "dropdown" && (
        <div>
          <select>
            {question && question.choices && Array.isArray(question.choices) && question.choices.length>0 && question.choices.map((choice, choiceIndex) => (
              <option key={choiceIndex} value={choice}>
                {choice}
              </option>
            ))}
          </select>
        </div>
      )}
         {question.type === "Radio Button" && (
        <ul>
          {question && question.choices && Array.isArray(question.choices) && question.choices.length>0 && question.choices.map((choice, choiceIndex) => (
            // <li key={choiceIndex}>{choice}</li>
            <div>
              <input name="choice" type="radio" disabled />
              <label htmlFor="choice">{choice}</label>
            </div>
          ))}
        </ul>
      )}
      {question.type === "checkbox" && (
  <div>
    {question && question.choices && Array.isArray(question.choices) && question.choices.length>0 && question.choices.map((choice, choiceIndex) => (
     <div>
     <input name="choice" type="checkbox" disabled />
     <label htmlFor="choice">{choice}</label>
   </div>
    ))}
  </div>
)}



      {/* {question.type === "matrix" && (
        <table>
          <thead>
            <tr>
              <th></th>
              {question.columns.map((column, columnIndex) => (
                <th key={columnIndex}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {question.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row}</td>
                {question.columns.map((_, columnIndex) => (
                  <td key={columnIndex}>
                    <input name={rowIndex} type="radio" disabled />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )} */}

      {/* {questionIndex} */}
      {question.skipLogic && Array.isArray(question.skipLogic) && question.skipLogic.length > 0 ? (
        <>
        {question.skipLogic?.map((item, index) => (
      <div key={index}>
        <p>If Choice: {item.choice} then Skip To: {item.skipTo}</p>
      </div>
    ))}
        </>
      ):(" ")}   
       {/* //No Logics Added */}
      
      <div className="btn-container">
      
      <button onClick={handleOpen}>Delete</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#001D3D',
            boxShadow: 24,
            p: 4,
            maxWidth: 700,
            width: '100%',
            textAlign: 'center',
            borderRadius: 8,
            height: 200, 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
           <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
          <Typography id="delete-confirmation-modal" variant="h6" component="h2" gutterBottom sx={{ color: 'white',marginTop:'20px' }}>
          If you delete the question, the responses for this question and any related skip logic conditions will also be eliminated! Are you sure?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button
  variant="contained"
  color="primary"
  onClick={handleDelete}
  sx={{
    borderRadius: '10px', // Set border radius to 8px
    border: '2px solid blue', // Set border color to white
    color: 'white', // Set text color to white
    marginTop:'10px',
    marginRight: '20px', 
    '&:hover': {
      borderColor: 'white', // Change border color to white on hover
    },
  }}
>
  OK
</Button>
<Button
  variant="contained"
  color="primary"
  onClick={handleClose}
  sx={{
    borderRadius: '10px', // Set border radius to 8px
    border: '2px solid blue', // Set border color to white
    color: 'white', // Set text color to white
    marginTop:'10px',
    marginLeft: '20px',
    '&:hover': {
      borderColor: 'white', // Change border color to white on hover
    },
  }}
>
  Cancel
</Button>

</Box>

        </Box>
      </Modal>
    
        {/* <button onClick={() => handleDeleteQuestion(index)}>Delete</button> */}
        
        <button onClick={() => {handleEditQuestion(index); scrollToTop();}}>Edit</button>
      </div>
    </div> : <span>Loading.........</span>}
     
    


  
    </>
  );
};
export default Question;



