
import React, { useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { get } from '../../../../../Utils/Function/Method/index';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';

export default function ExportCsv() {
  const { surveyId } = useParams();
  const surveyInt = parseInt(surveyId);
  const [surveyResponse, setSurveyResponse] = useState([]);
 

 const [excelData, setExcelData] = useState('');
 const fetchData = async () => {
  try {
    const response = await get(`/response_info/${surveyInt}`);
    // console.log("--ecx", response[1][0]);                          *
    const surveyData = response[1][0];

    // Extract question titles
    const questionTitles = surveyData.map((item) => item.q_title);

    // Prepare the rows for Excel-like output
    const formattedRows = [];

    // Find the maximum number of answers for any question
    const maxAnswers = Math.max(...surveyData.map((item) => item.answers.length));

    for (let i = 0; i < maxAnswers; i++) {
      const row = [];
      for (let j = 0; j < surveyData.length; j++) {
        const answers = surveyData[j].answers;
        const answer = answers[i] && answers[i].q_answer !== null ? answers[i].q_answer : 'null';
        // console.log("Answers",answers,answers[i])        *
        // const answer = answers[i] ? answers[i].q_answer : '';
        // console.log(answer[i])                        *
        row.push(answer);
      }
      formattedRows.push(row);
    }
    // console.log(formattedRows)             *

    // Combine the rows into a single string with line breaks and tabs
    const excelData = [questionTitles.join("\t")].concat(formattedRows.map((row) => row.join("\t"))).join("\n");
    // console.log(excelData)                   *
    // Set the excelData state
    setExcelData(excelData);

    // Set surveyResponse as an array of arrays
    setSurveyResponse([questionTitles, ...formattedRows]);
  } catch (error) {
    console.log('fetch error', error);
  }
};





  useEffect(() => {
    fetchData();
  }, [surveyInt]);

  const csvReport = {
    filename: 'Rivazia Report.csv',
    data: surveyResponse,
  };

  return (
    <>
      <Csv>
        <div className='export'>
          {surveyResponse.length > 0 ? (

            <CSVLink {...csvReport}>
              <div className='download-csv'>Download CSV</div>
            </CSVLink>
          ) : (
            <div className='disabled-csv'>No data available</div>
          )}
        </div>
      </Csv>


    </>
  );
}


const Csv =styled.div`
.download-csv{
color: black;

    
   }

`