import QrCode from "@mui/icons-material/QrCode";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useRef, useState } from "react";
import "./style.css";
// import QRCode from 'qrcode'
import QRCode from "qrcode.react";
import React from "react";
import { margin, textAlign } from "@mui/system";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  borderRadius:'5px',
  boxShadow: '0px 0px 1px 2px #d1d0f7',
  p: 4,
};

interface Props {
  show: boolean;
  onClose: () => void;
  url: any;
}

export default function BasicModal({ show, onClose, url }: Props) {
  const handleClose = () => onClose();
  // const [url, setUrl] = useState("");
  const [qr, setQr] = useState("");
  const qrRef = useRef();
  console.log(qrRef);
  const downloadQRCode = (evt: React.FormEvent) => {
    evt.preventDefault();
    // @ts-ignore
    let canvas = document.getElementsByTagName("canvas")[0];
    console.log(canvas);
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const qrCode = (
    <QRCode
      id="qrCodeElToRender"
      size={200}
      value={url}
      bgColor="white"
      fgColor="#141926"
      level="H"
      // imageSettings={{
      //   src: icon,
      //   excavate: true,
      //   width: 500 * 0.1,
      //   height: 500 * 0.1,
      // }}
    />
  );

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div className="QrCode" style={{textAlign:"center"}}>
            {/* <input type ='text'
            placeholder='https://google.com' />
            
            <button onClick={GenerateQRCode}>Generate</button>
            < img src={qr}/> */}
            <h2>Download Your QR Here</h2>
            <div className="qr-container__qr-code" ref={qrRef.current} style={{margin:"10px"}}>
              {qrCode}
            </div>
            <input
              type="text"
              placeholder="e.g. https://google.com"
              value={url}
              // onChange={(e) => setUrl(e.target.value)}
            />
            <button onClick={downloadQRCode}>Download</button>

            {/* {qr && <>
				<img src={qr} />
				<a href={qr} download="qrcode.png">Download</a>
			</>} */}
            
          </div>
        </div>
      </Box>
    </Modal>
  );
}


