import React from 'react'
import styled from "styled-components";
import TopNavBar from "../../Component/TopNavBar"
const paragraph = `
  "Client", "You", and "You are" refer to the person who logs on to this website and agrees to the Company's terms and conditions. 

  Our Company is commonly referred to as "The Organization", "Ourselves", "We", "Our", and "Us". 

  The terms "Party", "Parties", or "Us" relate to both the Client and the Company.
`;
const points = paragraph.split('\n\n');

const content = `
It may be retained by the receiving party if allowed or required by the Agreement or applicable law. 

If retention is not permitted or necessary, the receiving party will either return the Confidential Information to the disclosing party or securely destroy it. 
`;

const point=content.split('\n\n');

export default function Index() {
  return (
    <TERMS>
  <div className="navbar-container">

    <TopNavBar/>
  </div>
  
    <div className='Terms-Container'>
      <h1>Terms and Conditions</h1>
      <p>
      These terms and conditions outline the rules and regulations for the use of the Rivazia Website, located at rivazia.com. By accessing this website, we assume you accept these terms and conditions. 
        
      </p>
      <p>
      In simpler terms, the following words apply to the Terms and Conditions, Privacy Statement, and Disclaimer Notice, as well as all agreements: 
      </p>
      <div>


      <ul className='bullet-list'>
        {points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
      </div>
      <p>

      All these terms refer to the offer, acceptance, and payment necessary to begin the process of providing assistance to the Client for the purpose of meeting their needs regarding the Company's services. The aim is to efficiently address the Client's needs related to the provision of the Company's stated services. 


        </p>
        <h5>Our Email Address is  <a href="https://dev.rivazia.com/" className="link">dev.rivazia.com</a> </h5>
<h3>Cookies:</h3>
<p>
We employ the use of cookies. By accessing Rivazia, you agreed to use cookies in agreement with the Rivazia Privacy Policy. 

Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies. 
</p>

        <h3>  License Agreement</h3>
        <p>
        Either party has the right to terminate the Agreement by providing written notice to the other party if there is a material breach of the Agreement. However, the breaching party will have a 30-day period to remedy the breach and correct the issue. If the breach is successfully cured within this 30-day period, the termination will not take effect, and the Agreement will continue as before. But, if the breaching party fails to rectify the material breach within the specified 30-day notice period, the non-breaching party may proceed with terminating the Agreement. 
        </p>

        <h3>
        Data Backup:
        </h3>
        <p>

        The policy may state that provider keeps a backup of user data for a limited period in case of accidental data loss or system failures. However, these backups will not be used to restore individual accounts after termination.


        </p>
        <h3>
        Refund and Payments: 
        </h3>
<p>

If the termination of the service is initiated by the Customer, they will have the following entitlements: </p>

<p>
  
  

  (a) The Customer will receive a pro-rata refund for the unused portion of any prepaid fees for the terminated subscription. The refund will be calculated based on the remaining duration of the subscription as of the effective date of termination. However, it's important to note that in certain cases, a refund might not be possible due to restrictions imposed by Export Laws. 
  </p>
  <p>




(b) The Customer will be released from the obligation to pay any fees that would have been due for periods after the effective date of termination. This means they won't be liable for any future payments beyond the termination date. 
  </p>

<h3>
Effect of Expiration or Termination:
</h3>

<p>
When the Agreement between the Customer and Rivazia expires or is terminated, the following will occur: </p>
<p>



(a) The Customer's access and right to use the Cloud Service provided by Rivazia will come to an end. 
</p>
<p>


(b) Any Confidential Information that was shared between the parties during the course of the Agreement will be handled as follows: 



<div>


      <ul className='bullet-list'>
        {point.map((data, index) => (
          <li key={index}>{data}</li>
        ))}
      </ul>
      </div>


</p>
<p>




(c) The termination or expiration of this specific Agreement will not impact any other existing agreements or contracts that the parties may have in place with each other. Those other agreements will continue to be valid and enforceable. 

</p>



<h3>
Third-Party Links: 
</h3>
<p>
Links to third-party websites are possible; however, since we have no control over them, we are unable to take responsibility for their behaviour. We advise you to review the terms and conditions and privacy policies of such other websites before disclosing your personal information on any such website. Links to websites maintained by parties other than Rivazia may be found on the Sites. These websites are not under our control, and we are not liable for their content, privacy practices, or other actions. The fact that we have included links to these websites does not imply that we support the content found there or that we are associated with the website owners. These websites and services might have their own privacy policies. 
</p>
<h3> Content Liability: </h3>
<p>
We are not liable for any content that appears on your Website. As the Website owner, you agree to indemnify and protect us from any claims that may arise on your Website. You must ensure that no links on your Website can be considered defamatory, obscene, or illegal, and they should not infringe upon or violate the rights of any third party. 
</p>
<h3>
Owner Details of the Domain: 
</h3>
<p>
dev.rivazia.com  was established in 2023 with the mission of Survey questionnaire. This Rivazia is owned by the website xbi4.com.
</p>


    </div>
    </TERMS>
  )
}

const TERMS=styled.div`

.bullet-list{
   list-style-type: disc;
  padding-left: 20px;

}
.Terms-Container{
  padding: 2.5rem;
  padding-top:5%;
}
.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999; /* Set a high z-index value to ensure it appears above other content */
}

@media screen and (max-width: 767px){

  .Terms-Container{
    padding-top: 50%;
  }

 


}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Terms-Container{
    padding-top: 10%;
  }


}


`