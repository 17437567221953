import FooterMap from "../svg/LandingFooter.svg"
const Icon = () => {
    return (
   
        <div style={{backgroundColor:"#001D3D", height:'350px', opacity: 0.9,marginTop:'50px',maxWidth: "100%"}}>
        <img src={FooterMap} style={{ width: "100%", height: "350px", maxWidth: "100%" }} />

      </div>


    )
  }
  export default Icon;