import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import BuildIcon from '@mui/icons-material/Build';
// import DesignServicesIcon from '@mui/icons-material/DesignServices';
// import LoopIcon from '@mui/icons-material/Loop';
// import SettingsIcon from '@mui/icons-material/Settings';
import "./style.css";

import HeaderMenuBar from "../CreateSurvey/components/Header";
// import SideIconBar from "../CreateSurvey/components/SideIconBar"

// import SideIconBar from "../../Components/SideIconBar";
import SubSideIconBar from "../../Components/SubSideIconBar";
// import Header from "../../Components/Header";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Button, TextField, Typography } from "@mui/material";
import NewPjt from "../NewPjt";
import { useParams } from "react-router-dom";
import { get } from "../../Utils/Function/Method";
// import NewPjt from "./components/CreateSurveyModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 6,
};

export default function Index(props) {
  const [showInitialModal, setShowInitialModal] = useState(true);
  const handleClose = () => setShowInitialModal(false);
  // console.log(surveyId, "param surveyId")
  // console.log(typeof(surveyId), "param surveyId")
  const CountEle = document.querySelectorAll(".quest-container");
  const CountAll = CountEle.length;
  const editMode = props.editMode;
  console.log(CountAll);
  const { surveyId } = useParams();
  const surveyInt = parseInt(surveyId);
  const [surveyInfo, setSurveyInfo] = useState(null);
  async function getData() {
    try {
      const response = await get(`/survey_info/${surveyInt}`);
      // console.log("Response surveyInfo", response[1][0]);
      setSurveyInfo(response[1][0]);
    } catch (error) {
      console.log("FetchError", error);
    }
  }

  useEffect(() => {
    getData();
  }, [surveyInt]);
  useEffect(() => {
    // console.log("Updated surveyInfo", surveyInfo);
  }, []);

  // console.log(editMode, "Edit Mode")

  return (
    <div>
      <HeaderMenuBar
        surveyId={surveyId}
        editMode={editMode}
        surveyInfo={surveyInfo}
      />

      <div className="createSurvey-home">
        {/* <SideIconBar /> */}
        {/* <div>Image</div> */}
        {/* <div
          className="workspace-container"
          style={{
            width: "70%",
            boxShadow: "0px 0px 2px grey",
            borderRadius: "10px",
            marginLeft: "20px ",
            marginTop: "20px",
            height: "75vh",
          }}
        > */}
        {/* <SubSideIconBar/> */}
        {surveyInfo && <NewPjt surveyInfo={surveyInfo} editMode={editMode} />}
        {/* <div className="quest-access">
        {Array.from({length:CountAll}, (_, CountAll)=> (
          <a href={`#question${CountAll+1}`}>{CountAll}</a>
        )
        )}
      </div> */}
        {/* </div> */}
      </div>

      {/* <Modal
    open={showInitialModal}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        CREATE A NEW SURVEY
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Survey Title
      </Typography>
      <input type="text">rw</input>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '40ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic"  />
      </Box>
      <Box sx={{ '& button': { ml: 30, width:'15ch',mt:3 } }}>
      <Button variant="contained" color="success" onClick={()=>handleClose()} >
        Success
      </Button>
      </Box>
    </Box>
  </Modal> */}
    </div>

    // </div>
  );
}
