import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
      primary: {
        main: "#061d67",
        // light: 
      },
      secondary: {
        main: "#5EC273",
      },
    },
  });