import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material//Paper";

import {
  useValidEmail,
  useValidPassword,
  useValidUsername,
  useValidCode,
} from "../../hooks/useAuthHooks";

import {
  Code,
  Email,
  Password,
  Username,
} from "../../Components/Auth/authComponents";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../contexts/AuthContext";

// const useStyles = makeStyles({
//   root: {
//     height: "100vh",
//   },
// });

const Index = () => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const { code, setCode, codeIsValid } = useValidCode("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const { username, setUsername, usernameIsValid } = useValidUsername("");
  const [error, setError] = useState("");

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword("");
  const { userName } = useContext(AuthContext);

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0;

  const resetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(userName, code, password);
      navigate("/log-in");
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <Grid
      // className={classes.root}
      style={{height: "100vh"}}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        xs={11}
        sm={6}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
      >
        <Paper style={{ width: "100%", padding: 16 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* Title */}
            <Box m={3}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h3">Forgot Password</Typography>
              </Grid>
            </Box>

            <Box width="80%" m={1}>
              <Code codeIsValid={codeIsValid} setCode={setCode} />
            </Box>
            <Box width="80%" m={1}>
              <Password
                label="Password"
                passwordIsValid={passwordIsValid}
                setPassword={setPassword}
              />
            </Box>
            <Box width="80%" m={1}>
              <Password
                label="Confirm Password"
                passwordIsValid={passwordConfirmIsValid}
                setPassword={setPasswordConfirm}
              />
            </Box>

            {/* Error */}
            <Box mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" justifyContent="center">
                <Box m={1}>
                  <Button
                    onClick={() => navigate(-1)}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Box>
                <Box m={1}>
                  <Button
                    disabled={isValid}
                    color="success"
                    variant="contained"
                    onClick={resetPassword}
                  >
                    Change Password
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Index;
