import React from 'react'
import "./style.css";

export default function Index() {
  return (
    <>
<div className='lisiting-header'>
    <h1>Pick Your Perfect Plan!</h1>
    <p className='sub-header'>Discover the ideal plan that suits your preferences and start influencing deciisons that matter</p>
</div>
<div className='price-listing'>

<div className='plan-one'>
    <h1 >149 INR</h1>
    <p>/user/Month</p>
    < h2>Basic</ h2>
    <button>Get Started</button>

</div>
<div className='plan-two'>
    <h1>599 INR</h1>
    <p>/user/Month</p>
    < h2>Premium</ h2>
    <button>Get Started</button>

</div>
<div className='plan-three'>
    <h1>999 INR</h1>
    <p>/user/Month</p>
    < h2>Advanced</ h2>
    <button>Get Started</button>

</div>
<div className='plan-free'>
    <h1>0 INR</h1>
    <p>/user/Month</p>
    < h2>Free</ h2>
    <button>Get Started</button>

</div>
</div>
    <div className='list-overall-container'>

    

<div className="table-container">
      <table>
        <tbody>
          <tr >
            <td className='black-text' >Survey Builder</td>
            <td className='black-text'>10</td>
            <td className='black-text'>25</td>
            <td className='black-text'>100</td>
            <td className='black-text'>3</td>
          </tr>
          <tr>
            <td className='black-text'>No.of questions</td>
            <td className='black-text'>10</td>
            <td className='black-text'>20</td>
            <td className='black-text'>40</td>
            <td className='black-text'>5</td>
          </tr>
          <tr>
            <td className='black-text'>Question Type</td>
            <td className='black-text'>5</td>
            <td className='black-text'>7</td>
            <td className='black-text'>All</td>
            <td className='black-text'>3</td>
          </tr>
          <tr>
            <td>Single Text</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Multiple Choice</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>Rating</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>CheckBox</td>
            <td> </td>
            <td> &#x2713; </td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>NPS</td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>Mulit-Line Text</td>
            <td> </td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>DropDown</td>
            <td> </td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Upload</td>
            <td> </td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Radio Button</td>
            <td> </td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Yes or No</td>
            <td> </td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Skip and Logic</td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td className='black-text'>Export Options</td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>CSV</td>
            <td> </td>
            <td> </td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>PDF</td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td className='black-text'>Graphical Representations</td>
            <td className='black-text'>&#x2713; </td>
            <td className='black-text'>&#x2713;</td>
            <td className='black-text'>&#x2713;</td>
            <td className='black-text'>&#x2713;</td>
          </tr>
          <tr>
            <td>Pie chart</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Bar chart</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Line chart</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Horizontal chart</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td className='black-text'>Survey Responses</td>
            <td className='black-text'>10</td>
            <td className='black-text'>25</td>
            <td className='black-text'>100</td>
            <td className='black-text'>3</td>
          </tr>
          <tr>
            <td className='black-text'>Sharing Responses</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Embedded Link</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>Social Media(whatsapp,linedln,twitter,FB)</td>
            <td> </td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
            <td> </td>
          </tr>
          <tr>
            <td>QR Code</td>
            <td> </td>
            <td> </td>
            <td>&#x2713; </td>
            <td> </td>
          </tr>
          <tr>
            <td className='black-text'> Customer Support(24/5)</td>
            <td className='black-text'>Phone,Email(1-2 WDS)</td>
            <td className='black-text'>Phone,Email(1-3 WDS)</td>
            <td className='black-text'>Phone, Email,Meeting Schedlue</td>
            <td> </td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </table>
    </div>
    </div>
    </>
  )
}
