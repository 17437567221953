import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector } from "recharts";
import styled from "styled-components";

const renderActiveShape = (props: any) => {

  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    responses,
    option,
    responsepercentage
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
   
    
    
    <g>
      {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name} */}
      {/* </text> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="var(--blue)"
      >{`${option}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="var(--green)"
      >{`${responsepercentage}`}
        {/* {`${(percent * 100).toFixed()}%)`} */}
      </text>
    </g>
    
  );
};


export default function App(props) {
  const{surveyReport ,q_id,chartColor}=props;
  // console.log("----------",surveyReport) 
  const colors = ['#f24260', '#9260f7', '#45bd62', '#1878f3', '#f7b928', '#29bba6'];
  const randomIndex = Math.floor(Math.random() * colors.length);
 
  // const [activeIndex, setActiveIndex] = useState(0);
  // const onPieEnter = useCallback(
  //   (_, index) => {
  //     setActiveIndex(index);
  //   },
  //   [setActiveIndex]
  // );

  const questionData = surveyReport.filter((data) => data.q_id === q_id)[0];

  const chartData = questionData.answers
  .filter((answer) => answer.q_answer !== "None")
  .map((answer) => ({
    name: answer.q_answer,
    responsepercentage: answer.percentage,
    responses: answer.answer_count,
  }));
  // console.log("CHARTDATA",chartData)              *


  return (
    <>
      {
  questionData.q_type === "email" || questionData.q_type === "phone" || questionData.q_type==="TextInput" ||questionData.q_type==="LongText"|| questionData.q_type==="upload"? (
    null // If the question type is email or phonenumber, don't render the chart
  ) : (
    <PieR>
      <div className="graph">
    <PieChart width={500} height={400} >
      <Pie
       label={({ name, responses }) => `${name} (${responses})  `}
       
        // activeIndex={activeIndex}
        // label={renderActiveShape}
        data={chartData}
        // data={data}
        // data={data.Data}
        
        cx={200}
        cy={200}
        innerRadius={60}
        outerRadius={80}
        
        fill={chartColor}
        dataKey="responses"
        

        // onMouseEnter={onPieEnter}
      />
    </PieChart>
    </div>
    </PieR>
    )
  }
    </>
  );
}

const PieR =styled.div`
   .graph{
    display: flex;
    justify-content: center;
    margin: 10px auto;
   }
   /* .recharts-sector{
    fill: var(--random-color);
   } */
`
