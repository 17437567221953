import { AllFunction } from "../../Functions";
import { Delete } from "../../../../assets/svg/createSurvey";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { get } from "../../../../Utils/Function/Method";
import ShortTextIcon from '@mui/icons-material/ShortText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import GradingIcon from '@mui/icons-material/Grading';


const options = [
  {  icon: <RadioButtonCheckedIcon style={{color:'#001D3D'}}  />,value: 'multipleChoice', label: 'Multiple Choice', },
  { icon: <ShortTextIcon style={{ color: '#8cc739' }} />,value: 'TextInput', label: 'TextInput' },
  { value: 'Rating', label: 'Rating' ,icon: <StarOutlineIcon  style={{ color: '#9C27B0' }}/>},
  { value: 'boolean', label: 'Yes or No' ,icon: <ThumbsUpDownIcon style={{ color: '#008CBA' }} />},
  { value: 'Radio Button', label: 'Radio Button' ,icon: <RadioButtonCheckedIcon style={{ color: '#f50057' }} />},

  { value: 'nps', label: 'NPS',icon: <LinearScaleIcon  style={{color:'#00695f'}}/> },
  { value: 'email', label: 'Email',icon: <ForwardToInboxIcon style={{ color: '#ff9100' }} />  },
  { value: 'phone', label: 'Phone Number' ,icon: <LocalPhoneIcon  style={{ color: '#33eaff' }}/>},
  { value: 'upload', label: 'Upload' ,icon: <UploadFileOutlinedIcon style={{ color: '#f50057' }} />},
  { value: 'dropdown', label: 'Drop Down' ,icon: <ArrowDropDownOutlinedIcon style={{ color: '#ffeb3b' }} />},
  { icon: <ShortTextIcon style={{ color: '#8cc739' }} />,value: 'LongText', label: 'LongText' },
  { value: 'checkbox', label: 'Checkbox',icon:
  <PlaylistAddCheckIcon style={{ color: '#f44336' }}/>},

  // Add more options as needed
];

const QuestionType = ({
  currentQuestion,
  questions,
  handleQuestionChange,
  handleChoiceChange,
  addChoice,
  removeChoice,
  handleSkipChoiceChange,
  handleSkipToChange,
  handleDeleteSkipLogic,
  addSkipLogic,
  // questionType
}) => {


  // const { surveyId } = useParams();
  // // const {surveyNum }=useContext(AuthContext)
  // const[surveysInfo,setSurveysInfo]=useState(null)
  // const surveyInt = parseInt(surveyId);
  // useEffect(() => {
  //   async function getData() {
  //     try {
  //       const response = await get(`/survey_info/${surveyInt}`);
  //       console.log("Response surveyInfo", response[1][0]);
  //       setSurveysInfo(response[1][0]);
  //     } catch (error) {
  //       console.log("FetchError", error);
  //     }
  //   }
  
  //   if (surveyId && surveysInfo === null) {
  //     getData();
  //   }
  // }, [surveyId, surveysInfo]);
  return (
    <>
     <Select
      className="ipstyle"
      name="type"
      value={currentQuestion.type}
      onChange={handleQuestionChange}
      label="Select Type"
      sx={{height:'45px'}}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300, // Set the max height of the dropdown
          },
        },
      }}
      // sx={{ height: '34px', marginTop:'30px' }}
    >
    {options.map((option, index) => (
        <MenuItem key={option.value} value={option.value} sx={{ height: '30px', marginBottom: index < options.length - 1 ? '8px' : '0px' }}>
          <List>
            <Typography sx={{ marginBottom: '5px', marginRight: '10px', fontSize: "15px" }}>
              <ListItemIcon sx={{ marginRight: '10px', marginTop: '10px' }}>
                {option.icon || null}
              </ListItemIcon>
              {option.label}
            </Typography>
          </List>
        </MenuItem>
      ))}
    </Select>
      {(currentQuestion.type === "multipleChoice" ||
        currentQuestion.type === "dropdown" ||
        currentQuestion.type === "checkbox"  || currentQuestion.type === "Radio Button" ) && (
        <>
          <div className="row-container choice-grid">
            {/* <h3>Choices:</h3> */}
            {currentQuestion.choices && Array.isArray(currentQuestion.choices) && currentQuestion.choices.map((choice, index) => (
              <div key={index} className="choice-container">
                <input
                  type="text"
                  value={choice}
                  onChange={(event) => handleChoiceChange(index, event)}
                />
                <button
                  title="Remove"
                  className="del-btn"
                  type="button"
                  onClick={() => removeChoice(index)}
                >
                  <Delete />
                </button>
              </div>
            ))}
            <button
              className="quest-builder-btns"
              type="button"
              onClick={addChoice}
            >
              Add Choice
            </button>
          </div>
          
        </>
      )}
      <div className="row-container">
            {currentQuestion && currentQuestion.skipLogic && Array.isArray(currentQuestion.skipLogic) && currentQuestion.skipLogic.length>0 && currentQuestion.skipLogic.map((rule, index) => (
              <div key={index} className="skip-logic-container">
                <select
                  className="ipstyle"
                  value={rule.choice}
                  onChange={handleSkipChoiceChange(index)}
                >
                  <option disabled value="">
                    If Answer
                  </option>
                  {currentQuestion.choices && Array.isArray(currentQuestion.choices) && currentQuestion.choices.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice.slice(0, 30)}...
                    </option>
                  ))}
                  <option value="isAnswered">Is Answered</option>
                  <option value="isNotAnswered">Is Not Answered</option>
                </select>
                <select
                  className="ipstyle"
                  value={rule.skipTo}
                  onChange={handleSkipToChange(index)}
                >
                  <option disabled value="">
                    Skip To
                  </option>
                  {questions && questions.length>0 && questions.map((question, index) => (
                    <option key={question.text} value={index + 1}>
                      {index + 1}  {question.text}
                    </option>
                  ))}
                  <option value="completed">Completed</option>
                </select>
                <button
                  title="Remove"
                  className="del-btn"
                  type="button"
                  onClick={() => handleDeleteSkipLogic(index)}
                >
                  <Delete />
                </button>
              </div>
            ))}
            {/* <label htmlFor="">Add Skip Logic</label> */}
            <button className="quest-builder-btns" onClick={addSkipLogic}>
              Add Skip Logic
            </button>
          </div>
      {currentQuestion.type === "nps" && (
        <div>
          <h3>Min Value:</h3>
          <div>
            <input
              type="number"
              name="min"
              min={0}
              value={currentQuestion.min}
              onChange={handleQuestionChange}
            />
          </div>
          <h3>Max Value:</h3>
          <div>
            <input
              type="number"
              name="max"
              max={20}
              value={currentQuestion.max}
              onChange={handleQuestionChange}
            />
          </div>
        </div>
      )}
  
    </>
  );
};


export default QuestionType;

const index = () => {
  return <div>index</div>;
};
