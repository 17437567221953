import React, { useEffect, useState ,useRef} from "react";
// import NavBar from "./Component/BuiildBar";
import HeaderBg from "../../assets/LandingPageImages/headerbg";
import styled from "styled-components";
import Card from "./Component/Card";
import HeaderBgImg from "../../assets/LandingPageImages/headerbgimg";
import MobilefrndImgs from "../../assets/LandingPageImages/MobilefndImg";
import ServiceImg from "../../assets/LandingPageImages/Service";
import BuildImg from "../../assets/LandingPageImages/Build";
import Solution from "./Component/Solutions";
import FooterMapImg from "../../assets/LandingPageImages/Footer";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "./Component/Footer";
import LandingNew from "../../assets/LandingPageImages/HeaderImg";
import RivaziaLogo from "../../assets/rivaziaAnim";
import { Button } from "@mui/material";
import RectImg from "../../assets/LandingPageImages/Mr";
import TopNavbar from "./Component/TopNavBar";
import HomeImage from "../../assets/LandingPageImages/NewRivazia"
// import Build from "./Component/BuiildBar"

export default function Index() {
  console.log("master brach")
  const navigate = useNavigate();
  // -------------Animation Useeefect for home top container---------------
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Set animate to true when the component mounts or when content is updated
    setAnimate(true);

    // Reset animate after the animation duration to allow for subsequent animations
    const timeoutId = setTimeout(() => {
      setAnimate(false);
    }, 1000); // Adjust the duration based on your animation duration

    return () => clearTimeout(timeoutId);
  }, []);

  // --------------Animation for Image Container-----------------------
 
  const imageOneRef = useRef();
  const imageTwoRef = useRef();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold based on your needs
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-bottom'); // Add a class to trigger the animation
          observer.unobserve(entry.target); // Unobserve once the animation is triggered
        }
      });
    };
    const observerImageOne = new IntersectionObserver(handleIntersection, options);
    const observerImageTwo = new IntersectionObserver(handleIntersection, options);

    observerImageOne.observe(imageOneRef.current);
    observerImageTwo.observe(imageTwoRef.current);

    return () => {
      observerImageOne.disconnect();
      observerImageTwo.disconnect();
    };
  }, []);

  // ---------------------------------------------------------

  const buildImageRef = useRef();
  const buildContentRef = useRef();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold based on your needs
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.classList.contains('build-container-image')) {
            entry.target.classList.add('animate-slide-in-left');
          } else if (entry.target.classList.contains('build-content')) {
            entry.target.classList.add('animate-slide-in-right');
          }

          observer.unobserve(entry.target);
        }
      });
    };

    const observerImage = new IntersectionObserver(handleIntersection, options);
    const observerContent = new IntersectionObserver(handleIntersection, options);

    observerImage.observe(buildImageRef.current);
    observerContent.observe(buildContentRef.current);

    return () => {
      observerImage.disconnect();
      observerContent.disconnect();
    };
  }, []);


  return (
    <>
      <NAV>
        {/* <NavBar isFixed={isNavbarFixed}/> */}
        {/* <NavBar /> */}
        <div className="navbar-container">
          <TopNavbar />
          </div>
        <div className="Overall-landing-page-container">
          <div className={`Home-Top-Content ${animate ? 'animate-zoom-in' : ''}`}>
                  <div className='Home-Top-InsideContent'>

                 
                  <h1>Welcome to <span className='Green-text'>RIVAZIA</span></h1>
                  <h1>Where Your opinion matters !</h1>
                  <p className="p-text">At Rivazia,we believe that every voice deserves to be heard.</p>
                  <p className="p-text">Our platform is dedicated to providing a space where individuals</p>
                  <p  className="p-text">
                    like you can contribute to shaping the world around us✨
                  </p>
                  <button 
                    onClick={() => navigate("/log-in")}
                  className="shaking-button" >Get Started 🚀 </button>
                  </div>
                  <div className='Top-NavImg'>
                    <HomeImage/>
                  </div>
                  


                </div>


          <div className="features">
            <div className="section-header-main">
              <h1 id="animated-heading">
                <span className="animated-letter">H</span>
                <span className="animated-letter">o</span>
                <span className="animated-letter">w</span>
                <span className="animated-letter"> </span>
                <span className="animated-letter">C</span>
                <span className="animated-letter">a</span>
                <span className="animated-letter">n</span>
                <span className="animated-letter"> </span>
                <span className="animated-letter">W</span>
                <span className="animated-letter">e</span>
                <span className="animated-letter"> </span>
                <span className="animated-letter">H</span>
                <span className="animated-letter">e</span>
                <span className="animated-letter">l</span>
                <span className="animated-letter">p</span>
                <span className="animated-letter"> </span>
                <span className="animated-letter">Y</span>
                <span className="animated-letter">o</span>
                <span className="animated-letter">u</span>
                <span className="animated-letter"> </span>
                <span className="animated-letter">?</span>
              </h1>
            </div>

            {/* <div className="section-header-main">
  <h1 id="animated-heading">How Can We Help You ?</h1>
</div> */}
<div className="full-section-sub-header">

            <div className="section-sub-header-1">
              <h3>We will bring our vast experience to help you succeed.</h3>
            </div>
            <div className="section-sub-header-2">
              <h3>
                See how we can help you find the answers you're looking for.
              </h3>
            </div>
</div>
          </div>

          <div  className="image-one-container" ref={imageOneRef}>
            <div className="image-one">
              <MobilefrndImgs />
            </div>
            <div className="image-one-content">
              <h1 className="heading">MobileFriendly 📱</h1>

              {/* <h1 className='heading '>
  Mobile Friendly
</h1> */}
              <p className="description">
                <ul className="img-two-description-content">
                  <li>
                 
                    Our Mobile-Friendly Design Is The Pocket-Sized Perection
                  </li>
                  <li> You Need For Your Business. Keep Your Customers </li>
                  <li> Engaged WIth Our USer-Friendly Mobile Interface </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="image-two-container"  ref={imageTwoRef}>
            <div className="image-two-content">
              <h1 className="heading">Customized SurveyServices 📋</h1>

              {/* <h1 className='heading '>
  Mobile Friendly
</h1> */}
              <p className="description">
                <ul>
                  <li> Create A Survey With Helop Of Easy To Use Online </li>
                  <li> Survey Tools.Simply Choose From A Wide Range Of </li>
                  <li> Survey Templates For Market Research, Customer </li>
                  <li>Satisfaction Surveys, And More</li>
                </ul>
              </p>
            </div>
            <div className="image-two">
              <ServiceImg />
            </div>
          </div>
          <Card />
          <div className="build-container">
            <div className="build-container-image" ref={buildImageRef}>
              <BuildImg />
            </div>
            <div className="build-content" ref={buildContentRef}>
              <h1 className="heading">Build Your First Survey </h1>
              <p className="description">
                <ul>
                  <li>Construct your survey by yourself or use templates.</li>
                  <li>
                    Share your surveys and integration into many platform.
                  </li>
                  <li>Analyze your survey’s responses and insights.</li>
                  <li>
                    Generate your survey’s results and insight report in simple
                    click.
                  </li>
                  <li> Review your audience responses and feedbacks.</li>
                </ul>
              </p>
            </div>
          </div>
          {/* <Build/> */}
          
          <Solution />
          <div className="footer-bg-image">
            <FooterMapImg />

            <div className="footer-content">
              <h2 className="footer-heading">Get started with our awesome service!</h2>
              <p className="footer-sub-heading">Join us today to experience the best of our offerings.</p>
              <button className="footer-btn"   onClick={() => navigate("/sign-up")}>Join Us 🚀</button>
            </div>
          </div>

          <Footer />
        </div>
      </NAV>
    </>
  );
}
const NAV = styled.div`

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999; /* Set a high z-index value to ensure it appears above other content */
}
.Home-Top-Content{
  display: flex;
  justify-content: space-around;
  /* margin-top: 8%; */
  /* margin-bottom: 5%; */
  opacity: 0;
  height: 90vh;
  transform: scale(1.5); /* Start with a zoom-out effect */
  animation: zoomIn 1s ease-in-out forwards;
  background: linear-gradient(
    to bottom right,
    #ffcccc,
    #ffebcc,
    #ffffcc,
    #e5ffcc,
    #ccffcc,
    #ccffe6
  );
  background-size: 200% 200%; /* Increase the size of the gradient */
  transition: background-position 1s;

}
.Home-Top-Content:hover {
  background-position: 100% 100%; /* Move the gradient to the end */
}
.Home-Top-InsideContent {
  margin-top: 12%;
/* background-color: #ffd700; */
}
.animate-zoom-in {
  animation: zoomIn 1s ease-in-out forwards;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(1.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


.Green-text{
  color:var(--green);


}
  .footer-bg-image {
    position: relative;
  }

  .footer-content {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .footer-heading {
    color: white;
    font-size: 35px;
    margin-bottom: 20px;
    animation-name: slideInLeft;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation: waveColorHeading 4s infinite;
  }
.footer-sub-heading{
  color: white;
  font-weight: bold;
  animation-name: slideInRight;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation: waveColorSubHeading 4s infinite;
     
}
.p-text{
  font-size: x-large;
}
@keyframes waveColorHeading {
      0% {
        color: #FFD1D1; /* Red */
      }
      25% {
        color: #DBD8FC; /* Green */
      }
      50% {
        color:  #E1FCD8; /* Blue */
      }
      75% {
        color:  #E1FCD8; /* Magenta */
      }
      100% {
        color:   #FFD1D1; /* Red */
      }
    }

    @keyframes waveColorSubHeading {
      0% {
        color: #FFD1D1; /* Red */
      }
      25% {
        color: #DBD8FC; /* Green */
      }
      50% {
        color:  #E1FCD8; /* Blue */
      }
      75% {
        color:  #E1FCD8; /* Magenta */
      }
      100% {
        color:   #FFD1D1; /* Red */
      }
    }
@keyframes slideInLeft {
      0% {
        transform: translateX(-100%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes slideInRight {
      0% {
        transform: translateX(100%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  .footer-btn {
    background-color: var;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }

  .line-one {
    color: black;
    font-size: x-large;
    font-weight: bold;
    margin-top: 20%;
    margin-bottom: 20px;
  }
  .line-two-one {
    margin-bottom: 20px;
    color: black;
    font-size: small;
    margin-top: 20px;
  }
  .nav-rec-content-subheading {
    margin-bottom: 20px;
  }
  .nav-rec-content-subheading-two {
    margin-top: 10px;
  }

  .line-two-two {
    margin-bottom: 20px;
    color: black;
    font-size: small;
    margin-top: 20px;
  }
  .total-line-box {
    margin-left: 20px;
  }
  .header-inside-box {
    display: flex;
  }

  .header-inside-box {
    /* animation: shake 0.5s infinite; */
  }
  .image-one-container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    opacity: 0;
  transform: translateY(50px); 
  /* background-color: #FFD1D1;; */
  }
 
  .animate-bottom {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s, transform 1s;
}
  .image-one {
    width: 40%;
    margin-right: 20px;
  }

  .image-one-content {
    width: 60%;
    margin-bottom: 20px;
  }
  .image-two-container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    opacity: 0;
    /* background-color: #DBD8FC; */
  transform: translateY(50px); /* Move the element down initially */
  
  }
   
  .animate-bottom {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s, transform 1s;
}

  .image-two {
    width: 40%;
    margin-right: 20px;
  }

  .image-two-content {
    width: 60%;
    margin-bottom: 20px;
  }
  .description {
    /* font-style: italic;
    word-wrap: break-word;
  
     line-height: 1.5; */

    /* display: block; */
    margin: 0;
    width: auto;
    font-size: 12px;
    font-style: italic;
    overflow: wrap;
    text-align: center;
    justify-content: center;
    /* background-color: #142727; */
  }
  .description ul {
    margin: 0;
    padding: 0;
  }

  .description li {
    font-size: 19px;
  }
  .heading {
    margin-left: 35%;
  }

  .build-container {
    display: flex;
    align-items: center;
    margin-left: 10%;

    /* background-color: blanchedalmond; */
  }

  .build-container-image {
    width: 40%;
    margin-right: 20px;
    opacity: 0;
  transform: translateX(-50px); 
  }

  .build-content {
    width: 60%;
    opacity: 0;
  transform: translateX(50px);
  }

  .build-content {
    opacity: 1;
  }
  /* -----------------animation for build-content and image----------- */

  @keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Add the animation classes when the elements are intersecting */
.animate-slide-in-left {
  animation: slideInLeft 1s forwards;
}

.animate-slide-in-right {
  animation: slideInRight 1s forwards;
}
  /* ------------------------------------help------------------------------------- */
  .features{
    /* margin-top:%; */
  }

  .section-header-main {
    text-align: center;
  
  }

  #animated-heading .animated-letter {
    display: inline-block;
    animation: colorAnimation 3s infinite;
  }

  @keyframes colorAnimation {
    0% {
      color: #ff0000;
    }
    33% {
      color: #00ff00;
    }
    66% {
      color: #0000ff;
    }
    100% {
      color: #ff0000;
    }
  }

  /* ----------------------heading------------------------------------------------------ */

  .heading {
    display: inline-block;
    position: relative;
  }

  .heading::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffd700; /* Change the color to your desired animation color */
    transform: scaleX(0);
    transform-origin: left;
    animation: selected 2s infinite linear;
  }

  @keyframes selected {
    0% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }


  /* ------------------------Shake animation--------------------------------------------------- */

  @keyframes shakeAnimation {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .shaking-button {
    margin-top: 30px;
    animation: shakeAnimation 0.5s infinite;
    font-size:17px;
  }

  .features {
    text-align: center;
    color: var(--blue);
    font-size: small;
  }
  .image-one {
  }

  @keyframes buttonAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .footer-btn {
    animation: buttonAnimation 2s infinite;
  }
  @media screen and (max-width: 767px) {
    .features{
      margin-top: 10%;
    }
    .Overall-landing-page-container{
      padding-top:38%;
      /* background-color: #DBD8FC; */
    }
    .image-one-container{
      flex-wrap: wrap;
      
      /* justify-content: center; */
    }
    .heading{
    
    }
    .image-one{
      padding: 2.5rem;
      opacity: 0;
  transform: translateY(50px); /* Move the element down initially */
  animation: slideInFromBottom 1s ease-in-out forwards;
    }

    @keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
    .Home-Top-Content{
    flex-wrap: wrap;
    padding:2.5rem;
  }
    .image-two-container{
      flex-wrap: wrap-reverse;
   
     }
     .image-two{
      padding: 2.5rem;
      /* margin-left: 10%; */
     }
     .image-two-content{
  

  }
  .description{
margin-left: 23%;

  }
  .build-container{
    flex-wrap: wrap;
    /* padding: 0.5rem; */
    /* justify-content: center; */
    
  }
  .build-container-image{
    margin-left: 10%;
  }
  .Home-Top-Content{
    /* flex-wrap: wrap;
    padding:0.5rem; */
  }
  .Top-NavImg{
 
 padding-right: 5.5rem;
 
   /* padding:3.5rem; */
}

  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    
    .Home-Top-Content{
  /* padding:1.5rem; */
  flex-wrap:wrap;
  /* margin-right: 20%; */
}

    .image-two-container{
      /* padding: 2.5rem; */
      margin-right:15%;

    }
 .image-one-container{
  margin-right:15%;
  /* margin-top: 10%; */
  

 }
 .image-one-content{
  margin-left:15%;
 }
 
.build-content{
  margin-right:60px;
}

.build-container-image{
  margin-right: 90px;
}
.features{
  margin-top: 25%;
}
}

  

  `
    
  


