import React from "react";
import "./App.css";
import RootContextProvider from "./contexts/RootContext";
import AuthContextProvider from "./contexts/AuthContext";
import RouterComp from "./Router";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <AuthContextProvider>
      <RootContextProvider>
        <RouterComp />
      </RootContextProvider>
    </AuthContextProvider>
    </ErrorBoundary>
  );
}

export default App;
