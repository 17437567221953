import React, { useState } from 'react'
import Header from "../CreateSurvey/components/Header"
import CopyLink from "../Share/components/CopyLink"
import ShareCards from "../Share/components/ShareCard"
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Button, Typography } from '@mui/material';

import "./style.css";

export default function Index() {

  const { surveyId } = useParams();
  const baseUrl = window.location.origin;
  // function generateRandomString(length) {
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   let randomString = '';
  
  //   for (let i = 0; i < length; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length);
  //     randomString += characters[randomIndex];
  //   }
  
  //   return randomString;
  // }
  
  // // Specify the length of the random string you want
  // const randomString = generateRandomString(10);

// console.log(baseUrl);
// console.log(surveyId);

const shareLink = `${baseUrl}/${surveyId}`;


  return (
    <>
    
      <Header surveyId={surveyId} editMode={false}/>
    <div className="share-link-section">
      <div className='copy-link'>
      <CopyLink
      url={shareLink} 
      />
      </div>
     <div className='share-cards'>

      <ShareCards url={shareLink} />

     </div>
     {/* <div>

      <p>{shareLink}</p>
     </div> */}
      {/* <p>{shareLink}</p> */}


     
    </div>
    </>

  )
}


