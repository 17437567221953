import React from 'react';
import styled from 'styled-components';
import Styled from "styled-components"

export default function Index() {
  return (
    <Footer>

  
    <div className='foooter-underline'>
    
    </div>
    <div className='nav-content' style={{ display: 'flex', alignItems: 'center' ,justifyContent:'center',marginTop:'10px'}}>
    <ul className='footer-quick-links' style={{ display: 'flex', listStyleType: 'none', margin: 0, padding: 0 }}>
      <li  style={{ margin: '0 1em', fontSize:'1.2em' }}>

      <a href="#">Home</a>
      </li>
      <li style={{ margin: '0 1em' , fontSize:'1.2em'}}> <a href="about">About</a> </li>
      <li style={{ margin: '0 1em', fontSize:'1.2em' }}><a href="contact">Contact Us</a></li>
      {/* <li style={{ margin: '0 1em', fontSize:'1.2em' }}><a href="#">Plans</a></li> */}
      <li style={{ margin: '0 1em', fontSize:'1.2em' }}><a href="TermsandConditions">Terms and Conditions</a></li>
      <li style={{ margin: '0 1em' , fontSize:'1.2em'}}><a href="PrivacyPolicy">Privacy&Policy</a></li>
    </ul>
    
  </div>

    
<div className='footer-last-heading-copyright'>

Copyright @2020 | Designed by 
</div>


    </Footer>
  );
}

const Footer=styled.div`


.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  line-height: 1.5; 
  
}
.container h3 {
  color: var(--blue);
}
.container li {
  color: var(--blue);
}

.foooter-underline {
  content: "";
  display: block;
  width: 85%;
  margin-left:8%;
  border-bottom: 2px solid var(--blue);
  margin-top: 5%;
}

.footer-last-heading-copyright{
  align-items: center;
  justify-content: center;
margin-top:2%;
margin-bottom:2%;
  display: flex;
}
.footer-last-heading-copyright::after {
  content: " XBI4";
  background: linear-gradient(#1724db, #FF1493, #8A2BE2);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* For Firefox */
  background-clip: text; /* Standard property */
  color: transparent;
}

@media screen and (max-width: 767px) {
  .footer-quick-links{

    font-size:xx-small;
  }

}

`