import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Navigate, Outlet } from "react-router-dom";
// import AuthStatus from "../../src/contexts/AuthContext"

const useAuth = () => {
  const { authStatus }: any = useContext(AuthContext);
  const { userName, isAuthenticated }: any = useContext(AuthContext);
  // const userName = localStorage.getItem("userName");
  console.log("This is auth status " + authStatus)
  if (isAuthenticated) {
    console.log("userName info from auth :", userName);
    return true;
  } else {
    console.log("error userName info from auth :", userName);
    return false;
  }
};

const ProtectedRouter = () => {
  const auth = useAuth();
  return auth ? <Outlet /> : <Navigate to="/log-in" />;
  // return <Outlet />
};

export default ProtectedRouter;
