import React, { useState } from 'react'
import "./style.css";
import TopNavBar from "../../Component/TopNavBar"
import styled from "styled-components";
import AboutFirstImg from "../../../../assets/LandingPageImages/Aboutfirst"
import AboutSecondImg from "../../../../assets/LandingPageImages/Aboutsecond"
export default function Index() {
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);
  const [secondImgLoaded, setSecondImgLoaded] = useState(false);

  const handleFirstImgLoad = () => {
    setFirstImgLoaded(true);
  };

  const handleSecondImgLoad = () => {
    setSecondImgLoaded(true);
  };
  
  return (
    <ABOUT>
      <div className="navbar-container">

  <TopNavBar/>
      </div>
      <div className='overall-container'>
        <div className='about-first-container'>
        <div className="about-content">
          <h1 className='about-heading'>About Us 📖</h1>
          <p> Rivazia assesses the success potential of your surveys with Rivazia which discover effective strategies for creating advanced and impactful surveys.Connect with individuals through email, website and social media.Save time with quick survey creation, requiring just a few inutes. Rivazia assessses the success potential of your surveys with Rivazia which discover effective strategies for creating advanced and impactful surveys.Connect with individuals through email,website and social meia.Save time with quick survey creation, requiring just a few minutes</p>
        </div>
        <div className='animated'>
        {/* {firstImgLoaded ? <AboutFirstImg /> : <div> <AboutFirstImg /></div>} */}
          <AboutFirstImg/>
        </div>
        </div>
        <div className='about-second-container'>
        <div className='animated'>
        {/* {secondImgLoaded ? <AboutSecondImg /> : <div>Loading second image...</div>} */}
          <AboutSecondImg/>
        </div>
        <div className="about-second-content">
        <h1 className='about-heading'>Why Rivazia 🤔</h1>
          <p> Our survey tool offers user-friendly sample survey templates that enable you to initiate feedback collection within minutes. With a wide range of questions tailored to various survey types, you can be confident in obtaining precise and reliable results. Start exploring today togather valuable insights effortlessly. Our survey tool offers user-friendly sample survey templates that enable you to initiate feedback collection within minutes. With a wide range of questions tailored to various survey types, you can be confident in obtaining precise and reliable results. start exploring today to gater valuable insights effortlessly</p>
        </div>
        </div>
   


</div>


    </ABOUT>



   

  

  )
}
const ABOUT = styled.div`
.overall-container{
  margin-top: 5%;
  padding: 5%;
}

.about-first-container{
  display: flex;
  gap:15%

}
.about-content{
  width:52%;
  font-size: larger;
  letter-spacing: 2px;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;

}
.about-second-container{
  display: flex;
  gap:15%
}

.about-second-content{
  width:52%;
  font-size: larger;
  letter-spacing: 2px;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;

}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Image animation */
.animated img {
  opacity: 0;
  animation: slideIn 1s ease-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


  @keyframes selected {
    0% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
-----------------Image Animation---------------
  .animated img {
  opacity: 0;
  animation: slideInImage 1s ease-out forwards;
}

@keyframes slideInImage {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Add more styles as needed */



@media screen and (max-width: 767px){
.overall-container{
  padding-top: 50%;
justify-content: center;
align-items: center;

}
.about-first-container{
  flex-wrap: wrap;

}
.about-content{
  width:90% ;
  justify-content: center;
  align-items: center;
}

.about-second-container{
  flex-wrap: wrap;
  margin-top: 10%;
}
.about-second-content{
  width:90% ;
  justify-content: center;
  align-items: center;
}
.animated{
  width: 60%;
  justify-content: center;

}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .overall-container{
    padding-top: 20%;
  }

  .animated{
  width: 60%;
  justify-content: center;
}
}

`