import Contactimg from "../contactus.svg"
const Contact = () => {
    return (
   
  <img src={Contactimg} />


    )
  }
  export default Contact;




 