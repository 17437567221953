import React, { useContext, useState } from "react";

import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface FirstNameProps {
  firstNameIsValid: boolean;
  setFirstName: (_: string) => void;
}

interface LastNameProps {
  lastNameIsValid: boolean;
  setLastName: (_: string) => void;
}

export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void }> = ({
  emailIsValid,
  setEmail,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={emailIsValid ? 'Email' : 'Invalid Email'}
      error={!emailIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value)
      }}
    />
  )
}

export const Password: React.FunctionComponent<{
  label: string
  passwordIsValid: boolean
  setPassword: (_: string) => void
}> = ({ label, passwordIsValid, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <TextField
    fullWidth
    type={showPassword ? 'text' : 'password'}
    variant="outlined"
    label={passwordIsValid ? label : 'Minimum 8 characters'}
    error={!passwordIsValid}
    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setPassword(evt.target.value);
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleTogglePassword}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
  )
}

export const Username: React.FunctionComponent<{ 
  usernameIsValid: boolean; 
  setUsername: (_: string) => void }> = ({
  usernameIsValid,
  setUsername,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={usernameIsValid ? 'Email Address' : 'Username must be an email address'}
      error={!usernameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(evt.target.value)
      }}
    />
  )
}



export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
  codeIsValid,
  setCode,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value)
      }}
    />
  )
}

export const FirstName: React.FunctionComponent<FirstNameProps> = ({
  firstNameIsValid,
  setFirstName,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={firstNameIsValid ? "First Name" : "Minimum 1 character"}
      error={!firstNameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(evt.target.value);
      }}
    />
  );
};


export const LastName: React.FunctionComponent<LastNameProps> = ({
  lastNameIsValid,
  setLastName,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={lastNameIsValid ? "Last Name" : "Minimum 1 character"}
      error={!lastNameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(evt.target.value);
      }}
    />
  );
};



       
