import React from 'react'

const preview = () => {
  return (
    <>
    <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.3455 9.02836C22.3945 4.99054 17.9972 0.914886 12.5502 0.914886C7.10313 0.914886 2.70588 4.99054 0.754844 9.02836C0.696089 9.15325 0.66571 9.28861 0.66571 9.42552C0.66571 9.56244 0.696089 9.6978 0.754844 9.82269C2.70588 13.8605 7.10313 17.9362 12.5502 17.9362C17.9972 17.9362 22.3945 13.8605 24.3455 9.82269C24.4042 9.6978 24.4346 9.56244 24.4346 9.42552C24.4346 9.28861 24.4042 9.15325 24.3455 9.02836ZM12.5502 14.1537C11.5708 14.1537 10.6134 13.8764 9.79906 13.3568C8.98473 12.8373 8.35004 12.0989 7.97525 11.2349C7.60045 10.3709 7.50239 9.42028 7.69346 8.50311C7.88453 7.58594 8.35615 6.74347 9.04868 6.08223C9.74121 5.42099 10.6235 4.97068 11.5841 4.78824C12.5447 4.60581 13.5403 4.69944 14.4452 5.0573C15.35 5.41516 16.1234 6.02118 16.6675 6.79872C17.2116 7.57625 17.502 8.49039 17.502 9.42552C17.5005 10.679 16.9782 11.8808 16.0499 12.7672C15.1216 13.6535 13.863 14.1522 12.5502 14.1537Z" fill="#00214D"/>
<path d="M12.5502 12.2624C14.1911 12.2624 15.5213 10.9923 15.5213 9.42553C15.5213 7.85877 14.1911 6.58865 12.5502 6.58865C10.9093 6.58865 9.57904 7.85877 9.57904 9.42553C9.57904 10.9923 10.9093 12.2624 12.5502 12.2624Z" fill="#00214D"/>
</svg>

    </>
  )
}

export default preview