
import LaplandingImage from "../svg/LapLandingImage.svg"
const SS = () => {
    return (
   
  <img src={LaplandingImage} />


    )
  }
  export default SS;