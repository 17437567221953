import React from 'react'
import "./style.css";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PricingList from "../LandingPage/Component/PlansandPricing/Component/PricingList"
export default function Index() {
  return (
    <>
  
  <PricingList/>
  </>
);
};
