// import { createContext, useState } from "react";

// export const RootContext = createContext();

// function RootProvider({children}) {
//   const [loggedIn, setLoggedIn] = useState(false);
//   return (
//     <RootContext.Provider value={{ loggedIn, setLoggedIn }}>
//       {children}
//     </RootContext.Provider>
//   );
// }

// export default RootProvider

import React, { createContext, useContext, useState } from "react";

export interface ContextType {
  loggedIn?: boolean;
  setLoggedIn?: (e: boolean) => void;
  setselectedSubSide: (e: number) => void;
  selectedSubSide: number;
  setFirst: (e: string) => void;
  first: string;
  setWatch: (e: string) => void;
  watch: string;

}

const value = {
  loggedIn: false,
  setLoggedIn: (loginValue: boolean) => {
    value.loggedIn = loginValue;
  },
} as ContextType;

export const RootContext = createContext<ContextType>(value);

interface IProps {
  children: React.ReactNode;
}

// export const useRootContext = () => useContext(RootContext)

const RootContextProvider = ({ children }: IProps) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [selectedSubSide, setselectedSubSide] = useState(0)
  const [first, setFirst] = useState("")
  const[watch,setWatch]=useState("")
  const contextValue = { loggedIn, setLoggedIn, selectedSubSide, setselectedSubSide, setFirst, first,watch,setWatch };

  return (
    <RootContext.Provider value={contextValue}>{children}</RootContext.Provider>
  );
};

export default RootContextProvider;
