import React from 'react';
import ExpandableCard from './Component/Policycontent';
import styled from "styled-components";
import TopNavBar from "../../Component/TopNavBar"

const App = () => {
  const cardData = [
    {
      title: "Information Collection",
      content: "We collect personal information that you voluntarily provide when using our survey tools. This may include your name, email address, demographic information, and responses to survey questions. We may also collect non-personal information such as IP addresses, browser types, and operating systems for statistical purposes. ",
      contentFontSize: "54px"
    },
    {
      title: "Use of Information",
      content: "We use the information collected through our survey tools to analyze and interpret survey results, improve our services, and provide you with valuable insights. Your personal information will only be used for the purposes for which it was collected unless we obtain your consent for other uses. "
    },
    {
      title: "Data Confidentiality and Security",
      content: " We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We restrict access to personal information to authorized employees who need to know the information for the purposes outlined in this Privacy Policy. However, no method of transmission or electronic storage is 100% secure, and we cannot guarantee absolute security.  "
    },
    {
      title: "Third-Party Service Providers ",
      content: " We may engage third-party service providers to assist us in operating our survey tools and processing the collected information. These service providers are contractually bound to use the information only for the purposes specified by us and in compliance with applicable privacy laws."
    },
    {
      title: "Data Retention  ",
      content: "We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. Afterward, we securely delete or anonymize the information."
    },
    {
      title: "Sharing of Information",
      content: "We do not sell, rent, or lease your personal information to third parties unless we have obtained your consent or are required by law to do so. We may, however, share aggregated and anonymized survey data that does not personally identify individuals."
    },
    {
      title: "Cookies and Tracking Technologies ",
      content: " Our survey tools may use cookies and similar tracking technologies to enhance your user experience and collect non-personal information. You have the option to disable cookies through your web browser settings, but this may limit the functionality of our survey tools."
    },
    {
      title: "Children's Privacy ",
      content: " Our survey tools are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you believe we have inadvertently collected such information, please contact us immediately, and we will take appropriate steps to delete it."
    },
    {
      title: "Changes to the Privacy Policy ",
      content: " We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other means."
    },
    {
      title: "Contact Us ",
      content: "If you have any questions or concerns regarding our Privacy Policy or the handling of your personal information, please contact us at contact information."
    }
  ];
  return (
    <POLICY>
        <div className="navbar-container">

    <TopNavBar/>
        </div>
      <div className='Container'>

      
 
    <p className='heading'>Privacy Policy</p>
    <div className="app">
      {cardData.map((card, index) => (
        <ExpandableCard key={index} title={card.title} content={card.content}
        titleFontSize="30px" // Adjust the font size
        contentColor="#888"
        
        />
      ))}
    </div>
    </div>
    </POLICY>

   
  );
};

export default App;

const POLICY=styled.div`
  
.app{
  padding: 1.5rem;

}
.Container{
  padding: 1 rem;
  padding-top:5%;
  /* background-color: brown; */

}
.heading{
  margin-left: 3%;
  font-size:x-large;
}
.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999; /* Set a high z-index value to ensure it appears above other content */
}
@media screen and (max-width: 767px){
.Container{
  padding-top: 50%;
}

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Container{
    padding-top: 10%;
  }


}

`
