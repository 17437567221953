import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Open} from "../../../../assets/svg/allIcons"
import { get } from '../../../../Utils/Function/Method';
import { useContext, useEffect } from 'react';
import {AuthContext} from '../../../../contexts/AuthContext';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid var(--blue)',
  boxShadow: 24,
  p: 4,
  height: 300,
  overflowY: "auto",
  borderRadius: "10px",
  "@media (max-width: 768px)": {
    width: "85%",
    p: 2,
  },
};

   function scrollToElement(id) {
        document.getElementById(id).scrollIntoView();
      }
      
      const btnStyle ={
        position: "fixed",
        backgroundColor: "#f24260",
        bottom: 100,
        right: 30,
        zIndex: 100,
        borderRadius: "50%",
        p: 0,
        minWidth: 50,
        height:50,
        boxShadow: "5px 5px 10px 1px #000", 
        color: "#fff",
        transition: "none !important",
           }

export default function BasicModal(data) {
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {surveyNum}=useContext(AuthContext)
  const surveyInt = parseInt(surveyNum);

  const [surveyReport,setSurveyReport]=React.useState([]);
// console.log(data)
async function fetchData(){
  try{
    const response=await get(`/report_info/${surveyInt}`);
      // console.log("response", response[1][0]);
    setSurveyReport(response[1][0]);
  } catch (error) {
    console.log("fetch error ", error);
  }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='acss-btn-mob'>
      <Button onClick={handleOpen} sx={btnStyle}><Open/></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
                   
          <div className="res-quest-access-media">
          {Array.isArray(surveyReport) && surveyReport.length>0 && surveyReport?.map((Data, idx) => {
  // console.log("INDEX",Data.q_id);
  return (
    <div className="ques-btn">
      <a href={`#res-ques${idx}`} onClick={handleClose}>Question {Data?.q_id }</a>
    </div>
  );
})}

              </div>
              <div></div>
        </Box>
      </Modal>
    </div>
  );
}