import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import NavBar from "../../Components/NavBar";
// import Header from '../../Components/Header'
const Index = () => {
  const { signOut, isAuthenticated }: any = useContext(AuthContext);
  const navigate = useNavigate();
  const [printMode, setPrintMode] = useState(false);
  const showNavbar = !printMode;

  // useEffect(() => {
  //   navigate("/");
  // }, [signOut, isAuthenticated]);

  return (
    <div>
         {showNavbar && <NavBar />}
      {/* <Login /> */}
      {/* <NavBar /> */}
      
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Index;
