import React, { useState, useContext } from "react";

// import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material//Paper";
import RivaziaLogo from "../../assets/rivaziaAnim";

import {
  useValidEmail,
  useValidPassword,
  useValidUsername,
  useValidFirstName,
  useValidLastName,
  useValidPasswordConfirm,
} from "../../hooks/useAuthHooks";

import {
  Email,
  Password,
  Username,
  FirstName,
  LastName,
} from "../../Components/Auth/authComponents";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../contexts/AuthContext";
import TextField from "@mui/material/TextField";
import { post } from "../../Utils/Function/Method";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { z } from "zod";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

interface Props {
  isUserAuthenticated: any;
}

const SignUp: React.FunctionComponent<{}> = ({
  isUserAuthenticated,
}: Props) => {
  // const initialValue={
  //         first_name:"",
  //         last_name:"",
  //         email:"",
  //         phone_number:"",
  //         cognito_id:"",
  // }
  const {setUserName}=useContext(AuthContext)
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{7,}$/;
  const schema = z
    .object({
      first_name: z
        .string()
        .min(2, { message: "First name must have atleast 2 characters" })
        .max(20, { message: "First name atmost have 20 characters" })
        .refine((value: string) => value.length === value.trim().length, {
          message: "Please remove extra spaces",
        }),
      last_name: z
        .string()
        .min(1, { message: "Last name must have atleast 1 characters" })
        .max(20, { message: "Last name atmost have 20 characters" })
        .refine((value: string) => value.length === value.trim().length, {
          message: "Please remove extra spaces",
        }),
      email: z.string().email({ message: "Please provide a valid email Id" }),
      password: z
        .string()
        .max(25, { message: "Password can be atmost 25 characters only" })
        .refine((value) => passwordRegex.test(value), {
          message:
            "Password must be more than 6 characters , must contain one upper case , one special characters and one number",
        })
        .refine((value: string) => value.length === value.trim().length, {
          message: "Please remove spaces",
        }),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Password and Confirm Password mismatch",path:["confirmPassword"]
    });

  type SignUpFormDataType = z.infer<typeof schema>;

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SignUpFormDataType>({ resolver: zodResolver(schema) });

  //   const onValueChange = (key) => (value) => {
  //     setUserDetails({ ...userDetais, [key]: value })
  //   }

  // const signupDetails= async()=>{
  // const temp={...userDetais};
  // await post('/rv_userprofile',temp)
  // .then(([status]) => {

  //   if (status == 200) {
  //     alert("title added")
  //   }
  //   else {
  //     alert("went wrong")
  //   }
  // })
  // .catch((err) =>
  //   console.log(err)
  // )
  // }
  // console.log("post", userDetais)

  const navigate = useNavigate();
  const signUp:SubmitHandler<SignUpFormDataType> = async (data) => {
    console.log("DATA>>>",data)
    const {email,password,first_name,last_name}=data
    const username=email
    console.log("username",username)
    try {
      const userData = await Auth.signUp({
        username,
        password,

        attributes: {
          email,
          given_name: first_name,
          family_name: last_name,
        },
      });

      setUserName(username);
      const userDetails = {
        first_name: first_name,
        last_name: last_name,
        email: username,
        cognito_id: userData?.userSub,
        // phone_number: phone_number,
      };
      await post("/rv_userprofile", userDetails);

      navigate("/confirm-signup");
    } catch (err: any) {
      console.log(err)
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword]=useState(false)

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleShowConfirmPassword=()=>{
    setShowConfirmPassword(prev=>!prev)
  }
  // const signUp = async () => {
  //   try {
  //     await Auth.signUp(username, password);
  //     //   setCreated(true);
  //     setUserName(username);
  //     navigate("/confirm-signup");
  //   } catch (err: any) {
  //     setError(err.message);
  //   }
  // };

  const signUpContent = (
    <>
      <Box width="80%" m={1}>
        {/* <FirstName
          firstNameIsValid={firstNameIsValid}
          setFirstName={setFirstName}
        /> */}
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => <TextField fullWidth
          variant="outlined"
          label={"First Name"} {...field} />}
        />
        {errors.first_name && <span style={{ color: "red" }}>{errors.first_name.message}</span>}
      </Box>

      <Box width="80%" m={1}>
      <Controller
          name="last_name"
          control={control}
          render={({ field }) => <TextField fullWidth
          variant="outlined"
          label={"Last Name"} {...field} />}
        />
        {errors.last_name && <span style={{ color: "red" }}>{errors.last_name.message}</span>}
        {/* <LastName lastNameIsValid={lastNameIsValid} setLastName={setLastName} /> */}
      </Box>

      <Box width="80%" m={1}>
      <Controller
          name="email"
          control={control}
          render={({ field }) => <TextField fullWidth
          variant="outlined"
          label={"Email Address"} {...field} />}
        />
        {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}
        {/* <Username usernameIsValid={usernameIsValid} setUsername={setUsername} /> */}
      </Box>

      <Box width="80%" m={1}>
        {/* <TextField
          type={showPassword ? "text" : "password"}
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!passwordIsValid}
          helperText={!passwordIsValid && "Invalid password"}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
        <Controller
          name="password"
          control={control}
          render={({ field }) => <TextField 
          fullWidth
          variant="outlined"
          type={showPassword ? "text" : "password"}
          label={"Password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...field} />}
        />
        {errors.password && <span style={{ color: "red" }}>{errors.password.message}</span>}
      </Box>
      <Box width="80%" m={1}>
        {/* <TextField
          type={showCurrentPassword ? "text" : "password"}
          label="Current Password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
          error={!passwordConfirmIsValid}
          helperText={!passwordConfirmIsValid && "Invalid password"}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleCurrentPassword}>
                  {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
         <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => <TextField 
          fullWidth
          variant="outlined"
          type={ showConfirmPassword? "text" : "password"}
          label={"Confirm Password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleShowConfirmPassword}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...field} />}
        />
        {errors.confirmPassword && <span style={{ color: "red" }}>{errors.confirmPassword.message}</span>}
      </Box>
{/* 
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box> */}

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button
              onClick={() => navigate(-1)}
              color="primary"
              variant="contained"
            >
              Cancel 
            </Button>
          </Box>
          <Box m={1}>
            <Button
              // disabled={!firstNameIsValid}
              color="success"
              variant="contained"
              onClick={handleSubmit(signUp)}
            >
              Sign Up 🔑
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );

  return (
    <Grid
      // className={classes.root}
      style={{ height: "100vh" }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        xs={11}
        sm={6}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
      >
        <Paper style={{ width: "100%", padding: 10 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <RivaziaLogo width="200" />
            {/* Title */}
            <Box m={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h4">Sign Up</Typography>
              </Grid>
            </Box>
            {signUpContent}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SignUp;
