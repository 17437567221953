import React, { useContext, useEffect, useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, Modal, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import PreviewIcon from '@mui/icons-material/Preview';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import { useNavigate } from "react-router-dom";


import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {AuthContext} from '../../contexts/AuthContext';

interface Props{
  icon?: any
  handleDelete?: () => void
  
}





export default function FadeMenu({handleDelete,id,survey,setSurveyId, statusType},{icon=<MoreVertIcon/>}:Props) {
  
  const navigate = useNavigate();
  const {setSurveyNum} =useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const [openModal, setOpenModal] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    // handleClose();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    handleClose();
  };

  const handleConfirmDelete = () => {
    handleCloseModal();
    if (handleDelete) {
      handleDelete(survey);
      // setSurveyId(survey)
    
    }
    handleClose();
  };
 
  return (
    <div>
       <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      onClick={handleClick}
      sx={{color: "black"}}
      >
        {icon}
      </IconButton>
      
    
      <Menu
      
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        TransitionComponent={Fade}
        onClose={handleClose}
      >
     
        <MenuItem onClick={() => {navigate(`/edit-survey/${survey}`);setSurveyNum(survey)}} style={{color:'black'}}>
        <ListItemIcon>
        <EditIcon color="primary"/>
        </ListItemIcon>
        <Typography >Edit</Typography>
        </MenuItem>
        <MenuItem
         onClick={() => navigate(`/share/${survey}`)}
         style={{color:'black'}}>
        <ListItemIcon >
        <ShareIcon style={{ color: 'green'}}/>
        </ListItemIcon >
        <Typography >Share</Typography> 
        </MenuItem>
        <MenuItem onClick={()=>navigate(`/Preview/${survey}`)}
        
        style={{color:'black'}}>
        <ListItemIcon>
        <PreviewIcon style={{ color: '#9C27B0' }} />

        {/* <PreviewIcon/> */}
        </ListItemIcon>
        <Typography >Preview</Typography> 
        </MenuItem>
        <MenuItem 
        onClick={() => navigate(`/reports-and-responses/${survey}`)}
        // onClick={()=>navigate("/reports-and-responses")}  
        style={{color:'black'}}>
        <ListItemIcon>
        <AnalyticsIcon style={{ color: '#008CBA'}}/>
        </ListItemIcon>
        <Typography>View Result</Typography> 
        </MenuItem>
        <div>

        <MenuItem onClick={()=>{handleOpenModal()}}
        
style={{ color: "black" }}>

        <ListItemIcon>
        <DeleteIcon style={{color:'red'}}/>
        </ListItemIcon>
        <Typography >Delete</Typography>
         </MenuItem>
       
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box   sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Are you sure you want to delete the survey?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button variant="contained"  onClick={handleConfirmDelete}>
              Ok
            </Button>
            <Button sx={{ marginLeft: 2 }} onClick={handleCloseModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
        </div>


      </Menu>
    </div>
  );
}


