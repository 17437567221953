import React, { useState } from 'react'
import Styled from "styled-components"
import Customer from "./Component/Customer";
import Employee from './Component/Employee';
import Product from './Component/Product';
import Marketting from './Component/Marketting';
import Sales from './Component/Sales';
import { Navigate, useNavigate } from "react-router-dom";

export default function Index() {
    const [selectedButton, setSelectedButton] = useState('button1');

    const navigate = useNavigate();


  const renderDetails = () => {
    if (selectedButton === 'button1') {
      return <Customer/>;
    } else if (selectedButton === 'button2') {
      return <Employee/>;
    } else if (selectedButton === 'button3') {
      return <Product/>
    } else if (selectedButton === 'button4') {
      return <Marketting/>
    } else if (selectedButton === 'button5') {
      return <Sales/>
    } else {
      return null;
    }
  };
  return (
    <SOLUTION>

    
    <div>
        <h1 className='solution-header fade-in'>
            Our Solutions 
        </h1>
        <div className='render-solutoon-buttons'>
      <div className="button-container">
      <button
              className={`buttons button1 ${selectedButton === 'button1' ? 'selected' : ''}`}
              onClick={() => setSelectedButton('button1')}
            >
        
        Customer Experience 🛍️ </button>
        <button
              className={`buttons button2 ${selectedButton === 'button2' ? 'selected' : ''}`}
              onClick={() => setSelectedButton('button2')}
            >
                      Employee Experience 👨‍💼</button>
                      <button
              className={`buttons button3 ${selectedButton === 'button3' ? 'selected' : ''}`}
              onClick={() => setSelectedButton('button3')}
            >
                      Product Experience 🛒</button>
                      <button
              className={`buttons button4 ${selectedButton === 'button4' ? 'selected' : ''}`}
              onClick={() => setSelectedButton('button4')}
            >
                      
                      Marketting Experience 📢</button>
                      <button
              className={`buttons button5 ${selectedButton === 'button5' ? 'selected' : ''}`}
              onClick={() => setSelectedButton('button5')}
            >
                      
                      Sales Experience 💼</button>
      </div>
      {renderDetails()}
    </div>


    </div>


    </SOLUTION>
  )
}
 const SOLUTION =Styled.div`
.solution-header{
    display: flex;
  justify-content: center;
  align-items: center;
  margin-top:20px;
  

}
.button-container{
    display: flex;
    /* align-items:center */
          justify-content: space-between;
          margin-top: 30px;
          padding-left:15%;
          padding-Right:15%;
          
}
.button1 {
    background-color: #ffffff; /* Default color for button1 */
    color: #000000; /* Default text color for button1 */
    border: 1px solid #000000; /* Default border for button1 */
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .button1.selected {
    background-color: #eec14e ;; /* Color for selected button1 */
    color: #ffffff; /* Text color for selected button1 */
    border: 2px solid #000000; /* Border color for selected button1 */
  }

  .button2 {
    background-color: #ffffff; /* Default color for button2 */
    color: #000000; /* Default text color for button2 */
    border: 1px solid #000000; /* Default border for button2 */
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .button2.selected {
    background-color: #EC7A50; /* Color for selected button2 */
    color: #ffffff; /* Text color for selected button2 */
    border: 2px solid #000000; /* Border color for selected button2 */
  }
  .button3 {
    background-color: #ffffff; /* Default color for button1 */
    color: #000000; /* Default text color for button1 */
    border: 1px solid #000000; /* Default border for button1 */
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .button3.selected {
    background-color: #E1BFFF; /* Color for selected button1 */
    color: #ffffff; /* Text color for selected button1 */
    border: 2px solid #000000; /* Border color for selected button1 */
  }

  .button4 {
    background-color: #ffffff; /* Default color for button2 */
    color: #000000; /* Default text color for button2 */
    border: 1px solid #000000; /* Default border for button2 */
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .button4.selected {
    background-color:var(--green) ; /* Color for selected button2 */
    color: #ffffff; /* Text color for selected button2 */
    border: 2px solid #000000; /* Border color for selected button2 */
  }

  .button5 {
    background-color: #ffffff; /* Default color for button2 */
    color: #000000; /* Default text color for button2 */
    border: 1px solid #000000; /* Default border for button2 */
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .button5.selected {
    background-color: #1A76D2; /* Color for selected button2 */
    color: #ffffff; /* Text color for selected button2 */
    border: 2px solid #000000; /* Border color for selected button2 */
  }

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.solution-header {
  animation: fadeIn 1s ease-in;
}




@media screen and (max-width: 767px){
  .render-solutoon-buttons{
    margin-right:58%
    /* padding:0.5rem */

  }
  .button-container{
    
  }
  .buttons{
    width:60%;
    font-size:xx-small
  }

}



 `