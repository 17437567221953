import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const ExpandableCard = ({ title, content,titleFontSize, contentColor }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5">{title}</Typography>
          <IconButton onClick={toggleExpanded}>
            {expanded ? <CloseIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        {expanded && <p style={{ marginTop: '20px',color: contentColor,fontSize:'20px' }}>{content}</p>}
      </CardContent>
    </Card>
  );
};

export default ExpandableCard;
